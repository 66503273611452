/* eslint-disable react/no-unused-state */
import React from 'react';
import { isMobile } from 'react-device-detect';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  CssBaseline,
  Toolbar,
  Typography,
  AppBar,
  IconButton,
  Link,
  List,
  Drawer,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fab
} from '@material-ui/core';

import { MdArrowForward, MdLiveTv, MdPhoneInTalk, MdUploadFile } from 'react-icons/md';
import logo from '../../assets/logo_white.svg';

import {
  acceptedAdminRoles,
  acceptedPrivateRoles,
  acceptedAlertRoles,
  allRoles
} from '../../utils/routes/routesObj';

import { loginOperations } from '../auth/login/redux';
import sessionImage from '../../assets/Sessions_black.svg';
import dashboardImage from '../../assets/Dashboard_black.svg';
import clinicianImage from '../../assets/Clinician_black.svg';
import patientImage from '../../assets/Patients_black.svg';
import alertImage from '../../assets/Alerts_black.svg';
import alertImageWhite from '../../assets/Alerts_white.svg';
import sessionImageWhite from '../../assets/Sessions_white.svg';
import dashboardImageWhite from '../../assets/Dashboard_white.svg';
import clinicianImageWhite from '../../assets/Clinician_white.svg';
import patientImageWhite from '../../assets/Patients_white.svg';
// This component sets the auto logout timer in case the user is not active
import AutoLogout from './utils/autoLogout/autoLogout';
// ------------------------------------------------------------------------

const drawerWidth = 200;
const drawerWidthClosed = 70;

const styles = theme => ({
  toolbarTitle: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down(600)]: {
      width: 50,
      overflow: 'auto',
      overflowY: 'hidden'
    }
  },
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: drawerWidth,
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9) + 1}px)`
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main_two})`,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main_two})`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbarActions: {
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...theme.mixins.toolbar
  },
  toolbar: {
    ...theme.mixins.toolbar
  },
  contentDrawerOpen: {
    transition: 'all .3s ease-in-out',
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  contentDrawerClosed: {
    transition: 'all .3s ease-in-out',
    width: `calc(100% - ${drawerWidthClosed}px)`,
    marginLeft: drawerWidthClosed
  },
  activeItem: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff'
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: !isMobile
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  redirectTo = destination => {
    const { history, logout } = this.props;
    switch (destination) {
      case 'overview':
        history.push('/home?page=0');
        break;
      case 'sessions':
        history.push('/sessions?page=1');
        break;
      case 'staff':
        history.push('/staff?page=2');
        break;
      case 'onboard':
        history.push('/onboard?page=3');
        break;
      case 'patients':
        history.push('/patients');
        break;
      case 'alerts':
        history.push('/alerts?page=4');
        break;
      case 'stream':
        history.push('/stream?page=5');
        break;
      case 'live-calls':
        history.push('/live-calls?page=6');
        break;
      case 'file-drops':
        history.push('/drops?page=7');
        break;
      case 'upload':
        history.push('/upload?page=8');
        break;
      case 'logout':
        logout();
        break;
      default:
      // code block
    }
  };

  render() {
    const {
      classes,
      theme,
      children,
      role,
      portalConfig,
      portalActivePartners,
      firstName,
      partnerDisplayName,
      configurations,
      homeState
    } = this.props;
    const { open } = this.state;

    const selectedIndex =
      parseInt(queryString.parse(document.location.search).page, 10) || 0;
    const env = process.env.REACT_APP_ENV;

    return (
      <div>
        <AutoLogout />
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={!open}>
            {!isMobile && env !== 'prod' && env !== 'prod2' ? (
              <Fab
                disabled
                style={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}
                variant="extended"
              >
                {env === 'demo' ? 'demo' : env === 'dev' ? 'dev' : 'qa'}
              </Fab>
            ) : (
              ''
            )}

            {isMobile && open ? ( // If mobile screen and open drawer, leave only logout
              ''
            ) : (
              <>
                <div className={classes.toolbarTitle}>
                  <Typography variant="h6" color="inherit">
                    {!isMobile ? `Ellipsis Health Portal: ` : ''}
                    {portalActivePartners.map(
                      partner => `${partnerDisplayName[partner]} `
                    )}
                  </Typography>
                </div>

                <Typography
                  style={!isMobile ? { fontSize: 25 } : { fontSize: 18 }}
                  variant="subtitle1"
                  color="inherit"
                >
                  {`Hello, ${firstName}`}
                </Typography>
              </>
            )}
            <Link
              style={
                !isMobile
                  ? {
                    marginLeft: 20,
                    marginRight: 20,
                    color: '#ffffff',
                    cursor: 'pointer'
                  }
                  : {
                    marginLeft: 20,
                    marginRight: 20,
                    fontSize: 15,
                    color: '#ffffff',
                    cursor: 'pointer'
                  }
              }
              underline="always"
              onClick={() => {
                sessionStorage.removeItem('page');
                this.redirectTo('logout');
              }}
            >
              <Typography variant="subtitle1" color="inherit">
                Logout
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <div className={classes.toolbarActions}>
            {open === true ? (
              <img
                alt=""
                src={logo}
                height="80"
                onClick={this.handleDrawerClose}
              />
            ) : (
              <IconButton onClick={this.handleDrawerOpen}>
                <MdArrowForward style={{ color: '#FFFFFF' }} />
              </IconButton>
            )}
          </div>
          <List>
            {portalConfig.newPatientOptions &&
              portalConfig.newPatientOptions.length ? (
                <ListItem
                  button
                  selected={selectedIndex === 0}
                  className={classNames({
                  [classes.activeItem]: selectedIndex === 0
                })}
                  disabled={
                  !allRoles.includes(role) ||
                  homeState.showSpinner ||
                  homeState.showPSpinner
                }
                  onClick={() => {
                  this.setState({ selectedIndex: 0 });
                  sessionStorage.removeItem('page');
                  this.redirectTo('overview');
                }}
                  key="Overview"
                >
                  <ListItemIcon>
                    <img
                      alt=""
                      src={
                      selectedIndex === 0 ? dashboardImageWhite : dashboardImage
                    }
                      width="30"
                      height="30"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                      <Typography variant="h6" style={{ fontSize: 16 }}>
                        OVERVIEW
                      </Typography>
                  }
                  />
                </ListItem>
            ) : (
              <></>
            )}
            <ListItem
              button
              selected={selectedIndex === 1}
              className={classNames({
                [classes.activeItem]: selectedIndex === 1
              })}
              disabled={
                !allRoles.includes(role) ||
                homeState.showSpinner ||
                homeState.showPSpinner
              }
              onClick={() => {
                this.setState({ selectedIndex: 1 });
                // sessionStorage.removeItem('page');
                this.redirectTo('sessions');
              }}
              key="Sessions"
            >
              <ListItemIcon>
                <img
                  alt=""
                  src={selectedIndex === 1 ? sessionImageWhite : sessionImage}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography variant="h6" style={{ fontSize: 16 }}>
                    SESSIONS
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 2}
              className={classNames({
                [classes.activeItem]: selectedIndex === 2
              })}
              disabled={
                !acceptedAdminRoles.includes(role) ||
                homeState.showSpinner ||
                homeState.showPSpinner
              }
              onClick={() => {
                this.setState({ selectedIndex: 2 });
                sessionStorage.removeItem('page');
                this.redirectTo('staff');
              }}
              key="Staff"
            >
              <ListItemIcon>
                <img
                  alt=""
                  src={
                    selectedIndex === 2 ? clinicianImageWhite : clinicianImage
                  }
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography variant="h6" style={{ fontSize: 16 }}>
                    STAFF
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 3}
              className={classNames({
                [classes.activeItem]: selectedIndex === 3
              })}
              disabled={
                !acceptedPrivateRoles.includes(role) ||
                homeState.showSpinner ||
                homeState.showPSpinner
              }
              onClick={() => {
                this.setState({ selectedIndex: 3 });
                sessionStorage.removeItem('page');
                this.redirectTo('onboard');
              }}
              key="Patients"
            >
              <ListItemIcon>
                <img
                  alt=""
                  src={selectedIndex === 3 ? patientImageWhite : patientImage}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography variant="h6" style={{ fontSize: 16 }}>
                    PATIENTS
                  </Typography>
                }
              />
            </ListItem>
            {(portalConfig.notificationPartners &&
              portalConfig.notificationPartners[portalActivePartners[0]]) ||
              (portalConfig.phq9AlertPartners &&
                portalConfig.phq9AlertPartners[portalActivePartners[0]]) ? (
                  <ListItem
                    button
                    selected={selectedIndex === 4}
                    className={classNames({
                  [classes.activeItem]: selectedIndex === 4
                })}
                    disabled={
                  !acceptedAlertRoles.includes(role) ||
                  homeState.showSpinner ||
                  homeState.showPSpinner
                }
                    onClick={() => {
                  this.setState({ selectedIndex: 4 });
                  sessionStorage.removeItem('page');
                  this.redirectTo('alerts');
                }}
                    key="Alerts"
                  >
                    <ListItemIcon>
                      <img
                        alt=""
                        src={selectedIndex === 4 ? alertImageWhite : alertImage}
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography variant="h6" style={{ fontSize: 16 }}>
                          ALERTS
                        </Typography>
                  }
                    />
                  </ListItem>
            ) : (
              <></>
            )}
            {acceptedPrivateRoles.includes(role) &&
              configurations?.panelConfig?.stream && (
                <ListItem
                  button
                  selected={selectedIndex === 5}
                  className={classNames({
                    [classes.activeItem]: selectedIndex === 5
                  })}
                  disabled={homeState.showSpinner || homeState.showPSpinner}
                  onClick={() => {
                    this.setState({ selectedIndex: 5 });
                    sessionStorage.removeItem('page');
                    this.redirectTo('stream');
                  }}
                  key="Stream"
                >
                  <ListItemIcon>
                    <MdLiveTv
                      style={{
                        width: 30,
                        height: 30,
                        fill: selectedIndex === 5 ? '#fff' : '#000'
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Typography variant="h6" style={{ fontSize: 16 }}>
                        STREAM
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            {acceptedPrivateRoles.includes(role) &&
              configurations?.panelConfig?.liveCall && (
                <ListItem
                  button
                  selected={selectedIndex === 6}
                  className={classNames({
                    [classes.activeItem]: selectedIndex === 6
                  })}
                  disabled={homeState.showSpinner || homeState.showPSpinner}
                  onClick={() => {
                    this.setState({ selectedIndex: 6 });
                    sessionStorage.removeItem('page');
                    this.redirectTo('live-calls');
                  }}
                  key="live-calls"
                >
                  <ListItemIcon>
                    <MdPhoneInTalk
                      style={{
                        width: 30,
                        height: 30,
                        fill: selectedIndex === 6 ? '#fff' : '#000'
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Typography variant="h6" style={{ fontSize: 16 }}>
                        LIVE CALLS
                      </Typography>
                    }
                  />
                </ListItem>
              )}

            {acceptedPrivateRoles.includes(role) &&
              configurations?.panelConfig?.fileDrop && (
                <ListItem
                  button
                  selected={selectedIndex === 7}
                  className={classNames({
                    [classes.activeItem]: selectedIndex === 7
                  })}
                  disabled={homeState.showSpinner || homeState.showPSpinner}
                  onClick={() => {
                    this.setState({ selectedIndex: 7 });
                    sessionStorage.removeItem('page');
                    this.redirectTo('file-drops');
                  }}
                  key="file-drops"
                >
                  <ListItemIcon>
                    <MdUploadFile
                      style={{
                        width: 30,
                        height: 30,
                        fill: selectedIndex === 7 ? '#fff' : '#000'
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Typography variant="h6" style={{ fontSize: 16 }}>
                        FILE DROPS
                      </Typography>
                    }
                  />
                </ListItem>
              )}

            {acceptedPrivateRoles.includes(role) &&
              configurations?.panelConfig?.upload && (
              <ListItem
                button
                selected={selectedIndex === 8}
                className={classNames({
                  [classes.activeItem]: selectedIndex === 7
                })}
                disabled={homeState.showSpinner || homeState.showPSpinner}
                onClick={() => {
                  this.setState({ selectedIndex: 8 });
                  sessionStorage.removeItem('page');
                  this.redirectTo('upload');
                }}
                key="upload"
              >
                <ListItemIcon>
                  <MdUploadFile
                    style={{
                      width: 30,
                      height: 30,
                      fill: selectedIndex === 8 ? '#fff' : '#000'
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Typography variant="h6" style={{ fontSize: 16 }}>
                      UPLOAD
                    </Typography>
                  }
                />
              </ListItem>
            )}
          </List>

          {/* <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                sessionStorage.removeItem('page');
                this.redirectTo('logout');
              }}
              key="Logout"
            >
              <ListItemIcon>
                <PowerOffIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List> */}
        </Drawer>
        <main
          className={
            open ? classes.contentDrawerOpen : classes.contentDrawerClosed
          }
        >
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    role,
    portalActivePartners,
    configurations,
    firstName
  } = state.login.portalConfig;

  const { partnerDisplayName } = configurations.options;

  return {
    homeState: state.home,
    portalConfig: state.login.portalConfig,
    onboardState: state.onboard,
    role,
    portalActivePartners,
    firstName,
    partnerDisplayName,
    configurations
  };
};

Header.propTypes = {
  classes: PropTypes.object,
  homeState: PropTypes.object,
  children: PropTypes.object,
  role: PropTypes.string,
  portalConfig: PropTypes.object,
  portalActivePartners: PropTypes.array,
  firstName: PropTypes.string,
  partnerDisplayName: PropTypes.string,
  configurations: PropTypes.object,
  history: PropTypes.object,
  logout: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  const logout = () => dispatch(loginOperations.logUserOut());

  return {
    logout
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(Header)));
