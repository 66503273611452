import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TriggerContent from './NotificationTriggerContent';
import styles from './TextInfoContent.styles';

import { DateComponent } from '../../../components/Date';

const useDefaultStyles = makeStyles(styles, { name: 'TextInfoContent' });

const TextInfoContent = ({
  useStyles,
  data,
  patientsMap,
  config,
  bodyProps,
  ...props
}) => {
  const { timezone } = config
  const css = useStyles(props);

  return (
    <>
      <div className={css.infoTitle}>
        <Typography style={{ fontWeight: 500, marginRight: 10 }}>
          Alert Type:
        </Typography>
        <Typography style={{ fontWeight: 500 }}>
          DAILY NOTIFICATION
        </Typography>
      </div>
      <div className={css.infoLineColumn}>
        <Typography style={{ fontWeight: 500 }}>
          Alert Date & Time:
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          &nbsp; <DateComponent date={data.createdAtUtc?.value} timezone={timezone} />
        </Typography>
      </div>
      <div style={{ height: 20 }} />

      {data.triggers.length ? data.triggers.map((trigger, i) => (
        <TriggerContent config={config} trigger={trigger} patient={patientsMap[trigger.patientId]} />
      )) : <></>}
    </>
  );
};

TextInfoContent.propTypes = {
  data: PropTypes.object,
  patientsMap: PropTypes.object,
  bodyProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  config: PropTypes.object,
  useStyles: PropTypes.func,
};
TextInfoContent.defaultProps = {
  data: {},
  bodyProps: {},
  useStyles: useDefaultStyles,
};

export default TextInfoContent;