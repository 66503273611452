import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Menu, MenuItem, Button } from '@material-ui/core';
import { AiOutlineArrowDown } from 'react-icons/ai';
import cardHeaderStyles from './CardHeader.styles';
import { formatDate } from '../../../components/Date';

const BrandCardHeader = withStyles(cardHeaderStyles, {
  name: 'BrandCardHeader'
})(({ classes, alert, seen, clinicians, config }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <div style={{
            display: 'flex', alignItems: 'center'
          }}
          >
            <div
              className={classes.shape}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
            <div style={{ display: 'flex' }}>
              <Typography className={classes.alertTitle}>Alert ID:</Typography>
              <Typography className={classes.alert}>{alert}</Typography>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
            <Button endIcon={<AiOutlineArrowDown />} aria-haspopup="true" onClick={handleClick}>
              Seen By {seen.length}
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {seen.map((cli) =>
                clinicians[cli.uid] ? <MenuItem onClick={handleClose}>{`${clinicians[cli.uid].firstName} ${clinicians[cli.uid].lastName}`}: {formatDate(cli.seenAtUtc?.value, 'L LT z', config.timezone)}</MenuItem> : ''
              )}
            </Menu>

          </div>
        </div>
      </div>
      <hr className={classes.divider} />
    </>
  )
})

BrandCardHeader.propTypes = {
  alert: PropTypes.string,
  seen: PropTypes.array,
  clinicians: PropTypes.object,
  config: PropTypes.object
};

export default BrandCardHeader;
