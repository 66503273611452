import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import first from 'lodash/first';
import last from 'lodash/last';
import range from 'lodash/range';
import {
  XYPlot,
  XAxis,
  YAxis,
  GradientDefs,
  AreaSeries,
  CustomSVGSeries,
  WhiskerSeries,
  HorizontalGridLines,
  VerticalGridLines,
  MarkSeries,
  LineSeries
} from 'react-vis';

const COLORS = {
  WHITE: '#ffffff',
  GRAY: '#70797a',
  BLUE: '#394f68',
  PURPLE: '#3abab6',
  PINK: '#3abab6'
};
// const LABELS = ['Mild', 'Moderate', 'Severe'];
const STYLES = {
  AXIS: {
    line: { stroke: COLORS.GRAY, strokeWidth: 1 },
    text: { fill: COLORS.BLUE, fontSize: '14px', fontFamily: 'Roboto' }
  },
  GRID: { stroke: COLORS.GRAY, strokeWidth: 1 },
  LINE: {
    fill: 'none',
    stroke: COLORS.PINK
  },
  LINE_MARK: {
    fill: 'none',
    stroke: COLORS.PINK
  },
  RECT: { fill: COLORS.BLUE, stroke: COLORS.BLUE }
};

/**
 * AreaSeries - Gradient background
 *
 * VerticalGridLines, HorizontalGridLines - Grid lines
 * XAxis - Top session number axis
 * YAxis 1 - Left value scale
 * YAxis 2 - Right Severe/Moderate/Mild labels
 * YAxis 3 - Right side tick to discriminate labels
 *
 * WhiskerSeries - "I" shaped confidence score range series
 * CustomSVGSeries - Rect-shaped values for phq/gad survey score
 * LineMarkSeries - Main line & circles for phq_r/gad_r score
 *
 * @param stops - stop value for mild/moderate/severe
 *                For PHQ - [0, 9, 18, 24]
 *                For GAD - [0, 9, 15, 21]
 */
function AssessmentChart({
  data,
  stops,
  showSessions,
  showRight,
  showPHQ,
  colWidth,
  height,
  expand24
}) {
  /**
   * Expand the length array data to 24 to show a full graph
   */
  if (expand24 && data.length < 24) {
    let start = data.length;
    for (start; start < 24; start += 1) {
      data.push({});
    }
  }

  const lineData = data
    .map((s, i) => ({
      x: i + 1,
      y: s.value || s.expected
    }))
    .filter(s => !isNil(s.y));

  const markData = data.map((s, i) => ({
    x: i + 1,
    y: s.value
  }));
  const whiskerData = data
    .map((s, i) => ({
      x: i + 1,
      y: s.value,
      yVariance: s.confidence
    }))
    .filter(s => !isNil(s.y));
  const phqData = data
    .map((s, i) => ({
      x: i + 1,
      y: s.expected,
      size: 7,
      style: STYLES.RECT
    }))
    .filter(s => !isNil(s.y));
  const labelPos = stops
    .slice(1)
    .map((s, index) => stops[index] + (s - stops[index]) / 2);
  const MAX = last(stops);
  const MIN = first(stops);
  const handleScroll = value => {
    const item = data[value.x - 1];
    if (!item) return;
    const sessionItem = document.getElementById(`session-${item.id}`);
    sessionItem.scrollIntoView();
  };

  return (
    <XYPlot
      width={colWidth * (data.length - 1) + 120}
      height={height}
      yDomain={[MIN, MAX]}
      margin={{ top: showSessions ? 40 : 10, left: 40, right: 80 }}
    >
      <GradientDefs>
        <linearGradient id="ellipsisBg" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={COLORS.WHITE} stopOpacity={0.4} />
          <stop offset="100%" stopColor={COLORS.WHITE} stopOpacity={0.4} />
        </linearGradient>
      </GradientDefs>
      <AreaSeries
        color="url(#ellipsisBg)"
        data={[
          { x: 1, y: MAX, y0: MIN },
          { x: data.length, y: MAX, y0: MIN }
        ]}
      />
      <VerticalGridLines tickTotal={data.length - 1} style={STYLES.GRID} />
      <HorizontalGridLines
        tickTotal={4}
        tickValues={stops}
        style={STYLES.GRID}
      />
      {showSessions && (
        <XAxis
          orientation="top"
          top={0}
          tickSizeOuter={6}
          tickSizeInner={0}
          tickValues={range(1, data.length + 1)}
          tickFormat={i => `${i}`}
          style={STYLES.AXIS}
        />
      )}
      <YAxis
        tickValues={range(MIN, MAX + 3, 3)}
        tickSize={0}
        style={STYLES.AXIS}
      />
      {showRight && (
        <YAxis
          tickValues={labelPos}
          tickFormat={y => {}}
          tickSize={0}
          orientation="right"
          right={0}
          style={STYLES.AXIS}
        />
      )}
      {showRight && (
        <YAxis
          tickValues={stops}
          tickFormat={() => ''}
          tickSizeOuter={40}
          tickSizeInner={0}
          orientation="right"
          right={0}
          style={STYLES.AXIS}
        />
      )}
      {/* <WhiskerSeries
        data={whiskerData}
        color={COLORS.PINK}
        strokeWidth={3}
        size={0}
      /> */}
      {showPHQ && <CustomSVGSeries customComponent="square" data={phqData} />}
      <LineSeries
        style={STYLES.LINE}
        color={COLORS.PINK}
        data={lineData}
        getNull={d => !isNil(d.y)}
      />
      <MarkSeries
        markStyle={STYLES.LINE_MARK}
        size={5}
        color={COLORS.PINK}
        data={markData}
        getNull={d => !isNil(d.y)}
        onValueClick={dataValue => handleScroll(dataValue)}
      />
    </XYPlot>
  );
}

AssessmentChart.propTypes = {
  data: PropTypes.array,
  stops: PropTypes.array,
  showRight: PropTypes.bool,
  colWidth: PropTypes.number,
  height: PropTypes.number,
  showPHQ: PropTypes.bool,
  showSessions: PropTypes.bool,
  expand24: PropTypes.bool
};

AssessmentChart.defaultProps = {
  showPHQ: true,
  colWidth: 40,
  height: 300
};

export default AssessmentChart;
