// import fetch from 'cross-fetch';
import firebase from '../../../config/firebase';
import notifier from '../../common/utils/notifier';
import {
  requestAlertsJson,
  receiveAlertsJson,
  requestACK,
  receiveACK,
  receiveACKSuccess
} from './actions';

require('firebase/functions');

const getAlertsJson = (partnerList, dateFrom) => {
  return dispatch => {
    dispatch(requestAlertsJson());

    const alertsList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_alertsList');
    alertsList({
      partnerList,
      dateFrom,
    })
      .then(result => {
        const alerts = JSON.parse(result.data).alertsList;

        // Remove duplicates and create map of user's info (This happens because of the UNNEST property used in the backend)
        const patientsMap = {};
        const unique = alerts.reduce((acc, current) => {

          patientsMap[current.pId] = {
            name: current.name,
            lastName: current.lastName,
            mrn: current.mrn
          }

          const x = acc.find(item => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } 
          return acc;
        }, []);

        dispatch(receiveAlertsJson(unique, patientsMap));
      })
      .catch((error) => {
        console.log(`Error while calling alertsList function: ${error}`);
        dispatch(receiveAlertsJson([]));
      });
  };
};

const sendFollowUpNotification = data => {

  const followUp = firebase
    .functions()
    .httpsCallable('dashboardFunctions-ackPhq9Alert');

  followUp(data).then(result => {
    const followUpResult = result.data;

    if (!followUpResult.success) {
      notifier.error(followUpResult.message);
    }

    if (followUpResult.success) {
      notifier.success(followUpResult.message);
    }
  })
    .catch(error => {
      console.log(error);
    });
};

const acknowledgeAlert = data => {
  return dispatch => {
    dispatch(requestACK());

    const ack = firebase
      .functions()
      .httpsCallable('dashboardFunctions-ackPhq9Alert');

    ack({
      ...data
    })
      .then(result => {
        const ackResult = result.data;

        if (!ackResult.success) {
          notifier.error(ackResult.message);
          dispatch(receiveACK());
        }

        if (ackResult.success) {
          sendFollowUpNotification({
            ...data,
            type: 'FOLLOW_UP'
          })

          notifier.success(ackResult.message);

          const ackDetails = JSON.parse(ackResult.ackDetails);
          dispatch(
            receiveACKSuccess({
              ...ackDetails,
              id: data.id
            })
          );
        }
      })
      .catch(error => {
        console.log(error);
        notifier.error(error);
        dispatch(receiveACK());
      });
  };
};

export default { getAlertsJson, acknowledgeAlert };
