import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow
} from '@material-ui/core';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdInsertDriveFile,
  MdCheck,
  MdClose,
  MdPlayArrow,
} from 'react-icons/md';
import moment from 'moment-timezone';
import { pick } from 'lodash';

import firebase from '../../../config/firebase';

import { statuses as _statuses } from '../utils/constants';
import { statusGenerator } from '../utils/handleEvents';
import { getSessionScore, scoreSession } from '../utils/getFileDropsInfo';
import RowExpanded from './SessionDropRowExpanded';
import { levelColors } from './Level';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontWeight: 700,
      color: '#344769',
      fontSize: '14px'
    }
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  iconPlay: {
    width: '35px',
    height: '35px'
  },
  rot90: {
    transform: 'rotate(20deg)'
  }
});

const SessionRow = props => {
  const { session } = props;
  const [open, setOpen] = useState(false);
  const [sessionEvents, setSessionEvents] = useState([]);
  const [restSessionScore, setRestSessionScore] = useState(
    session?.restSessionScore || {}
  );

  const classes = useRowStyles();

  const status = useMemo(() => {
    return statusGenerator(sessionEvents);
  }, [sessionEvents]);

  const isFailed = useMemo(() => !!status.find(el => el.name === 'Incomplete'), [status]);
  const statuses = useMemo(() => {
    return [..._statuses].filter(el => el !== (isFailed ? 'Completed' : 'Incomplete'))
  }, [_statuses, status])

  const statusPos = useMemo(
    () =>
      status.length
        ? statuses.findIndex(el => el === status[status.length - 1]?.name)
        : -1,
    [status]
  );

  useEffect(() => {
    const meetingRef = firebase
      .firestore()
      .collection('cloudDropSessions')
      .where('sessionId', '==', session.sessionId);

    const unsubscribeMeetingListener = meetingRef.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(async change => {
        if (change.type === 'modified' || change.type === 'added') {
          const data = change.doc.data();

          if (data.processed !== session.processed) {
            if (data.processed) {
              session.inProgress = false;
            }
            session.processed = data.processed
          }
          if (data.numTries !== session.numTries) {
            session.numTries = data.numTries
          }

          if (data?.restSessionId && data?.restScoreId) {
            const scoreData = await getSessionScore(data.restScoreId);
            setRestSessionScore(scoreData || {});
          }
        }
      });
    });

    const eventsRef = firebase
      .firestore()
      .collection('cloudDropEvents')
      .where('sessionId', '==', session.sessionId);

    const unsubscribeEventsListener = eventsRef.onSnapshot(snapshot => {
      const temp = [];
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const data = change.doc.data();
          temp.push(
            pick(data, [
              'createdAtUtc',
              'event',
              'sessionId',
              'partnerId',
            ])
          );
        }
      });
      setSessionEvents(prev => [
        ...prev,
        ...temp
          .map(e => {
            return e;
          })
      ]);
    });

    return () => {
      unsubscribeMeetingListener();
      unsubscribeEventsListener();
    };
  }, [session.sessionId]);

  const runSession = () => {
    return scoreSession(session.sessionId, session.partnerId, session.indexName);
  };

  const isInProgress = () => {
    session.inProgress = status.length !== 0 && statusPos < statuses.length - 1
    return session.inProgress
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell align="center">
          {session.sessionName ?? session.sessionId ?? '-'}
        </TableCell>
        <TableCell align="center">
          {session.sessionTimestamp && moment(session.sessionTimestamp).tz('America/Los_Angeles').format('L LT z')}
        </TableCell>

        <TableCell
          align="center"
          style={{
            color:
              levelColors?.[restSessionScore?.measures?.depressionLevel] ||
              'initial'
          }}
        >
          {restSessionScore?.measures?.depressionLevel || '-'}
        </TableCell>
        <TableCell
          align="center"
          style={{
            color:
              levelColors?.[restSessionScore?.measures?.anxietyLevel] ||
              'initial'
          }}
        >
          {restSessionScore?.measures?.anxietyLevel || '-'}
        </TableCell>

        <TableCell align="center" style={{ whiteSpace: 'noWrap' }}>
          {session.processed ? "Yes" : isInProgress() ? "In Progress" : "No"}
        </TableCell>

        <TableCell align="center">
          {session.numTries}
        </TableCell>

        <TableCell align="center">
          {status.length === 0 ? '' : statusPos < statuses.length - 1 ? (
            <svg width="28" height="28" fill="none">
              <path
                d="M15.196 1.196v2.291a1.196 1.196 0 1 1-2.392 0v-2.29a1.196 1.196 0 1 1 2.392 0ZM21 1.877a1.2 1.2 0 0 0-1.633.438L18.223 4.3a1.196 1.196 0 1 0 2.07 1.196l1.144-1.984A1.196 1.196 0 0 0 21 1.877Zm2.105 8.067c.21 0 .416-.055.598-.16L25.69 8.64a1.196 1.196 0 0 0-1.197-2.073l-1.985 1.145a1.197 1.197 0 0 0 .598 2.232Zm3.7 2.87-2.291-.003h-.003a1.196 1.196 0 1 0-.002 2.392l2.292.003h.002a1.196 1.196 0 0 0 .002-2.393Zm-1.126 6.563-1.983-1.147A1.197 1.197 0 1 0 22.5 20.3l1.983 1.147a1.196 1.196 0 0 0 1.196-2.07Zm-5.398 3.136a1.197 1.197 0 1 0-2.074 1.196l1.145 1.986a1.197 1.197 0 1 0 2.074-1.196l-1.145-1.986Zm-6.292.804h-.003a1.198 1.198 0 0 0-1.196 1.194l-.004 2.29A1.195 1.195 0 0 0 13.979 28h.003a1.196 1.196 0 0 0 1.196-1.194l.004-2.29a1.196 1.196 0 0 0-1.194-1.2ZM9.33 22.062a1.197 1.197 0 0 0-1.636.435l-1.149 1.982a1.197 1.197 0 0 0 2.07 1.2l1.149-1.982a1.198 1.198 0 0 0-.434-1.635Zm-5.039-3.86-1.985 1.142a1.196 1.196 0 0 0 1.191 2.075l1.985-1.143a1.194 1.194 0 0 0 .135-1.985 1.196 1.196 0 0 0-1.326-.09Zm-.803-3.028a1.196 1.196 0 0 0 .002-2.392l-2.292-.005h-.002a1.195 1.195 0 1 0-.002 2.392l2.291.005h.003Zm2.02-7.487L3.528 6.54a1.196 1.196 0 1 0-1.2 2.068l1.98 1.15a1.196 1.196 0 1 0 1.2-2.069ZM8.666 2.3A1.196 1.196 0 1 0 6.592 3.49l1.14 1.987a1.196 1.196 0 1 0 2.074-1.191L8.666 2.3Z"
                fill="#81DE80"
              >
                <animateTransform 
                  attributeName="transform" 
                  begin="0s" 
                  type="scale"
                  values="1 1;.5 .5;1 1"
                  repeatCount="indefinite"
                  dur="4s"
                  calcMode="spline"
                  keySplines="0.4 0 0.5 1; 0.5 0 0.5 1"
                />
              </path>
            </svg>
          ) : (
            isFailed ? <MdClose className={classes.icon} color="#ff0E00" /> : <MdCheck className={classes.icon} color="#81DE80" />
          )}
        </TableCell>

        <TableCell align="center">
          {session.processed || session.numTries > 2 || isInProgress() ? "" : (
            <IconButton
              aria-label="Score session"
              size="small"
              onClick={runSession}
              style={{ color: "white" }}
            >
              <MdPlayArrow onClick={runSession} className={classes.iconPlay} color="#81DE80" />
            </IconButton>
          )}
        </TableCell>

        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <MdKeyboardArrowUp className={classes.icon} />
            ) : (
              <MdKeyboardArrowDown className={classes.icon} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={3}>
              <RowExpanded
                status={status}
                restSessionScore={restSessionScore}
                files={session.files ?? []}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

SessionRow.propTypes = {
  meeting: PropTypes.shape({
    app: PropTypes.string,
    appMeetingName: PropTypes.string,
    appMeetingId: PropTypes.string,
    appMeetingUuid: PropTypes.string,
    appHostId: PropTypes.string,
    appParticipants: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        userId: PropTypes.string,
        userName: PropTypes.string
      })
    ),
    meetingId: PropTypes.string,
    restSessionId: PropTypes.string,
    restSessionScore: PropTypes.shape({
      scoreId: PropTypes.string,
      measures: PropTypes.shape({
        anxietyBinary: PropTypes.string,
        anxietyScore: PropTypes.number,
        depressionBinary: PropTypes.string,
        anxietyLevel: PropTypes.string,
        depressionLevel: PropTypes.string,
        depressionScore: PropTypes.string
      }),
      wordCount: PropTypes.number,
      recordingLength: PropTypes.number
    }),
    partnerId: PropTypes.string,
    // recordingEndedAt: PropTypes.instanceOf(Date),
    // createdAtUtc: PropTypes.instanceOf(Date),
    // endedAtUtc: PropTypes.instanceOf(Date),
    meetingEvents: PropTypes.arrayOf(
      PropTypes.shape({
        eventName: PropTypes.string
      })
    )
  })
};

export default SessionRow;
