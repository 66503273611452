import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MdArrowDropDown } from 'react-icons/md';

import {
  Menu,
  MenuItem,
  Typography,
  CircularProgress
} from '@material-ui/core';

import firebase from '../../../config/firebase';
import { getClinicians } from './getClinicians';

const styles = theme => ({
  container: {
    flexDirection: 'row',
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.main
  },
  text: {
    textDecoration: 'underline'
  }
});

function ClinicianSelect({ classes, patient, onChange, role, email, shouldUpdate = false }) {
  const [anchor, setAnchor] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleSelect = async item => {
    const updatePatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-updatePatient');
    setAnchor(null);
    setLoading(true);
    try {
      if (shouldUpdate) 
        await updatePatient({
          id: patient.uid,
          data: { assignedAdminId: item ? item.id : null }
        });
      onChange({ assignedAdminId: item ? item.id : null });
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    let isCancelled = false;

    const loadData = async () => {
      setLoading(true);
      try {
        const resp = await getClinicians(patient.partner);

        if (!isCancelled) {
          setItems(
            resp.filter(el =>
              role === 'super' || role === 'admin'
                ? true
                : el.id === email || el.id === patient.assignedAdminId
            )
          );
        }
      } catch (e) {
        // silence error
        console.error(e);
      }
      setLoading(false);
    };

    if (patient.partner) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [patient.partner, role]);

  useEffect(() => {
    const selectedItem = items.find(
      item => item.id === patient.assignedAdminId
    );
    setSelected(selectedItem);
  }, [items, patient.assignedAdminId]);

  if (loading) {
    return <CircularProgress size={20} />;
  }

  if (role === 'viewer') {
    return (
      <div>
        {selected
          ? `${selected.firstName || ''} ${selected.lastName || ''}`
          : ' - '}
      </div>
    );
  }

  return (
    <div>
      <div className={classes.container} onClick={e => setAnchor(e.target)}>
        <Typography variant="body1" className={selected && classes.text}>
          {selected
            ? `${selected.firstName || ''} ${selected.lastName || ''}`
            : ' - '}
        </Typography>
        <MdArrowDropDown />
      </div>
      <Menu
        id="clinician-select"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        <MenuItem
          key="selected-null"
          onClick={() => handleSelect(null)}
          selected={patient.assignedAdminId === null}
        >
          {' - '}
        </MenuItem>
        {items.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => handleSelect(item)}
            selected={patient.assignedAdminId === item.id}
          >
            {item.firstName} {item.lastName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ClinicianSelect.propTypes = {
  patient: PropTypes.object,
  onChange: PropTypes.func,
  role: PropTypes.oneOf(['super', 'admin', 'clinician', 'viewer']),
  email: PropTypes.string,
  shouldUpdate: PropTypes.bool,
};

export default withStyles(styles)(ClinicianSelect);
