import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography, Snackbar } from '@material-ui/core';
import SessionBox from './sessionBox';

import { getCustomSurveys } from '../../../common/form/getCustomSurveys';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px 0px 40px',
    width: '100%',
    [theme.breakpoints.down(850)]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  cont_one: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    marginBottom: '20px',
    [theme.breakpoints.down(850)]: {
      width: '100%',
      alignItems: 'center'
    }
  },
  sorting: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    alignItems: 'center',
    marginTop: 20,
    [theme.breakpoints.down(850)]: {
      flexDirection: 'column',
      marginBottom: 5
    }
  },
  cont_two: {
    maxHeight: '500px', // best 75
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#b8b8b8',
    padding: 8,
    display: 'grid',
    overflowY: 'auto',
    backgroundColor: theme.palette.secondary.extralight
  },
  text: {
    color: theme.palette.text.main
  }
});

function Bottom({
  classes,
  info,
  sessions,
  role,
  timezone,
  showEHResults,
  hideRecordings,
  columnsToShow,
  update,
  saving,
  savingText,
  loadRecordings,
  createCSV,
  recordings,
  topicQuestions
}) {
  // Check if EH results must be shown
  const ehResults = typeof showEHResults === 'undefined' || showEHResults;

  const [order, setChecked] = React.useState(1);
  const [snackbar, setSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [surveyNames, setSurveyNames] = React.useState([]);

  let timeout;

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const resp = await getCustomSurveys();
        const surveys = [];
        resp.forEach(s => {
          surveys[s.id] = s.name;
        });
        setSurveyNames(surveys);
      } catch (e) {
        // silence error
        console.error(e);
      }
      setLoading(false);
    };

    loadData();
  }, [info]);

  const handleChange = event => {
    if (order !== event.target.value) {
      setChecked(parseInt(event.target.value, 10));
    }
  };

  const updateSession = data => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      data.sessions = sessions;
      setSnackbar(true);
      update(data);
    }, 2000);
  };

  const orderSessions = order === 1 ? sessions : [...sessions].reverse();

  return (
    <div className={classes.root}>
      <div className={classes.cont_one}>
        <Typography className={classes.text} variant="h6">
          Session Details
        </Typography>
        <div className={classes.sorting}>
          <Typography
            style={{ marginRight: 10 }}
            color="inherit"
            variant="subtitle2"
          >
            Show:
          </Typography>
          <TextField
            disabled={saving}
            style={{ minWidth: '100px' }}
            select
            onChange={handleChange}
            value={order}
            SelectProps={{
              native: true,
              disableUnderline: true
            }}
          >
            <option key={1} value={1}>
              Most recent first
            </option>
            <option key={2} value={2}>
              Older first
            </option>
          </TextField>
        </div>
      </div>
      <div className={classes.cont_two} id="session-list">
        {!loading
          ? orderSessions.map((session, index) => (
            <SessionBox
              key={`session_${order}_${session.uid}`}
              patient={info}
              session={session}
              role={role}
              timezone={timezone}
              ehResults={ehResults}
              hideRecordings={hideRecordings}
              columnsToShow={columnsToShow}
              index={order === 1 ? orderSessions.length - index : index + 1}
              update={updateSession}
              saving={saving}
              loadRecordings={loadRecordings}
              surveyNames={surveyNames}
              createCSV={createCSV}
              recordings={recordings}
              topicQuestions={topicQuestions}
            />
          ))
          : ''}
      </div>
      <Snackbar
        open={snackbar}
        autoHideDuration={4000}
        onClose={() => setSnackbar(false)}
        message={savingText}
      />
    </div>
  );
}

Bottom.propTypes = {
  sessions: PropTypes.array,
  timezone: PropTypes.string,
  classes: PropTypes.object,
  update: PropTypes.func,
  saving: PropTypes.bool
};

const enhance = withStyles(styles);

export default enhance(Bottom);
