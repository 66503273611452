import firebase from '../../../config/firebase';

/* Upload file to temporary cloud storage. */
const deleteTempFile = async (fileRef) => {
  const storageResponse = await fileRef.delete();

  return storageResponse
}

/* Deletes the temporary file */
const saveTempFile = async (file) => {
  const timestampHash = Number(new Date()).toString(36);

  const folderRef = firebase
    .storage()
    .ref()
    .child('tempPortalUploads')
  const fileRef = folderRef.child(`${timestampHash}/${file.name}`);

  const { metadata } = await fileRef.put(file);

  return { metadata, fileRef }
}

/* Calls a cloud function to upload the file at given path to sftp */
export const uploadToSFTP = async (
  file,
  partnerId,
  batchId,
  sessionId,
  sessionTimestamp,
  patientUserId,
  agentUserId,
  userName,
  patientFirstName,
  patientLastName,
  dob,
  memberProgramId,
  agentFirstName,
  agentLastName,
) => {
  const { metadata, fileRef } = await saveTempFile(file);

  const functions = firebase.functions();
  // functions.useFunctionsEmulator('http://localhost:5001');
  const uploadSession = functions.httpsCallable('uploadSession', { timeout: 540000 });

  const response = await uploadSession({
    metadata,
    partnerId,
    batchId: batchId || undefined,
    sessionId: sessionId || undefined,
    sessionTimestamp: sessionTimestamp.toISOString() || undefined,
    patientUserId: patientUserId || undefined,
    agentUserId: agentUserId || undefined,
    userName,
    patientFirstName: patientFirstName || undefined,
    patientLastName: patientLastName || undefined,
    dob: dob?.toISOString().slice(0, 10) || undefined,
    memberProgramId: memberProgramId || undefined,
    agentFirstName: agentFirstName || undefined,
    agentLastName: agentLastName || undefined,
  });

  await deleteTempFile(fileRef);
  console.log(response);
  if (response.data?.message === 'Error') {
    console.log('error')
    throw new Error('Error uploading session to sftp.');
  }

  return response;
};
