import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Link,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import PhoneInputField from '../../../common/phoneInputField/PhoneInputField';
import logo from '../../../../assets/logo.png';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  progress: {
    margin: theme.spacing(2)
  }
});

function SendOTP(props) {
  const { classes, load, next, submit } = props;
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+1 (   )    -    ');
  const [radio, setRadio] = useState('email');
  const [errorMessage, setError] = useState('');

  const handleRadioChange = event => {
    setRadio(event.target.value);
    setError('')
  };

  const validateEmail = value => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      setError('Invalid email address')
      return
    }
    setError('')
    submit({
      phone: email.trim().toLowerCase(),
      method: 'EMAIL/PORTAL',
      partner: ''
    })
  };

  const validatePhone = value => {
    if (!value || !/^\+\d{11}$/gi.test(value.replace(/[^0-9+]/g, ''))) {
      setError('Invalid phone number')
      return
    }
    setError('')
    submit({
      phone: `+${phone.replace(/\D/g, '')}`, // Remove +1 and all non digit chars
      method: 'TEXT/PORTAL',
      partner: ''
    })
  }

  return (
    <>
      <img alt="" src={logo} height="100" />
      <form className={classes.form}>
        <Typography style={{ marginTop: 10 }} align="center" variant="h6">
          Select email or phone
        </Typography>
        <FormControl margin="normal" fullWidth component="fieldset">
          <RadioGroup
            row
            style={{ alignSelf: 'center' }}
            aria-label="position"
            name="position"
            value={radio}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="email"
              control={<Radio color="primary" />}
              label="Email"
            />
            <FormControlLabel
              value="phone"
              control={<Radio color="primary" />}
              label="Phone"
            />
          </RadioGroup>
        </FormControl>

        {radio === 'email' ? (
          <FormControl fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input value={email} onChange={(event) => setEmail(event.target.value)} variant="outlined" id="email" name="email" />
          </FormControl>
        ) : (
          <PhoneInputField phone={phone} setPhone={(event) => setPhone(event.target.value)} />
        )}

        <Typography style={{ marginTop: 10, fontSize: 12, color: '#ff0000' }} align="center" variant="body1">
          {errorMessage}
        </Typography>

        {load ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              if (radio === 'email') {
                validateEmail(email)
                return
              }
              validatePhone(phone)
            }}
          >
            Send code
          </Button>
        )}

        <Typography style={{ marginTop: 10, fontSize: 12 }} align="center" variant="body1">
          <Link
            underline="always"
            onClick={() => {
              next(1);
            }}
            color="primary"
            style={{ cursor: 'pointer' }}
          >
            Back
          </Link>
        </Typography>
      </form>
    </>
  );
}

SendOTP.propTypes = {
  classes: PropTypes.object.isRequired,
  load: PropTypes.bool,
  next: PropTypes.func,
  submit: PropTypes.func
};

export default withStyles(styles)(SendOTP);
