import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Date formatting function
function formatDate(date, format, timezone) {
    return date ? `${moment(date).tz(timezone ?? 'America/Los_Angeles').format(format ?? 'L LT z')}` : "n/a";
}

// React component that uses Redux state for timezone
function Date({ date, format, timezone }) {
    const formattedDate = date ? `${moment(date).tz(timezone ?? 'America/Los_Angeles').format(format ?? 'L LT z')}` : "n/a";

    return (
      <div>{formattedDate}</div>
    );
}

Date.propTypes = {
    date: PropTypes.object,
    timezone: PropTypes.string
};

const mapStateToProps = (state) => {
    const { portalConfig } = state.login;

    return {
        timezone: portalConfig?.configurations?.partnerTimezone
    };
};

const DateComponent = connect(mapStateToProps)(Date);

// Export both the component and the formatting function
export { DateComponent, formatDate };
