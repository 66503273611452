import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  DialogTitle,
  Button,
  Checkbox,
  DialogContent,
  CircularProgress,
  Typography,
  TextField,
  Link,
  FormControlLabel,
  Radio,
  Select,
  MenuItem
} from '@material-ui/core';

import notifier from '../../../common/utils/notifier';

import ClinicianSelect from '../../../common/form/ClinicianSelect';
import firebase from '../../../../config/firebase';

const REGISTER = 'register';
const INVITE = 'invite';

const styles = theme => ({
  title: {
    color: theme.palette.primary.main
  },
  actions: {
    backgroundColor: '#ffffff',
    height: 50,
    position: 'sticky',
    width: '100%',
    bottom: 0,
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    textTransform: 'none'
  }
});

function NewPatient({
  classes,
  partners,
  type,
  close,
  years,
  config,
}) {
  const [values, setValues] = useState({
    email: '',
    mrn: '',
    name: '',
    lastName: '',
    birthyear: '',
    phone: '',
    gender: '',
    partner: partners[0],
    assignedAdminId: ''
  });
  const [repeatValues, setRepeatValues] = useState({
    repeatemail: '',
    repeatmrn: '',
    repeatphone: ''
  });

  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const { role, email } = config;

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeRepeatValues = e => {
    const { name, value } = e.target;
    setRepeatValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangePhone = event => {
    const { value } = event.target; // <-- moved outside asynchronous context

    const re = /^\d{10}$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      setValues(prevState => ({
        ...prevState,
        phone: value
      }));

      setPhoneError('');
      setSuccess('');
      setError('');
    } else {
      setValues(prevState => ({
        ...prevState,
        phone: value
      }));

      setPhoneError('The phone number must contain 10 digits. E.g, 9876543210');
    }

    if (value.length < 1) {
      setPhoneError('');
    }
  };

  const handleChangeEmail = event => {
    const { value } = event.target; // <-- moved outside asynchronous context

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value).toLowerCase())) {
      setValues(prevState => ({
        ...prevState,
        email: value
      }));
      setEmailError('');
      setSuccess('');
      setError('');
    } else {
      setValues(prevState => ({
        ...prevState,
        email: value
      }));
      setEmailError(
        'Please, insert a valid email address. E.g, ellipsis@ellipsishealth.com'
      );
    }

    if (value.length < 1) {
      setEmailError('');
    }
  };

  const resetSuccess = () => {
    setSuccess('New patient invited correctly!');
    setLoading(false);
    setValues({
      mrn: '',
      name: '',
      lastName: '',
      birthyear: '',
      phone: '',
      email: '',
      gender: '',
      partner: partners[0],
      assignedAdminId: ''
    });

    setRepeatValues({
      repeatemail: '',
      repeatmrn: '',
      repeatphone: ''
    });

    setPhoneError('');
    setEmailError('');
  };

  const registerNewPatient = () => {
    setLoading(true);
    if(values.phone === '' && values.email === ''){
      setLoading(false);
      setError('An phone number must be insterted.');
      return;
    }
    if (
      values.phone !== repeatValues.repeatphone ||
      values.mrn !== repeatValues.repeatmrn ||
      values.email !== repeatValues.repeatemail
    ) {
      setLoading(false);
      setError('Fields do not match.');
      return;
    }

    let patient = {
      mrn: values.mrn,
      name: values.name,
      lastName: values.lastName,
      gender: values.gender,
      birthyear: values.birthyear,
      assignedAdminId: values.assignedAdminId
    }; // Add the +1 in front of the phone number

    if(values.phone){
      patient = {...patient, phone: `+1${values.phone}`}
    }
    if(values.email){
      patient = {...patient, email: values.email}
    }

    const invitePatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-invitePatient');
    invitePatient({
      partnerId: values.partner,
      patient,
      type
    })
      .then((result) => {
        if (result.data.success) {
          notifier.success(result.data.message);
          if(checked === true){
            resetSuccess();
            return 
          }
          close();
          return
        }
        // No success
        notifier.error(result.data.message);
        setLoading(false);

      })
      .catch(e => {
        console.log('An error occurred while adding a new patient: ', e);
        setLoading(false);
        setError(
          'Sorry, something went wrong. Please try again later or contact support@ellipsishealth.com'
        );
      });
  };

  return (
    <div>
      <DialogTitle className={classes.title}>
        {type[0].toUpperCase() + type.slice(1).toLowerCase()} A New Patient
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          To {type[0].toUpperCase() + type.slice(1).toLowerCase()} a new patient, please enter the following information
          about them. After the patient conducts a session, their assessment will be
          viewable in your Dashboard. Fields with * are required.
        </Typography>

        <div style={{ marginTop: 20 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            First Name:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            autoComplete={false}
            value={values.name}
            name="name"
            onChange={handleChange}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Last Name:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={values.lastName}
            name="lastName"
            onChange={handleChange}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Birth Year:
          </Typography>
          <div style={{ width: 5 }} />
          <Select
            disabled={loading === true}
            value={values.birthyear}
            name="birthyear"
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {years.map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Gender:
          </Typography>
          <div style={{ width: 5 }} />
          <Select
            disabled={loading === true}
            value={values.gender}
            name="gender"
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value="">
              <em>Select One:</em>
            </MenuItem>
            <MenuItem value="M">
              <em>Male</em>
            </MenuItem>
            <MenuItem value="F">
              <em>Female</em>
            </MenuItem>
            <MenuItem value="O">
              <em>Other</em>
            </MenuItem>
            <MenuItem value="Undisclosed">
              <em>Prefer not to disclose</em>
            </MenuItem>
          </Select>
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Followed by:
          </Typography>
          <div style={{ width: 5 }} />
          <ClinicianSelect
            patient={{
              partner: values.partner,
              assignedAdminId: values.assignedAdminId
            }}
            onChange={d => {
              setValues(prevState => ({
                ...prevState,
                assignedAdminId: d.assignedAdminId
              }));
            }}
            role={role}
            email={email}
          />
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Patient ID/MRN * :
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={values.mrn}
            onChange={event => {
              handleChange(event);
              setSuccess('');
              setError('');
            }}
            name="mrn"
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>
        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Re-Enter Patient ID/MRN * :
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={repeatValues.repeatmrn}
            onChange={handleChangeRepeatValues}
            name="repeatmrn"
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>

        <div
          style={
            values.email.length > 0 && type === REGISTER
              ? { marginTop: 10, opacity: '0.2' }
              : { marginTop: 10 }
          }
          className={classes.row}
        >
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Mobile Number * :
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            value="+1"
            disabled
            inputProps={{
              style: {
                padding: 8,
                width: 20
              }
            }}
            variant="outlined"
          />
          <div style={{ width: 2 }} />
          <TextField
            placeholder="E.g, 9876543210"
            disabled={loading === true || (values.email.length > 0 && type === REGISTER)}
            value={values.phone}
            onChange={handleChangePhone}
            error={phoneError.length > 0 || false}
            inputProps={{
              style: {
                padding: 8,
                width: 142
              }
            }}
            style={values.email.length > 0 && type === REGISTER ? { opacity: '0.2' } : {}}
            variant="outlined"
          />
        </div>
        <Typography style={{ color: '#ff0000', margin: 10, fontSize: 12 }} variant="body1">
          {phoneError}
        </Typography>
        <div
          style={
            values.email.length > 0 && type === REGISTER
              ? { marginTop: 10, opacity: '0.2' }
              : { marginTop: 10 }
          }
          className={classes.row}
        >
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Re-Enter Mobile Number * :
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            value="+1"
            disabled
            inputProps={{
              style: {
                padding: 8,
                width: 20
              }
            }}
            variant="outlined"
          />
          <div style={{ width: 2 }} />
          <TextField
            placeholder="E.g, 9876543210"
            disabled={loading === true || values.email.length > 0 && type === REGISTER}
            value={repeatValues.repeatphone}
            onChange={handleChangeRepeatValues}
            name="repeatphone"
            inputProps={{
              style: {
                padding: 8,
                width: 142
              }
            }}
            style={values.email.length > 0 && type === REGISTER ? { opacity: '0.2' } : {}}
            variant="outlined"
          />
        </div>
        
        <div
          style={
            values.phone.length > 0 && type === REGISTER
              ? { marginTop: 10, opacity: '0.2' }
              : { marginTop: 10 }
          }
          className={classes.row}
        >
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            {type === REGISTER ? 'Email * :' : 'Email:'}
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true || (values.phone.length > 0 && type === REGISTER)}
            value={values.email}
            onChange={handleChangeEmail}
            error={emailError.length > 0 || false}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            style={values.phone.length > 0 && type === REGISTER ? { opacity: '0.2' } : {}}
            variant="outlined"
          />
        </div>
        <Typography style={{ color: '#ff0000', margin: 10, fontSize: 12 }} variant="body1">
          {emailError}
        </Typography>
        <div
          style={
            values.phone.length > 0 && type === REGISTER
              ? { marginTop: 10, opacity: '0.2' }
              : { marginTop: 10 }
          }
          className={classes.row}
        >
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            {type === REGISTER ? 'Re-Enter Email * :' : 'Re-Enter Email:'}
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true || (values.phone.length > 0 && type === REGISTER)}
            value={repeatValues.repeatemail}
            onChange={handleChangeRepeatValues}
            name="repeatemail"
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            style={values.phone.length > 0 && type === REGISTER ? { opacity: '0.2' } : {}}
            variant="outlined"
          />
        </div>

        <Typography style={{ fontSize: 12, marginTop: 10 }} variant="body1">
          {type === REGISTER ? '* required fields. Insert only one between email and phone.' : '* required fields'}
        </Typography>

        <Typography style={{ color: '#ff0000', marginTop: 20, fontSize: 12 }} variant="body1">
          {error}
        </Typography>
        <Typography style={{ color: '#148316', marginTop: 20, fontSize: 12 }} variant="body1">
          {success}
        </Typography>

        {partners.length > 1
          ? partners.map(p => (
              // eslint-disable-next-line react/jsx-indent
              <FormControlLabel
                value={p}
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Radio
                    checked={values.partner === p}
                    onChange={setValues(prevState => ({
                      ...prevState,
                      partner: p
                    }))}
                    label
                    value={p}
                    inputProps={{ 'aria-label': `${p}` }}
                  />
                }
                label={p}
              />
            ))
          : ''}

        <div style={{ height: 25 }} />
        <div className={classes.row}>
          <Link color="primary" disabled={loading === true} onClick={close}>
            <Typography variant="body1" color="primary">
              Cancel
            </Typography>
          </Link>
          <div style={{ flex: 1 }} />
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                disabled={loading === true}
                checked={checked}
                onChange={() => setChecked(!checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Add another"
          />
          {loading === false ? (
            <Button
              disabled={
                (type === INVITE && values.phone === '') ||
                phoneError.length > 0 ||
                values.mrn.length === 0 ||
                emailError.length > 0
              }
              variant="contained"
              color="primary"
              onClick={registerNewPatient}
              className={classes.button}
            >
              {type[0].toUpperCase() + type.slice(1).toLowerCase()} Patient
            </Button>
          ) : (
            <CircularProgress />
          )}
        </div>
      </DialogContent>
    </div>
  );
}

NewPatient.propTypes = {
  classes: PropTypes.object,
  close: PropTypes.func,
  type: PropTypes.string,
  partners: PropTypes.array,
  years: PropTypes.array,
  config: PropTypes.object,
};

export default withStyles(styles)(NewPatient);
