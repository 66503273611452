import React, { useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MdCheck, MdClose, MdCheckCircle, MdError } from 'react-icons/md';

import PropTypes from 'prop-types';

import { statuses as _statuses } from '../utils/constants';
// import Scores from './Scores';
import Score from './Score';

const useStyles = makeStyles({
  section: {
    padding: '1rem'
  },
  sectionTitle: {
    fontSize: '14px',
    lineHeight: '34px',
    fontWeight: 700
  },
  sectionTitleRed: {
    fontSize: '14px',
    lineHeight: '34px',
    fontWeight: 700,
    color: 'red',
  },
  sectionContent: {
    margin: '1rem 0.5rem'
  },
  statusIndicator: {
    marginBottom: '6px',
    marginTop: '6px',
    alignItems: 'center',

    '& > span, & > svg': {
      width: '1.2rem',
      height: '1.2rem',
      borderRadius: '50%',
      marginRight: '2rem',
      fill: '#5280C9'
    }
  },
  status: {
    fontSize: 12,
    fontWeight: 700,
  },
  timestamp: {
    fontSize: 10,
    color: '#6C52aD',
    marginLeft: '8px'
  }
});

const RowExpanded = props => {
  const { status, restSessionScore = {}, files } = props;
  const classes = useStyles();

  const isFailed = useMemo(() => !!status.find(el => el.name === 'Failed'), [status]);
  const statuses = useMemo(() => {
    return [..._statuses].filter(el => el !== (isFailed ? 'Completed' : 'Failed'))
  }, [_statuses, status]);

  const statusPos = useMemo(
    () =>
      status.length
        ? statuses.findIndex(el => el === status[status.length - 1].name)
        : -1,
    [status]
  );

  return (
    <Box>
      <Grid item>
        <Typography
          className={files.length ? classes.sectionTitle : classes.sectionTitleRed}
        >
          {files.length ? "Files" : "No files available for this session."}
        </Typography>
        <Divider />
        {files?.length && files.map(file => (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box className={classes.sectionContent} style={{ margin: '1rem 0.5rem 0rem 0rem' }}>
                <Typography className={classes.status} style={{ display: 'inline-block', marginRight: 5 }}>File path: </Typography>
                {file.filePath || "-"}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.sectionContent} style={{ margin: '1rem 0.5rem 0rem 0rem' }}>
                <Typography className={classes.status} style={{ display: 'inline-block', marginRight: 5 }}>Available: </Typography>
                {file.available ? <MdCheckCircle size={10} style={{ color: 'green' }} /> : <MdError size={10} style={{ color: 'red' }} />}
              </Box>
            </Grid>
          </Grid>
        )
        )}
        <Grid container spacing={5} style={{ marginTop: 20 }}>
          <Grid item xs={5}>
            <Typography className={classes.sectionTitle}>Scoring Status</Typography>
            <Divider />
            <Box className={classes.sectionContent}>
              {statuses.map((_status, index) => (
                <Box
                  display="flex"
                  className={classes.statusIndicator}
                  key={_status}
                >
                  {statusPos === statuses.length - 1 &&
                    index === statuses.length - 1 ? (
                    isFailed ? <MdClose /> : <MdCheck />
                  ) : (
                    <span
                      style={{
                        borderColor: index > statusPos ? '#C6CACC' : '#5280C9',
                        borderWidth:
                          index === statusPos &&
                            (status[status.length - 1].name === 'Session Submitted' ||
                              status[status.length - 1].name ===
                              'Calculating Insights (~5 mins)')
                            ? '1.8px'
                            : '4px',
                        borderStyle:
                          index === statusPos &&
                            (status[status.length - 1].name === 'Session Submitted' ||
                              status[status.length - 1].name ===
                              'Calculating Insights (~5 mins)')
                            ? 'dashed'
                            : 'solid'
                      }}
                    />
                  )}
                  <Typography className={classes.status}>{_status}</Typography>
                  <Typography className={classes.timestamp}>
                    {status.find(el => el.name === _status)?.timestamp || ''}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.sectionTitle}>Insights</Typography>
            <Divider />
            <Box className={classes.sectionContent}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {/* <SpeakerAllocation /> */}
                </Grid>
                <Grid item xs={0}>
                  {/* <Scores /> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.sectionTitle}>Scores</Typography>
            <Divider />
            <Box className={classes.sectionContent}>
              <Grid container spacing={3}>
                {/* <Grid item sx={6}>
                <Level
                  title="Anxiety level"
                  level={restSessionScore?.measures?.anxietyLevel}
                />
              </Grid> */}
                <Grid item sx={6}>
                  <Score
                    title="Anxiety score"
                    level={restSessionScore?.measures?.anxietyLevel}
                    score={restSessionScore?.measures?.anxietyLevel}
                  />
                </Grid>
                {/* <Grid item sx={6}>
                <Level
                  title="Depression level"
                  level={restSessionScore?.measures?.depressionLevel}
                />
                  </Grid> */}
                <Grid item sx={6}>
                  <Score
                    title="Depression score"
                    level={restSessionScore?.measures?.depressionLevel}
                    score={restSessionScore?.measures?.depressionLevel}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

RowExpanded.protoTypes = {
  status: PropTypes.arrayOf(PropTypes.string),
  restSessionScore: PropTypes.shape({
    scoreId: PropTypes.string,
    measures: PropTypes.shape({
      anxietyBinary: PropTypes.string,
      anxietyScore: PropTypes.number,
      depressionBinary: PropTypes.string,
      anxietyLevel: PropTypes.string,
      depressionLevel: PropTypes.string,
      depressionScore: PropTypes.string
    }),
    wordCount: PropTypes.number,
    recordingLength: PropTypes.number
  })
};

export default RowExpanded;
