import moment from "moment-timezone";
import { statuses as _statuses } from "./constants";

export const statusGenerator = meetingEvents => {
  const result = [];
  const events = meetingEvents.map(({ eventName, createdAtUtc }) => ({
    eventName,
    timestamp: moment
      .unix(createdAtUtc.seconds)
      .tz('America/Los_Angeles')
      .format('LTS')
  }));

  const pickEvent = name => events.find(el => el.eventName === name);

  const statuses = [..._statuses].filter(el => el !== (pickEvent('session.failed') ? 'Completed' : 'Incomplete'));

  if (pickEvent('meeting.started'))
    result.push({
      name: 'Meeting Started',
      timestamp: pickEvent('meeting.started').timestamp
    });
  if (pickEvent('meeting.patient_joined'))
    result.push({
      name: 'Patient Joined',
      timestamp: pickEvent('meeting.patient_joined').timestamp
    });
  if (pickEvent('meeting.clinician_joined'))
    result.push({
      name: 'Clinician Joined',
      timestamp: pickEvent('meeting.clinician_joined').timestamp
    });
  if (
    pickEvent('meeting.started') &&
    pickEvent('meeting.patient_joined') &&
    pickEvent('meeting.clinician_joined')
  )
    result.push({ name: 'In Progress' });
  if (pickEvent('meeting.ended'))
    result.push({
      name: 'Meeting Finished',
      timestamp: pickEvent('meeting.ended').timestamp
    });

  if (pickEvent('recording.completed') && pickEvent('meeting.noshow_patient')) {
    result.push({
      name: 'Completed - Patient did not join',
      // timestamp: pickEvent('recording.completed').timestamp
    });
  } else if (pickEvent('recording.completed') && pickEvent('meeting.noshow_provider')) {
    result.push({
      name: 'Completed - Provider did not join',
      // timestamp: pickEvent('recording.completed').timestamp
    });
  } else if (pickEvent('recording.completed')) {
    result.push({
      name: 'Recording Received (~3 mins)',
      timestamp: pickEvent('recording.completed').timestamp
    });
    result.push({
      name: 'Calculating Insights (~5 mins)',
      timestamp: pickEvent('recording.completed').timestamp
    });
  }

  if (pickEvent('session.scored'))
    result.push({
      name: 'Completed',
      timestamp: pickEvent('session.scored').timestamp
    });

  if (pickEvent('session.failed')) {
    result.push({
      name: "Incomplete",
      timestamp: pickEvent('session.failed').timestamp
    })
  }

  if (pickEvent('recording.unavailable')) {
    result.push({
      name: "Incomplete - Audio unavailable",
      timestamp: pickEvent('recording.unavailable').timestamp
    })
  }

  return result.sort((a, b) =>
    statuses.findIndex(el => el === a.name) <
      statuses.findIndex(el => el === b.name)
      ? -1
      : 1
  );
};