import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import logo from '../../../assets/logo.png';

const styles = () => ({
  // theme
  root: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

function SetPasswordResponse({ classes, props }) {
  const location = useLocation();
  const queryValues = queryString.parse(location.search);
  const [response] = useState(
    queryValues.response ? queryValues.response : null
  );
  return (
    <div className={classes.root}>
      <img alt="" src={logo} height="100" />      
      <Typography
        style={{ marginTop: 10, fontSize: '20px' }}
        component="h2"
        variant="h2"
      >
        {response === '0'
          ? 'Your set password link has expired. Please contact your admin or write to support@ellipsishealth.com . You may now close this page.'
          : response === '1'
            ? 'You have correctly set a new password for the in clinic application! You may now close this page.'
            : 'You have correctly set a new password for the critical alert dashboard! You may now close this page.'}
      </Typography>
    </div>
  );
}

SetPasswordResponse.propTypes = {
  classes: PropTypes.object,
  props: PropTypes.object
};

export default withStyles(styles)(SetPasswordResponse);
