import moment from "moment-timezone";
import { statuses as _statuses } from "./constants";

export const statusGenerator = sessionEvents => {
  const result = [];
  const events = sessionEvents.map(({ event, createdAtUtc }) => ({
    event,
    timestamp: moment
      .unix(createdAtUtc.seconds)
      .tz('America/Los_Angeles')
      .format('LTS')
  }));

  const pickEvent = name => events.find(el => el.event === name);

  const statuses = [..._statuses].filter(el => el !== (pickEvent('Job.session.failed') ? 'Completed' : 'Incomplete'));

  if (pickEvent('Job.started'))
    result.push({
      name: 'Process Started',
      timestamp: pickEvent('Job.started').timestamp
    });
  if (pickEvent('Job.downloaded'))
    result.push({
      name: 'Audio files downloaded',
      timestamp: pickEvent('Job.downloaded').timestamp
    });
  if (pickEvent('Job.session.created'))
    result.push({
      name: 'Session created in db',
      timestamp: pickEvent('Job.session.created').timestamp
    });
  if (pickEvent('Job.session.submitted'))
    result.push({
      name: 'Session submitted for scoring',
      timestamp: pickEvent('Job.session.submitted').timestamp
    });
  /* if (
    pickEvent('meeting.started') &&
    pickEvent('meeting.patient_joined') &&
    pickEvent('meeting.clinician_joined')
  )
    result.push({ name: 'In Progress' }); */
  if (pickEvent('Job.session.completed'))
    result.push({
      name: 'Completed',
      timestamp: pickEvent('Job.session.completed').timestamp
    });

  if (pickEvent('Job.session.failed'))
    result.push({
      name: "Incomplete",
      timestamp: pickEvent('Job.session.failed').timestamp
    })

  return result.sort((a, b) =>
    statuses.findIndex(el => el === a.name) <
      statuses.findIndex(el => el === b.name)
      ? -1
      : 1
  );
};