import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  IconButton,
  Button,
  Collapse,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdInsertDriveFile,
  MdPlayArrow,
} from 'react-icons/md';

import SessionDropTable from './components/SessionDropTable';
import { getFileDropsInfo, scoreMultipleSessions } from './utils/getFileDropsInfo';

import Dialog from '../common/dialog/dialog';
import CSVViewerDialog from '../common/dialog/csvViewerDialog';

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  icon: {
    width: '24px',
    height: '24px'
  }
});

const Wrapper = ({ children }) => (
  <Container maxWidth="xl" style={{ padding: '24px' }}>
    {children}
  </Container>
);


const FileDropCollapsibleTable = props => {
  const { portalConfig } = props;
  const [fileDrops, setFileDrops] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const partnerId = portalConfig.portalActivePartners[0];

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setError("")
      const data = await getFileDropsInfo(partnerId);

      if (data.status === 'success' && data.indices?.length) {
        setFileDrops(data.indices || []);
        setError("");
      } else if (data.status === 'error') {
        setFileDrops([]);
        setError("Sorry, something went wrong while getting the file drops information. Please, try again later or contact support@ellipsishealth.com");
      }

      setLoading(false);
    };

    getData();
  }, [getFileDropsInfo, partnerId]);

  if (loading)
    return (
      <Wrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '300px' }}
        >
          <CircularProgress />
        </Box>
      </Wrapper>
    );

  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead />
          <TableBody>
            {fileDrops.map((drop) => (
              <FileDropRow key={drop.indexName} row={drop} partnerId={partnerId} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}


const FileDropRow = props => {
  const { row, partnerId } = props;
  const classes = useRowStyles();
  const [openModule, setOpenModule] = useState(false);
  const [openCSVDialog, setOpenCSVDialog] = useState(false);

  const [loading, setLoading] = useState(false);


  const openDialog = () => {
    // const { dialog } = this.state;

    return (
      <Dialog maxWidth="lg" close={() => setOpenCSVDialog(false)}>
        <CSVViewerDialog
          fileContent={row.contents}
          close={() => setOpenCSVDialog(false)}
        />
      </Dialog>
    );
  };

  const runMultipleScoring = async () => {
    setLoading(true);
    await scoreMultipleSessions(partnerId, row.indexName);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} style={{ background: "#5687d6" }}>

        <TableCell style={{ color: "white" }}>
          <Typography style={{ fontWeight: 700, display: 'inline-block', marginRight: '10px' }}>
            File Name:
          </Typography>
          <Typography style={{ display: 'inline-block' }}>
            {row.indexName}
          </Typography>
        </TableCell>

        <TableCell style={{ textAlign: 'end' }}>
          <Button
            style={{
              color: "white",
              whiteSpace: 'nowrap',
            }}
            variant="outlined" 
            onClick={() => setOpenCSVDialog(true)} 
            endIcon={<MdInsertDriveFile />}
          >
            Check file content
          </Button>
          <Button 
            style={{ color: "white", whiteSpace: 'nowrap', marginLeft: '15px' }}
            variant="outlined"
            onClick={() => runMultipleScoring()} 
            endIcon={<MdPlayArrow />}
            disabled={loading}
          >
            Score sessions
          </Button>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenModule(!openModule)}
            style={{ color: "white", marginLeft: '15px' }}
          >
            {openModule ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openModule} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <SessionDropTable
                    sessions={row.sessions}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {openCSVDialog ? openDialog() : ''}
    </React.Fragment>
  );
};

export default FileDropCollapsibleTable;
