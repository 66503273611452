import React from 'react';
import { connect } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';

import UploadComponent from './UploadComponent';

const mapStateToProps = state => {
  return {
    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login,
    portalConfig: state.login.portalConfig,
    portalActivePartners: state.login.portalConfig.portalActivePartners,
    userEmail: state.login.user.email
  };
};

const useStyles = makeStyles({
  root: {
    padding: '20px'
  },
  select: {
    marginBottom: '32px'
  }
});

const LiveCallsContainer = props => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <UploadComponent {...props} />
    </Box>
  );
};

export default connect(mapStateToProps)(LiveCallsContainer);
