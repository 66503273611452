import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

const styles = theme => ({
  dialog: {
    overflow: 'hidden'
  }
});

class MaxWidthDialog extends React.Component {
  state = {
    open: true,
    fullWidth: true
    // maxWidth: 'sm',
  };

  handleClose = () => {
    const { close } = this.props;
    close();
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  render() {
    const { classes, children } = this.props;
    const { fullWidth, maxWidth, open } = this.state;

    return (
      <React.Fragment>
        <Dialog
          className={classes.dialog}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={this.handleClose}
          scroll="paper"
        >
          <div>{children}</div>
        </Dialog>
      </React.Fragment>
    );
  }
}

MaxWidthDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MaxWidthDialog);
