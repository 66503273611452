import React from 'react';
import moment from 'moment';
import { /* Typography, */ TableCell, Tooltip } from '@material-ui/core';
import { MdInsertChartOutlined, MdCreate, MdList, MdTextsms, MdEmail } from 'react-icons/md';
import { GrDocumentPdf, GrDocumentCsv } from 'react-icons/gr';

import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

import { DateComponent, formatDate } from '../../../../components/Date';

import AssessmentDoc from '../../reports/pdf/d&a_assessment';

import Audio from '../../audioComponent/audio';

import ClinicianSelect from '../../form/ClinicianSelect';

import { MSHanswers } from "../../utils/mshUtils";

function tableField(id, value, props, newSession) {
  const { config, columnsName, showColumns } = props;
  const { configurations, role, email } = config;
  const { partnerTimezone } = configurations;

  /**
   * Field used for edits done in the switch below.
   */
  let editedFieldValue = null;

  /**
   * If the user has a viewer role, remove the restricted columns
   */
  if (
    role === 'viewer' &&
    configurations.user_restriction.restricted_fields_viewer.indexOf(id) > -1
  ) {
    return;
  }

  // Boolean values need to be strings
  if (value[id] === true) {
    value[id] = 'true';
  } else if (value[id] === false) {
    value[id] = 'false';
  }

  // Check if the user has 4 way classification (phq_4c and gad_4c) in the session table columns
  // In that case, show FourWayAssessmentChart
  const has4WayClassification = (showColumns.indexOf("phq_4c") !== -1 || showColumns.indexOf("gad_4c") !== -1);

  let tableValue = null;
  let color = '';

  // If the id = surveyScores, merge the custom survey arrays to produce only one
  let surveyScores = [];
  if (id === "surveyScores") {
    const sScores = value.custom ? value.custom : [];
    const sStatus = value.customStatus ? value.customStatus : [];

    surveyScores = sStatus.map(status => ({ ...status, ...sScores.find(score => score.survey === status.survey) }));
  }

  switch (id) {
    case 'name':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value[id] || ''}
        </TableCell>
      );

    case 'sessionId':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.uid}
        </TableCell>
      );

    case 'endTimeStamp':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <DateComponent date={value.endTimeStamp?.value} timezone={partnerTimezone} />
        </TableCell>
      );

    case 'startTimeStamp':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <DateComponent date={value.startTimeStamp?.value} timezone={partnerTimezone} />
        </TableCell>
      );

    case 'createdAt':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <DateComponent 
            date={value.createdAt?.value || value.createdTimestamp?.value || null} 
            timezone={partnerTimezone}
          />
        </TableCell>
      );

    case 'phqQ9':
      return (
        <TableCell
          style={
            value.phq9 &&
              value.phq9Status === 'COMPLETE' &&
              value.phqScores[8] > 0
              ? { color: '#be2020', fontWeight: 'bold' }
              : { fontWeight: newSession }
          }
          align="left"
        >
          {!value.phq9 || value.phq9Status === 'OFF'
            ? 'n/a'
            : value.phq9Status === 'COMPLETE'
              ? value.phqScores[8]
              : 'Incomplete'}
        </TableCell>
      );

    case 'phq9Total':
      editedFieldValue = value.phqTotal;
      return (
        <TableCell
          style={
            editedFieldValue >= 10 && value.phq9Status === 'COMPLETE'
              ? { color: '#be2020', fontWeight: 'bold' }
              : { fontWeight: newSession }
          }
          align="left"
        >
          {!value.phq9 || value.phq9Status === 'OFF'
            ? 'n/a'
            : value.phq9Status === 'COMPLETE'
              ? editedFieldValue
              : 'Incomplete'}
        </TableCell>
      );

    case 'phqTotal':
      editedFieldValue =
        value.phq9Status === 'COMPLETE'
          ? value.phqTotal - value.phqScores[8]
          : value.phqTotal;
      return (
        <TableCell
          style={
            editedFieldValue >= 10 && value.phqStatus === 'COMPLETE'
              ? { color: '#be2020', fontWeight: 'bold' }
              : { fontWeight: newSession }
          }
          align="left"
        >
          {value.phqStatus === 'OFF'
            ? 'n/a'
            : value.phqStatus === 'INCOMPLETE'
              ? 'Incomplete'
              : editedFieldValue}
        </TableCell>
      );

    case 'phq9TotalClickable':
      editedFieldValue = value.phqTotal;
      return (
        <TableCell
          onClick={() => props.openCellDialog(value, id)}
          style={
            editedFieldValue >= 10 && value.phq9Status === 'COMPLETE'
              ? { color: '#be2020', fontWeight: 'bold' }
              : { fontWeight: newSession }
          }
          align="left"
        >
          <div style={{ cursor: 'pointer' }}>
            {!value.phq9 || value.phq9Status === 'OFF'
              ? 'n/a'
              : value.phq9Status === 'COMPLETE'
                ? editedFieldValue
                : 'Incomplete'}
          </div>
        </TableCell>
      );

    case 'gadTotalClickable':
      return (
        <TableCell
          onClick={() => props.openCellDialog(value, id)}
          className={newSession}
          style={
            value.gadTotal >= 10 && value.gadStatus === 'COMPLETE'
              ? { color: '#be2020', fontWeight: 'bold', cursor: 'pointer' }
              : { fontWeight: newSession, cursor: 'pointer' }
          }
          align="left"
        >
          <div style={{ cursor: 'pointer' }}>
            {value.gadStatus === 'OFF'
              ? 'n/a'
              : value.gadStatus === 'INCOMPLETE'
                ? 'Incomplete'
                : value.gadTotal}
          </div>
        </TableCell>
      );

    case 'gadTotal':
      return (
        <TableCell
          className={newSession}
          style={
            value.gadTotal >= 10 && value.gadStatus === 'COMPLETE'
              ? { color: '#be2020', fontWeight: 'bold' }
              : { fontWeight: newSession }
          }
          align="left"
        >
          {value.gadStatus === 'OFF'
            ? 'n/a'
            : value.gadStatus === 'INCOMPLETE'
              ? 'Incomplete'
              : value.gadTotal}
        </TableCell>
      );

    case 'gadScores':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.gadScores !== null ? value.gadScores.join(',') : ''}
        </TableCell>
      );

    case 'phqScores':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.phqScores !== null ? value.phqScores.join(',') : ''}
        </TableCell>
      );

    case 'topicIds':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.topicIds !== null ? value.topicIds.join(',') : ''}
        </TableCell>
      );

    case 'questionIds':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.questionIds !== null ? value.questionIds.join(', ') : ''}
        </TableCell>
      );

    case 'questionDurations':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.questionDurations !== null
            ? value.questionDurations.join(',')
            : ''}
        </TableCell>
      );

    case 'nextValidDate':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <DateComponent date={value.nextValidDate} timezone={partnerTimezone} />
        </TableCell>
      );

    case 'lastSession':
      editedFieldValue = new Date(parseInt(value.lastSession, 10) * 1000);
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.lastSession !== null
            ? <DateComponent date={editedFieldValue} timezone={partnerTimezone} />
            : ''}
        </TableCell>
      );

    case 'wordCounts':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.wordCounts !== null ? value.wordCounts.join(',') : ''}
        </TableCell>
      );

    case 'followedBy':
      return (
        <TableCell align="left">
          <ClinicianSelect
            patient={value}
            onChange={d => {
              value.assignedAdminId = d.assignedAdminId;
            }}
            role={role}
            email={email}
            shouldUpdate
          />
        </TableCell>
      );

    case 'phq_r':
      tableValue = value.phq_r > 24 ? 24 : value.phq_r;

      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {tableValue !== -1 && (tableValue || tableValue === 0)
            ? `${Math.ceil(tableValue).toString()}`
            : 'Incomplete'}
        </TableCell>
      );

    case 'gad_r':
      tableValue = value.gad_r > 21 ? 21 : value.gad_r;

      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {tableValue !== -1 && (tableValue || tableValue === 0)
            ? `${Math.ceil(tableValue).toString()}`
            : 'Incomplete'}
        </TableCell>
      );

    case 'phq_4c':
      tableValue = value.phq_4c === 0 ? 'None' :
        value.phq_4c === 1 ? 'Mild' :
          value.phq_4c === 2 ? 'Moderate' :
            value.phq_4c === 3 ? 'Severe' : 'Incomplete';

      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {tableValue}
        </TableCell>
      );

    case 'phqTotalCategory':
      if (!value.phqStatus) {
        return (
          <TableCell style={{ fontWeight: newSession }} align="left">
            Incomplete
          </TableCell>
        );
      }

      if (value.phqStatus === 'INCOMPLETE' || value.phqStatus === 'OFF') {
        return (
          <TableCell style={{ fontWeight: newSession }} align="left">
            {value.phqStatus.charAt(0) + value.phqStatus.substring(1).toLowerCase()}
          </TableCell>
        );
      }

      tableValue = value.phqTotal < 5 ? 'None' :
        value.phqTotal < 10 ? 'Mild' :
          value.phqTotal < 15 ? 'Moderate' :
            value.phqTotal >= 15 ? 'Severe' : 'Incomplete';

      color = tableValue === 'Severe' ? '#be2020' : tableValue === 'Moderate' ? '#cbbc2d' : '';

      return (
        <TableCell style={{ fontWeight: newSession, color }} align="left">
          {tableValue}
        </TableCell>
      );

    case 'gadTotalCategory':
      if (!value.gadStatus) {
        return (
          <TableCell style={{ fontWeight: newSession }} align="left">
            Incomplete
          </TableCell>
        );
      }

      if (value.gadStatus === 'INCOMPLETE' || value.gadStatus === 'OFF') {
        return (
          <TableCell style={{ fontWeight: newSession }} align="left">
            {value.gadStatus.charAt(0) + value.gadStatus.substring(1).toLowerCase()}
          </TableCell>
        );
      }

      tableValue = value.gadTotal < 5 ? 'None' :
        value.gadTotal < 10 ? 'Mild' :
          value.gadTotal < 15 ? 'Moderate' :
            value.gadTotal >= 15 ? 'Severe' : 'Incomplete';

      color = tableValue === 'Severe' ? '#be2020' : tableValue === 'Moderate' ? '#cbbc2d' : '';

      return (
        <TableCell style={{ fontWeight: newSession, color }} align="left">
          {tableValue}
        </TableCell>
      );

    case 'gad_4c':
      tableValue = value.gad_4c === 0 ? 'None' :
        value.gad_4c === 1 ? 'Mild' :
          value.gad_4c === 2 ? 'Moderate' :
            value.gad_4c === 3 ? 'Severe' : 'Incomplete';

      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {tableValue}
        </TableCell>
      );

    case 'details' || 'actions':
      // In case of patients, userId exists but it is called uid
      editedFieldValue = value.uid;
      if (value.userId) {
        // In case of recordings and sessions, userId exists
        editedFieldValue = value.userId;
      }
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <div style={{ width: '100%', display: 'flex' }}>
            <MdInsertChartOutlined
              style={{ marginRight: 20, cursor: 'pointer', width: 20 }}
              title="Patient Details"
              size={25}
              onClick={() =>
                props.history.push({
                  pathname: `/patient/${editedFieldValue}`,
                  search: '?page=1',
                  state: {
                    from: props.history.location.pathname,
                    search: props.history.location.search
                  }
                })
              }
            />
            <GrDocumentPdf
              style={{ marginRight: 20, cursor: 'pointer', width: 20 }}
              title="Download PDF"
              size={25}
              onClick={async () => {
                let blob = null;

                blob = await pdf(
                  <AssessmentDoc
                    data={[value]}
                    selected={[value].map(n => n.uid)}
                    user={value}
                    config={{ ...config, has4WayClassification }}
                  />
                ).toBlob();

                saveAs(
                  blob,
                  `Ellipsis_${value.name ?? 'no_name_'}${value.lastName ?? 'no_last_name'}_${formatDate(value.startTimeStamp?.value, 'DD_MM_YYYY_hh_mm_ss_A_z', partnerTimezone)}.pdf`
                );
              }}
            />

            <GrDocumentCsv
              disabled={props.showSpinner}
              title="Download CSV"
              size={25}
              style={{ cursor: 'pointer', width: 20 }}
              onClick={() =>
                props.downloadCsv({
                  data: [value],
                  fileName: `Ellipsis_${value.name ?? 'no_name_'}${value.lastName ?? 'no_last_name'}_${formatDate(value.startTimeStamp?.value, 'DD_MM_YYYY_hh_mm_ss_A_z', partnerTimezone)}.csv`
                })
              }
            />
          </div>
        </TableCell>
      );

    case 'patientsActions':
      // In case of patients, userId exists but it is called uid
      editedFieldValue = value.uid;
      if (value.userId) {
        // In case of recordings and sessions, userId exists
        editedFieldValue = value.userId;
      }
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <div style={{ width: '100%', display: 'flex' }}>
            <MdCreate
              style={{ marginRight: 20, cursor: 'pointer' }}
              title="Edit"
              size={25}
              onClick={() => props.editPatient(value)}
            />
            {/* <Delete
              style={{ marginRight: 20, cursor: 'pointer' }}
              title={`delete: ${value.uid}`}
              onClick={() => props.deletePatient(value)}
            /> */}
            <MdInsertChartOutlined
              style={{ marginRight: 20, cursor: 'pointer' }}
              title="Patient Details"
              size={25}
              onClick={() =>
                props.history.push({
                  pathname: `/patient/${editedFieldValue}`,
                  search: '?page=3',
                  state: {
                    from: props.history.location.pathname,
                    search: props.history.location.search
                  }
                })
              }
            />
            {value?.contactPhone && (
              <MdTextsms
                style={{ marginRight: 20, cursor: 'pointer' }}
                title="Send Text Message"
                size={25}
                onClick={() => props.sendMessage(value)}
              />
            )}
            {(value?.email || value?.contactEmail) && (
              <MdEmail
                style={{ marginRight: 20, cursor: 'pointer' }}
                title="Send email"
                size={25}
                onClick={() => props.sendEmail(value)}
              />
            )}
          </div>
        </TableCell>
      );

    case 'reviewOfSystems':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <div style={{ width: '100%', display: 'flex' }}>
            <MdList
              style={{ marginRight: 20, cursor: 'pointer' }}
              title={columnsName && columnsName[id] ? columnsName[id] : id}
              onClick={() => props.openCellDialog(value, id)}
            />
          </div>
        </TableCell>
      );

    case 'surveyScores':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {surveyScores.map(s => {
            return s.survey ?
              (s.status && s.score && s.status === 'INCOMPLETE') ?
                `${surveyScores.length === 1 ? '' : `${s.survey}:`} Incomplete\n` : s.status && !s.score && s.status === 'INCOMPLETE' ?
                  `${surveyScores.length === 1 ? '' : `${s.survey}:`} n/a\n` : `${surveyScores.length === 1 ? '' : `${s.survey}:`} ${s.score}\n` : ''
          })}
        </TableCell>
      );

    case 'mshServices':

      tableValue = [];
      for (let i = 0; i < value.custom.length; i += 1) {
        if (value.custom[i].survey === 'MSHPQ') {
          for (let j = 0; j < value.custom[i].scores.length; j += 1) {
            if (value.custom[i].scores[j] === 1) {
              tableValue.push(MSHanswers[j]);
            }
          }
          break;
        }
      }

      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {tableValue.join(", ")}
        </TableCell>
      );

    case 'audioFile':
      return (
        <TableCell align="left">
          {value.audioFile !== null &&
            typeof value.audioFile !== 'undefined' ? (
              <Audio
                style={{ width: '50' }}
                recording={value}
                savePlayLogs={() =>
                props.saveLogs({
                  type: 'play/recording',
                  success: 'Success',
                  text: `User played audio for session: ${value.sessionId} and question: ${value.question}`
                })
              }
                savePauseLogs={() =>
                props.saveLogs({
                  type: 'pause/recording',
                  success: 'Success',
                  text: `User paused audio for session: ${value.sessionId} and question: ${value.question}`
                })
              }
              />
          ) : (
            ''
          )}
        </TableCell>
      );

    default:
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value[id]}
        </TableCell>
      );
  }
}

export default function getTableField(columns, session, props, newSession) {
  const columnsOrder = [];
  for (let i = 0; i < columns.length; i += 1) {
    columnsOrder.push(tableField(columns[i].id, session, props, newSession));
  }

  return columnsOrder;
}
