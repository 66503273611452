// import fetch from 'cross-fetch';
import firebase from '../../../../config/firebase';
import notifier from '../../../common/utils/notifier';

import {
  requestLogin,
  receiveUserInfo,
  receivePortalConfigInfo,
  selectPartnersToShow,
  requestSaveConfiguration,
  saveConfigurationCompleted,
  requestSaveFilters,
  saveFiltersCompleted,
  setError,
  loginError,
  setLoginState,
  logout
} from './actions';

import { homeActions } from '../../../home/redux';

const saveLogs = data => {
  return dispatch => {
    const saveLogsFunction = firebase
      .functions()
      .httpsCallable('dashboardFunctions-saveLogs');
    saveLogsFunction(data)
      .then(function (result) {
        if (result.data !== 'success') {
          console.log(
            `Something wrong happened while saving logs.`
          );
        }
      })
      .catch(error => {
        console.error('Error saving logs: ', error.message);
      });
  };
};

const saveConfiguration = data => {
  return dispatch => {
    dispatch(requestSaveConfiguration());
    // ( { merge: true } --> Existing data in the db are not be deleted )

    const { email } = data;
    delete data.email;

    firebase
      .firestore()
      .collection('admins')
      .doc(email)
      .set(data, { merge: true })
      .then(() => {
        console.log('User info successfully saved!');

        const snackbar = {
          variant: 'success',
          message: `New Configuration Saved. User: ${email}`
        };

        dispatch(saveConfigurationCompleted(data, snackbar));
      })
      .catch(error => {
        console.error('Error writing user info in document: ', error.message);
        const snackbar = {
          variant: 'error',
          message: error.message
        };
        dispatch(setError(snackbar));
      });
  };
};

const saveLastLoginInfo = data => {
  const { email } = data;
  delete data.email;

  firebase
    .firestore()
    .collection('admins')
    .doc(email)
    .set(data, { merge: true })
    .then(() => {
      console.log('User last login in portal successfully saved!');
    })
    .catch(error => {
      console.error(
        'Error writing last login user info in document: ',
        error.message
      );
    });
};

const logUserOut = () => {
  return dispatch => {
    saveLogs({
      type: 'logout/firebase',
      success: 'Success',
      text: 'Logout'
    });

    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('Log user out');
        sessionStorage.clear();
        localStorage.clear();

        dispatch(logout());
      })
      .catch(error => {
        console.log(error.message);
        const snackbar = {
          variant: 'error',
          message: error.message
        };
        dispatch(setError(snackbar));
      });
  };
};

const getPortalConfig = partnerList => {
  return async dispatch => {
    /**
     * Get OVERALL dashboard configurations from the firestore database.
     * Be sure we get the configurations for the specific admin's partners
     */
    /**
     * - If the admin has only one partner, get the configuration of that partner only
     * - If the admin has more than one partner, provide all the configurations
     */
    const configurations = {};

    await firebase
      .firestore()
      .collection('dashboard_config')
      .get()
      .then(snapshotConfig => {
        snapshotConfig.forEach(config => {
          if (partnerList.indexOf(config.id) > -1) {
            const nextConfig = config.data();
            configurations[config.id] = nextConfig;
          }

          if (config.id === 'options' || config.id === 'user_restriction') {
            const nextConfig = config.data();
            configurations[config.id] = nextConfig;
          }
        });
      })
      .catch(error => {
        console.log('Error getting dashboard config:', error);
        notifier.error('Sorry, we were unable to get the portal configurations for your user. Please, try again later.')

        dispatch(loginError());
        logUserOut();
      });

    await firebase
      .firestore()
      .collection('partners')
      .get()
      .then(partners => {

        partners.forEach(partner => {
          if (partnerList.indexOf(partner.id) > -1) {
            const partnerData = partner.data();
            configurations.partnerTimezone = partnerData.timezone || "America/Los_Angeles";
            if (partnerData.phq9Config && partnerData.phq9Config.phq9Alert) {
              configurations.phq9Config = partner.data().phq9Config;
            }
            if (partnerData.notificationConfig && (partnerData.notificationConfig.thresholdAlert || partnerData.notificationConfig.upwardAlert)) {
              configurations.notificationConfig = partner.data().notificationConfig;
            }
            if (partnerData.panelConfig) {
              configurations.panelConfig = partnerData.panelConfig;
            }
          }
        });

        /**
         * Update the config setting and the partners that will be visualized in the portal
         */
        dispatch(receivePortalConfigInfo(configurations, partnerList));

      })
      .catch(error => {
        console.log('Error getting partner config:', error);
        notifier.error('Sorry, we were unable to get the portal configurations for your user. Please, try again later.')

        dispatch(loginError());
        logUserOut();
      });
  };
};

const getUserJsonWithToken = (token) => {
  let portalConfig;

  return dispatch => {
    dispatch(requestLogin());
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(result => {
        const { user } = result;
        /**
         * Check if user is admin
         * yes -> get user info and partners the user has permission to see
         * no -> Logout user and send error
         */
        firebase
          .firestore()
          .collection('admins')
          .doc(user.email.toLowerCase())
          .get()
          .then(admin => {
            saveLogs({
              email: user.email,
              type: 'login/firebase',
              success: 'Success',
              text: 'Login'
            });

            /**
             * 1- Get the current admin dashboard configuration
             * 2- partners represents the list of partners assigned to this particular user
             * 3- If list is empty or not present, return error
             */
            portalConfig = admin.data();
            if (!portalConfig.partners || portalConfig.partners.length < 1) {
              console.log('Error: the user has no partner assigned');
              notifier.error('Error: the user has no partner assigned. Please, ask your administrator')

              saveLogs({
                email: user.email,
                type: 'login/firebase',
                success: 'Error',
                text: 'Error while logging in'
              });

              dispatch(loginError());
              logUserOut();

              return;
            }

            /**
             * HOME REDUX ACTIONS
             * All the portal requests for data must initially be filtered by the user's email.
             * This action sets the initial filter in the home reducer.
             */
            // Add portalConfig.email in call to set followed by to current loggedIn user
            dispatch(homeActions.setFollowedby());

            /**
             * Save the current date of login
             * date of login is in --> user.metadata.a (milliseconds)
             */
            saveLastLoginInfo({
              email: user.email.toLowerCase(),
              lastLogin: user.metadata.b
            });

            /**
             * 1- save userPartnerList in portal config (Overall list of partners for the logged in user)
             * 2.1- If the partners list has more than 1 partner, dispatch action to set flag "multi_partner" to true
             * 2.2- If the partners list has 1 partner, dispatch getPortalConfig directly.
             */
            portalConfig.userPartnerList = admin.data().partners;
            dispatch(receiveUserInfo(user, portalConfig));

            if (portalConfig.partners.length === 1) {
              dispatch(getPortalConfig(portalConfig.partners));
              return;
            }

            dispatch(selectPartnersToShow());
          })
          .catch(error => {
            console.log('Error getting admin user:', error);
            notifier.error('Sorry, we were unable to get the user information. Please try again later.')

            saveLogs({
              email: user.email,
              type: 'login/firebase',
              success: 'Error',
              text: 'Error while logging in'
            });

            dispatch(loginError());
            logUserOut();
          });
      })
      .catch(error => {
        console.log(error.message);
        notifier.error(error.message)

        dispatch(loginError());
      });
  };
};

const getUserJson = (email, password) => {
  let portalConfig;

  return dispatch => {
    dispatch(requestLogin());
    firebase
      .auth()
      .signInWithEmailAndPassword(email.toLowerCase(), password)
      .then(result => {
        const { user } = result;
        /**
         * Check if user is admin
         * yes -> get user info and partners the user has permission to see
         * no -> Logout user and send error
         */
        firebase
          .firestore()
          .collection('admins')
          .doc(user.email.toLowerCase())
          .get()
          .then(admin => {
            saveLogs({
              email: user.email,
              type: 'login/firebase',
              success: 'Success',
              text: 'Login'
            });

            /**
             * 1- Get the current admin dashboard configuration
             * 2- partners represents the list of partners assigned to this particular user
             * 3- If list is empty or not present, return error
             */
            portalConfig = admin.data();
            if (!portalConfig.partners || portalConfig.partners.length < 1) {
              console.log('Error: the user has no partner assigned');
              notifier.error('Error: the user has no partner assigned. Please, ask your administrator')

              saveLogs({
                email: user.email,
                type: 'login/firebase',
                success: 'Error',
                text: 'Error while logging in'
              });

              dispatch(loginError());
              logUserOut();

              return;
            }

            /**
             * HOME REDUX ACTIONS
             * All the portal requests for data must initially be filtered by the user's email.
             * This action sets the initial filter in the home reducer.
             */
            // Add portalConfig.email in call to set followed by to current loggedIn user
            dispatch(homeActions.setFollowedby());

            /**
             * Save the current date of login
             * date of login is in --> user.metadata.a (milliseconds)
             */
            saveLastLoginInfo({
              email: user.email.toLowerCase(),
              lastLogin: user.metadata.b
            });

            /**
             * 1- save userPartnerList in portal config (Overall list of partners for the logged in user)
             * 2.1- If the partners list has more than 1 partner, dispatch action to set flag "multi_partner" to true
             * 2.2- If the partners list has 1 partner, dispatch getPortalConfig directly.
             */
            portalConfig.userPartnerList = admin.data().partners;
            dispatch(receiveUserInfo(user, portalConfig));

            if (portalConfig.partners.length === 1) {
              dispatch(getPortalConfig(portalConfig.partners));
              return;
            }

            dispatch(selectPartnersToShow());
          })
          .catch(error => {
            console.log('Error getting admin user:', error);
            notifier.error('Sorry, we were unable to get the user information. Please try again later.')

            saveLogs({
              email: user.email,
              type: 'login/firebase',
              success: 'Error',
              text: 'Error while logging in'
            });

            dispatch(loginError());
            logUserOut();
          });
      })
      .catch(error => {
        console.log(error.message);
        notifier.error(error.message)

        dispatch(loginError());
      });
  };
};

const saveFilter = data => {
  return dispatch => {
    dispatch(requestSaveFilters());
    dispatch(saveFiltersCompleted(data));
  };
};

export default {
  getUserJson,
  getUserJsonWithToken,
  getPortalConfig,
  setLoginState,
  saveFilter,
  saveConfiguration,
  logUserOut,
  saveLogs
};
