import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, Typography } from '@material-ui/core';

import { STREAM_ENDPOINT } from 'config/stream';

import EHStreamViz from './EHStreamViz';
import EHStreamData from './EHStreamData';

const SingleStream = props => {
  const { token, sessionId, sessionSecret, finished } = props;

  const [expanded, setExpanded] = useState(false);
  const [started, setStarted] = useState(false);
  const [recording, setRecording] = useState(false);
  const [stopped, setStopped] = useState(false);
  const [result, setResult] = useState({
    transcript: '',
    wordCount: 0,
    score: 0
  });
  const [runKey, setRunKey] = useState(Date.now());

  const start = () => {
    console.log('eh_app: starting');
    setStarted(true);
    setRecording(true);
    setStopped(false);
  };

  const stop = () => {
    console.log('eh_app: stopping');
    setStarted(false);
    setRecording(false);
    setStopped(true);
    setRunKey(Date.now());
  };

  const handleClick = () => {
    setExpanded(!expanded);
    setTimeout(() => {
      if (!finished) start()
    }, 100);
  };

  useEffect(() => {
    if (started && finished) stop();
  }, [finished, started]);

  // eslint-disable-next-line no-shadow
  const onStreamResponse = result => {
    setResult(result);
  };

  return (
    <>
      <Card
        onClick={handleClick}
        style={{
          padding: '16px',
          fontSize: '16px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <span>Session: {sessionId}</span>
        {finished && <span>Recently finished</span>}
      </Card>
      {expanded && (
        <div style={{ marginTop: '10px' }}>
          <Box>
            <Box p={3} width={1 / 4} color="black" bg="primary">
              <Button disabled={started} mr={2} onClick={start}>
                Start
              </Button>
            </Box>
            <Box p={3} width={1 / 4} color="black" bg="primary">
              <Button
                disabled={!started}
                variant="secondary"
                color="black"
                mr={2}
                onClick={stop}
              >
                Stop
              </Button>
            </Box>
          </Box>
          <Card style={{ marginBottom: 16, padding: 10 }}>
            <Typography style={{ textAlign: 'center' }}>
              React-Viz Widget
            </Typography>

            <EHStreamViz
              sessionId={sessionId}
              paused={!recording}
              numVars={3}
              data={[
                { name: 'word count', value: result.wordCount },
                {
                  name: 'anxiety score',
                  value: result.measures?.anxietyScore || 0
                },
                {
                  name: 'depression score',
                  value: result.measures?.depressionScore || 0
                }
              ]}
            />
          </Card>
          <Card style={{ padding: 10 }}>
            <Typography style={{ textAlign: 'center' }}>
              React-Data Widget
            </Typography>
            <Box
              style={{
                padding: '.5rem',
                margin: '2rem',
                marginTop: '0.5rem'
              }}
            >
              <EHStreamData
                key={`EHSD_${  runKey}`}
                url={STREAM_ENDPOINT}
                onStreamResponse={onStreamResponse}
                subscriptions={{
                  transcript: true,
                  wordCount: true,
                  measures: [
                    'anxietyScore',
                    'anxietyBinary',
                    'anxietyLevel',
                    'depressionScore',
                    'depressionBinary',
                    'depressionLevel'
                  ]
                }}
                start={started}
                pause={started && !stopped && !recording}
                stop={stopped}
                onStart={() => console.log('EHStreamData: starting...')}
                onPause={() => console.log('EHStreamData: pausing...')}
                onResume={() => console.log('EHStreamData: resuming...')}
                onStop={() => console.log('EHStreamData: stopping...')}
                onError={err => {
                  console.log(err);
                }}
                token={token}
                sessionId={sessionId}
                sessionSecret={sessionSecret}
              />
            </Box>
          </Card>
        </div>
      )}
    </>
  );
};

SingleStream.propTypes = {
  token: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  sessionSecret: PropTypes.string.isRequired,
  finished: PropTypes.bool
};

export default SingleStream;
