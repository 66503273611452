// import fetch from 'cross-fetch';
import firebase from '../../../config/firebase';
import { requestPatientsJson, receivePatientsJson } from './actions';

require('firebase/functions');

const saveLogs = data => {
  const saveLogsFunction = firebase
    .functions()
    .httpsCallable('dashboardFunctions-saveLogs');
  saveLogsFunction(data)
    .then(result => {
      if (result.data !== 'success') {
        console.log(
          `Something wrong happened while saving logs.`
        );
      }
    })
    .catch(error => {
      console.error('Error saving logs: ', error.message);
    });
};

const getPatientsJson = (
  partnerList,
  sort,
  sortOrder,
  limit,
  offset,
  filters
) => {
  return dispatch => {
    dispatch(requestPatientsJson());

    const sessionList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_patientsList');
    sessionList({
      partnerList,
      sort,
      sortOrder,
      filters,
    }).then(result => {
      const { patientsCount, query } = JSON.parse(result.data);
      const patients = JSON.parse(result.data).patientsList;

      dispatch(
        receivePatientsJson(
          patientsCount,
          patients,
          sort,
          sortOrder,
          limit,
          offset,
          filters
        )
      );

      saveLogs({
        type: 'query/bigQuery',
        success: `Successfully returned patients list: ${patientsCount} patients`,
        text: query,
      });
    });
  };
};

export default { getPatientsJson };