import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './TextInfoContent.styles';

import { formatDate } from '../../../components/Date';

const useDefaultStyles = makeStyles(styles, { name: 'TextInfoContent' });

const TriggerContent = ({
    useStyles,
    config,
    trigger,
    patient,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const css = useStyles(props);

    const patientInfo = (
      <span>
        <b>MRN:</b> {`${patient.mrn},`} {`${trigger.value.length} thresholds exceeded`}
      </span>
    );

    const nameInfo = (
      <span>
        {`${patient.name ?? ''} ${patient.lastName ?? ''}, `}
      </span>
    );

    const displayInfo = config.phq9PortalShowName ? (
      <span>
        {nameInfo}
        {patientInfo}
      </span>
    ) : (
      <span>
        {patientInfo}
      </span>
    );

    return (
      <div>
        <div className={css.userTriggerLine}>
          <div onClick={() => setOpen(!open)} className={css.expandButton}>
            {open ? <AiOutlineMinus /> : <AiOutlinePlus />}
          </div>
          <Typography style={{ fontSize: '12px' }}>
            {displayInfo}
          </Typography>
        </div>
        {open ? (
          <div>
            {trigger.value.map(val => {
                        const parsed = JSON.parse(val);
                        return (
                          <div className={css.trigger}>
                            <Typography style={{ fontSize: '12px' }}>
                              {`Session Date & Time: `}{formatDate(parsed.time, 'L LT z', config.timezone)}
                            </Typography>
                            <Typography style={{ fontSize: '12px' }}>
                              {`Description: `}{parsed.name !== 'UPWARD_threshold' ? `The defined ${parsed.name.substring(0, 3)} threshold has been exceeded.` : `The defined Upward limit has been exceeded compared to the previous session.`}
                            </Typography>
                            <Typography style={{ fontSize: '12px' }}>
                              {`Threshold Value: `}{parsed.trigger ?? ''}
                            </Typography>
                            <Typography style={{ fontSize: '12px' }}>
                              {`Current Value: `}{parsed.value ?? ''}
                            </Typography>
                          </div>
)
                    })}
          </div>
              ) : <></>}
      </div>
    );
};

TriggerContent.propTypes = {
    patient: PropTypes.object,
    trigger: PropTypes.object,
    useStyles: PropTypes.object
};
TriggerContent.defaultProps = {
    patient: {},
    trigger: {},
    useStyles: useDefaultStyles,
};

export default TriggerContent;