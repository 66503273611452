import firebase from '../../../config/firebase';
import {
  requestCreateNewUser,
  receiveNewUser,
  editPortalUser,
  receiveEditPortalUser,
  requestUserList,
  receiveUserList,
  requestSendInvite,
  inviteSent,
  requestEditDisable,
  DisableEdited,
} from './actions';

require('firebase/functions');

const saveLogs = data => {
  const saveLogsFunction = firebase
    .functions()
    .httpsCallable('dashboardFunctions-saveLogs');
  saveLogsFunction(data)
    .then(result => {
      if (result.data !== 'success') {
        console.log(
          `Something wrong happened while saving logs.`
        );
      }
    })
    .catch(error => {
      console.error('Error saving logs: ', error.message);
    });
};

const sendInviteEmail = (partners, email, from) => {
  return dispatch => {
    if (from !== 'createNewUser') {
      /**
       * The function can be called in two ways.
       * 1) Directly from the UI
       * 2) After that the createNewUser has been called
       * In the first case, we have to dispatch different actions
       */
      dispatch(requestSendInvite());
    }

    const sendInvite = firebase
      .functions()
      .httpsCallable('dashboardFunctions-sendKittDashboardInvite');
    sendInvite({ partners, newUserEmail: email })
      .then(result => {
        const { success, message } = result.data;

        const snackbar = {
          message: success ? `An email has been sent to the User ${email} with instructions on how to access the Ellipsis Health dashboard.` : message,
          variant: success ? 'success' : 'error'
        };

        if (from === 'createNewUser') {
          dispatch(receiveNewUser(snackbar));
        } else {
          dispatch(inviteSent(snackbar));

          saveLogs({
            type: 'newPortalUser/firestore',
            success: snackbar.variant,
            text: snackbar.message,
          });
        }
      })
      .catch(error => {
        console.log('Error sending the dashboard invite email:', error);

        const snackbar = {
          variant: 'error',
          message: 'Sorry, something went wrong. Try again later.'
        };

        if (from === 'createNewUser') {
          dispatch(receiveNewUser(snackbar));
        } else {
          dispatch(inviteSent(snackbar));
        }
      });
  };
};

const createNewUser = data => {
  return dispatch => {
    dispatch(requestCreateNewUser());

    const newUser = firebase
      .functions()
      .httpsCallable('dashboardFunctions-createNewDashboardUser');
    newUser(data)
      .then(result => {
        const responseData = result.data;
        const { message } = responseData;
        const variant = responseData.success ? 'success' : 'error';

        const snackbar = {
          variant,
          message
        };

        /**
         * If the user has been created successfully, send the dashboard invite email
         */
        if (responseData.success) {
          console.log(responseData);
          dispatch(sendInviteEmail(data.partners, data.email, 'createNewUser'));
        }

        dispatch(receiveNewUser(snackbar));

      })
      .catch(error => {
        console.error(
          'Error calling the createNewDashboardUser from frontend: ',
          error.message
        );
        const snackbar = {
          variant: 'error',
          message: 'Sorry, something went wrong. Try again later.'
        };
        dispatch(receiveNewUser(snackbar));
      });
  };
};

const editExistingPortalUser = data => {
  return dispatch => {
    dispatch(editPortalUser());

    const editUser = firebase
      .functions()
      .httpsCallable('dashboardFunctions-editDashboardUser');
    editUser(data)
      .then(result => {
        const results = result.data;
        const { message, success } = results;
        const variant = success ? 'success' : 'error';

        const snackbar = {
          variant,
          message
        };

        dispatch(receiveEditPortalUser(snackbar));

      })
      .catch(error => {
        console.error(
          'Error calling the editDashboardUser from frontend: ',
          error.message
        );
        const snackbar = {
          variant: 'error',
          message: 'Sorry, something went wrong. Try again later.'
        };
        dispatch(receiveEditPortalUser(snackbar));
      });
  };
};

const getUserList = data => {
  return dispatch => {
    dispatch(requestUserList());

    const list = firebase
      .functions()
      .httpsCallable('dashboardFunctions-dashboardUsers');
    list(data)
      .then(result => {
        const jsonResult = JSON.parse(result.data);
        const { users, success, message, phq9AlertActive, crisisMembersMap } = jsonResult;
        const userList = users;
        const variant = success ? '' : 'error';

        const snackbar = {
          variant,
          message
        };

        dispatch(receiveUserList(userList, phq9AlertActive, crisisMembersMap, snackbar));
      })
      .catch(error => {
        console.error(
          'Error calling the dashboardUsers function from frontend: ',
          error.message
        );
        const snackbar = {
          variant: 'error',
          message:
            'Sorry, something went wrong loading dashboard users. Please try again later.'
        };
        dispatch(receiveUserList([], false, {}, snackbar));
      });
  };
};

const changeUserStatus = data => {
  return dispatch => {
    dispatch(requestEditDisable());

    const changeStatus = firebase
      .functions()
      .httpsCallable('dashboardFunctions-changeUserStatus');
    changeStatus(data)
      .then(result => {
        const snackbar = {
          variant: JSON.parse(result.data).variant,
          message: JSON.parse(result.data).message
        };

        dispatch(DisableEdited(snackbar));

        saveLogs({
          type: 'changeUserStatus/firestore',
          success: snackbar.variant,
          text: `Changed status for user ${data.uid ?? ''}. ${snackbar.message}`,
        });
      })
      .catch(error => {
        console.error(
          'Error calling the dashboardUsers function from frontend: ',
          error.message
        );

        const snackbar = {
          variant: 'error',
          message:
            'Sorry, something went wrong processing your request. Please try again later.'
        };
        dispatch(DisableEdited(snackbar));
      });
  };
};

const deleteDashboardUser = data => {
  return dispatch => {
    dispatch(requestEditDisable());

    firebase
      .firestore()
      .collection('admins')
      .doc(data.email)
      .delete()
      .then(() => {
        saveLogs({
          type: 'delete/firestore',
          success: 'Success',
          text: `Deleted user ${data.email}`
        });

        const changeUserStatusAfterDelete = firebase
          .functions()
          .httpsCallable('dashboardFunctions-changeUserStatus');
        changeUserStatusAfterDelete(data)
          .then(result => {
            let snackbar = {
              variant: JSON.parse(result.data).variant,
              message: `The user ${data.email} has been correctly deleted.`
            };
            if (JSON.parse(result.data).variant === 'error') {
              snackbar = {
                variant: JSON.parse(result.data).variant,
                message:
                  'The user has been deleted correctly. However, we encountered a problem disabling the user. Please, proceed directly from the database.'
              };
            }

            dispatch(DisableEdited(snackbar));
          })
          .catch(error => {
            console.error(
              'Error calling the changeUserStatus function from frontend: ',
              error.message
            );

            const snackbar = {
              variant: 'error',
              message:
                'Sorry, something went wrong processing your request. Please try again later.'
            };
            dispatch(DisableEdited(snackbar));
          });
      })
      .catch(error => {
        console.error('Error deleting the user: ', error.message);

        saveLogs({
          type: 'delete/firestore',
          success: 'Error',
          text: `Error deleting user ${data.email}`
        });

        const snackbar = {
          variant: 'error',
          message:
            'Sorry, something went wrong deleting the user. Please try again later.'
        };

        dispatch(DisableEdited(snackbar));
      });
  };
};

export default {
  createNewUser,
  editExistingPortalUser,
  sendInviteEmail,
  getUserList,
  changeUserStatus,
  deleteDashboardUser,
};