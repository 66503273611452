import firebase from 'firebase';

// Initialize Firebase
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// export const auth = firebase.auth();
export default firebase;
export { config };
