export default ({ palette, breakpoints }) => {
    return {
      root: {
        minWidth: 256,
      },
      header: {
        width: '100%',
        minHeight: 60,
        marginLeft: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [breakpoints.down('600')]: {
          flexDirection: 'column',
          padding: '5px'
        },
      },
      shape: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        marginRight: 10
      },
      divider: {
        backgroundColor: palette.grey[200]
      },
      extra: {
        fontSize: 14,
        color: palette.grey[500],
        letterSpacing: '1px',
        marginRight: 20,
        [breakpoints.down('md')]: {
          fontSize: 12,
          marginLeft: 10
        },
      },
      alertTitle: {
        fontSize: 14,
        color: palette.grey[600],
        letterSpacing: '1px',
        marginRight: 10,
        [breakpoints.down('md')]: {
          fontSize: 12,
          marginLeft: 10
        },
      },
      alert: {
        fontSize: 14,
        color: '#2986CC',
        letterSpacing: '1px',
        marginRight: 10,
        [breakpoints.down('md')]: {
          fontSize: 12,
          marginLeft: 10
        },
      },
      phqalert: {
        fontSize: 14,
        color: '#e09100',
        letterSpacing: '1px',
        marginRight: 10,
        [breakpoints.down('md')]: {
          fontSize: 12,
          marginLeft: 10
        },
      },
      confirmSection: {
        display: 'flex',
        flexDirection: 'column',
        [breakpoints.down('600')]: {
          flexDirection: 'row'
        },
      }
    };
  };