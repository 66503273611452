import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './TextInfoContent.styles';

import { DateComponent } from '../../../components/Date';

const useDefaultStyles = makeStyles(styles, { name: 'TextInfoContent' });

const TextInfoContent = ({
  useStyles,
  data,
  config,
  bodyProps,
  ...props
}) => {
  const { timezone } = config
  const css = useStyles(props);
  return (
    <>
      <div className={css.infoTitle}>
        <Typography style={{ fontWeight: 500, marginRight: 10 }}>
          Alert Type:
        </Typography>
        <Typography style={{ fontWeight: 500 }}>
          REAL-TIME ALERT
        </Typography>
      </div>
      <div className={css.infoTitle}>
        <Typography style={{ fontWeight: 500, marginRight: 10 }}>
          Alert Date & Time:
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          <DateComponent date={data.createdAtUtc?.value} timezone={timezone} />
        </Typography>
      </div>
      <div className={css.infoLine}>
        <Typography style={{ fontWeight: 500 }}>
          MRN:
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          &nbsp; {data.mrn || "n/a"}
        </Typography>
      </div>
      {config.phq9PortalShowName && (
        <>
          <div className={css.infoLine}>
            <Typography style={{ fontWeight: 500 }}>
              Last Name:
            </Typography>
            <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
              &nbsp; {data.lastName ? `${data.lastName}` : 'n/a'}
            </Typography>
          </div>
          <div className={css.infoLine}>
            <Typography style={{ fontWeight: 500 }}>
              First Name:
            </Typography>
            <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
              &nbsp; {`${data.name}` || "n/a"}
            </Typography>
          </div>
        </>
      )}
      <div className={data.email && isMobile ? css.infoLineColumn : css.infoLine}>
        <Typography style={{ fontWeight: 500 }}>
          Email: &nbsp;
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          {data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : "n/a"}
        </Typography>
      </div>
      <div className={css.infoLine}>
        <Typography style={{ fontWeight: 500 }}>
          Total PHQ-9:
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          &nbsp; {data.phqTotal ? data.phqTotal : "n/a"}
        </Typography>
      </div>
      <div className={css.infoLine}>
        <Typography style={{ fontWeight: 500 }}>
          PHQ-9 Q9:
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          &nbsp; {data.phqScores && data.phqScores[8] ? data.phqScores[8] : "n/a"}
        </Typography>
      </div>
      <div className={css.infoLine}>
        <Typography style={{ fontWeight: 500, marginRight: 10 }}>
          Session Date:
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          <DateComponent date={data.startTimeStamp?.value} timezone={timezone} />
        </Typography>
      </div>
      <div style={{ height: 20 }} />
      <div className={css.infoLine}>
        <Typography>
          Phone:
        </Typography>
        <Typography {...bodyProps} className={cx(css.body, bodyProps.className)}>
          &nbsp; {data.contactPhone ? <a href={`tel:${data.contactPhone}`}>{data.contactPhone}</a> : "n/a"}
        </Typography>
      </div>
    </>
  );
};

TextInfoContent.propTypes = {
  data: PropTypes.object,
  bodyProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  config: PropTypes.object,
  useStyles: PropTypes.func,
};
TextInfoContent.defaultProps = {
  data: {},
  bodyProps: {},
  useStyles: useDefaultStyles,
};

export default TextInfoContent;