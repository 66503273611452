import React, { useState, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import firebase from '../../../config/firebase';

const functions = firebase.functions(); // Import Firebase functions

async function getRecordingUrl(data) {
  try {
    const getUrl = functions.httpsCallable('dashboardFunctions-getRecordingUrl');
    const response = await getUrl(data);

    return response.data;
  } catch (error) {
    console.error('Error concatenating audio:', error);
    throw error;
  }
}

function Audio(props) {
  const [url, setURL] = useState(null);
  const [loading, setLoading] = useState(true);

  const { recordings, style, savePlayLogs, savePauseLogs, partner } = props;

  const recordingNames = recordings
    ? recordings
      .filter((rec) => rec.audioFile) // Filter out recordings without audioFile
      .map((rec) => rec.audioFile.split('com/').pop())
    : [];

  const loadAudio = async () => {
    try {
      const audioData = await getRecordingUrl({
        paths: recordingNames,
        partner
      });

      setURL(audioData);
      setLoading(false);
    } catch (err) {
      console.error('Error concatenating audio:', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Load the first audio when the component mounts
    loadAudio();
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LinearProgress style={{ align: 'center', marginBottom: 15, width: 10 }} />
        </div>
      ) : (
        <audio
          type="audio/wav"
          style={style}
          src={url}
          controls
          controlsList="nodownload"
          onPlay={savePlayLogs}
          onPause={savePauseLogs}
        >
          <track kind="captions" />
          Your browser does not support the audio element
        </audio>
      )}
    </div>
  );
}

export default Audio;
