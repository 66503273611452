import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import some from 'lodash/some';
import AssessmentChart from '../../../common/chart/AssessmentChart';
import FourWayAssessmentChart from '../../../common/chart/FourWayAssessmentChart';

import Captions from '../../../common/chart/Captions';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    marginTop: 20,
    marginBottom: 20,
    marginRight: 40,
    marginLeft: 40,
    borderRadius: 2,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#f5f9ff',
    alignItems: 'center',
    [theme.breakpoints.down(850)]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  leftTop: {
    width: '10%',
    paddingTop: 60,
    minWidth: 100
  },
  left: {
    width: '10%',
    paddingTop: 40,
    minWidth: 100
  },
  right: {
    flex: 1,
    overflowX: 'auto'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  rightTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 900
  },
  caption: {
    color: '#715FA6'
  }
});

const getDepressionValue = (session, showEHResults, has4WayClassification, hasPhq9) => {
  if (!showEHResults) { return null }

  if (has4WayClassification) {
    /**
     * phq_4c could present 4 values: 0, 1, 2, 3
     * 0 --> Values between 0 and 5
     * 1 --> Values between 5 and 10
     * 2 --> Values between 10 and 15
     * 3 --> Values above 15
     */
    if (session.phq_4c === null ) { return null }

    return session.phq_4c === null || session.phq_4c < 0 ? null : session.phq_4c === 0 ? 2.5 :
      session.phq_4c === 1 ? 7.5 :
        session.phq_4c === 2 ? 12.5 :
          (session.phq_4c === 3 && hasPhq9) ? 21 : 19.5
  }

  return session.phq_r < 0 ? 0 : session.phq_r > 24 ? 24 : session.phq_r

}
const getAnxietyValue = (session, showEHResults, has4WayClassification) => {
  if (!showEHResults) { return null }

  if (has4WayClassification) {
    /**
     * gad_4c could present 4 values: 0, 1, 2, 3
     * 0 --> Values between 0 and 5
     * 1 --> Values between 5 and 10
     * 2 --> Values between 10 and 15
     * 3 --> Values above 15
     */
    if (session.gad_4c === null) { return null }

    return session.gad_4c === null || session.gad_4c < 0 ? null : session.gad_4c === 0 ? 2.5 :
      session.gad_4c === 1 ? 7.5 :
        session.gad_4c === 2 ? 12.5 : 18
  }

  return session.gad_r < 0 ? 0 : session.gad_r > 21 ? 21 : session.gad_r

}
function Middle({ classes, showEHResults, sessions, columnsToShow }) {
  // Check if EH results must be shown
  const ehResults = typeof showEHResults === 'undefined' || showEHResults;

  const [phqShow, setPhqShow] = useState(true);
  const [gadShow, setGadShow] = useState(true);
  const hasPhq = some(sessions, s => s.phqScores && s.phqScores.length);
  const hasGad = some(sessions, s => s.gadScores && s.gadScores.length);
  const hasPhq9 = some(sessions, s => s.phq9);

  // Check if the user has 4 way classification (phq_4c and gad_4c) in the session table columns
  // In that case, show FourWayAssessmentChart
  const has4WayClassification = (columnsToShow.indexOf("phq_4c") !== -1 || columnsToShow.indexOf("gad_4c") !== -1);

  const phqData = sessions
    .slice(0)
    .reverse()
    .map(s => ({
      id: s.uid,
      value: getDepressionValue(s, ehResults, has4WayClassification, hasPhq9),
      // expected: s.phq_r ? random(20) : null,
      // confidence: random(2, 5)
      expected:
        s.phqStatus === 'COMPLETE' &&
          s.phqScores &&
          s.phqScores.length &&
          phqShow
          ? s.phqTotal
          : null,
      confidence: s.ellipsisScore * 2 || 0
    }));
  const gadData = sessions
    .slice(0)
    .reverse()
    .map(s => ({
      id: s.uid,
      value: getAnxietyValue(s, ehResults, has4WayClassification),
      // expected: s.gad_r ? random(20) : null,
      // confidence: random(2, 5)
      expected:
        s.gadStatus === 'COMPLETE' &&
          s.gadScores &&
          s.gadScores.length &&
          gadShow
          ? s.gadTotal
          : null,
      confidence: s.anxietyScore * 2 || 0
    }));

  return (
    <div id="middleContOne" className={classes.root}>
      <div id="depressionScore" className={classes.row}>
        <div className={classes.leftTop}>
          <Typography className={classes.title} variant="subtitle1">
            Depression Score
          </Typography>
        </div>
        <div className={classes.right}>
          <div className={classes.rightTop}>
            <Typography className={classes.title} variant="subtitle1">
              Session #
            </Typography>
            <Captions
              show={phqShow}
              onShow={setPhqShow}
              ehResults={ehResults}
              expectedCaption={hasPhq ? hasPhq9 ? 'PHQ-9 Score' : 'PHQ-8 Score' : null}
            />
          </div>

          {has4WayClassification ? (
            <FourWayAssessmentChart
              showSessions
              showRight
              showPHQ={phqShow}
              data={phqData}
              expand24
              stops={hasPhq9 ? [0, 5, 10, 15, 27] : [0, 5, 10, 15, 24]}
            />
          ) : (
            <AssessmentChart
              showSessions
              showRight
              showPHQ={phqShow}
              data={phqData}
              expand24
              stops={hasPhq9 ? [0, 9, 18, 27] : [0, 9, 18, 24]}
            />
          )}

        </div>
      </div>

      <div id="anxietyScore" className={classes.row}>
        <div className={classes.left}>
          <Typography className={classes.title} variant="subtitle1">
            Anxiety Score
          </Typography>
        </div>
        <div className={classes.right}>
          <div className={classes.rightTop}>
            <div />
            <Captions
              show={gadShow}
              onShow={setGadShow}
              ehResults={ehResults}
              expectedCaption={hasGad ? 'GAD-7 Score' : null}
            />
          </div>

          {has4WayClassification ? (
            <FourWayAssessmentChart
              showPHQ={gadShow}
              showRight
              data={gadData}
              expand24
              stops={[0, 5, 10, 15, 21]}
            />
          ) : (
            <AssessmentChart
              showPHQ={gadShow}
              showRight
              data={gadData}
              expand24
              stops={[0, 9, 15, 21]}
            />
          )}

        </div>
      </div>
    </div>
  );
}

Middle.propTypes = {
  sessions: PropTypes.array,
  showEHResults: PropTypes.bool,
  classes: PropTypes.object,
  columnsToShow: PropTypes.array
};

const enhance = withStyles(styles);

export default enhance(Middle);
