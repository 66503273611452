import firebase from '../../../config/firebase';

const cache = [];
const func = firebase
  .functions()
  .httpsCallable('dashboardFunctions-getAllSurveysQuestionsAnswers');

export async function getAllCustomSurveys(surveyIds) {
    const missingSurveys = [];
    for(let i = 0; i < surveyIds.length; i+=1){
        const survey = surveyIds[i];
        if(!cache[survey]){
            missingSurveys.push(survey);
        }
    }
    if(missingSurveys.length === 0){
        return Promise.resolve(cache);
    }

    const { data } = await func({surveyIDs: missingSurveys});

    for(let j = 0; j < data.length; j+=1){
        const s = data[j];
        cache[s.id] = s;
    }
    
    return Promise.resolve(cache);
}
