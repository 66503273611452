/* eslint-disable prefer-promise-reject-errors */
import firebase from 'firebase';
import jwt_decode from 'jwt-decode';

const isTokenValid = async (token) => {
  const decodedToken = await jwt_decode(token);
  const currentDate = new Date();
  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return false
  } 
    return true
  
}

const getJWToken = async (partnerId) => {
  // Check if there is a Valid token in the Session Storage
  const storageToken = sessionStorage.getItem('fileDropToken');
  const isValid = storageToken ? await isTokenValid(storageToken) : false;

  if (isValid) {
    console.log("Token still valid, no need to get a new one");
    return storageToken
  }
  // Remove expired token from the storage
  console.log("Token not valid, get a new one");
  sessionStorage.removeItem('fileDropToken');

  const getToken = firebase
    .functions()
    .httpsCallable('dashboardFunctions-getJWToken');

  try {
    const { data } = await getToken({ partnerId });

    const token = data?.token;
    if (!token) {
      return null
    }
    sessionStorage.setItem('fileDropToken', token);
    return token;
  } catch (error) {
    console.error(error);
    return Promise.reject('Error creating JWT token');
  }
};

export const getFileDropsInfo = async (partnerId) => {

  const token = await getJWToken(partnerId);

  if (!token) {
    return {
      success: "error",
      message: "Error creating a valid token to process the request."
    }
  }

  try {
    const url = `https://dropbox-integration-tihy5arxia-uc.a.run.app/api/v1/filevault/sessions?app=dropbox&partnerId=${partnerId}&days=10&token=${token}`;
    return await fetch(url, {
      method: 'GET',
      // mode: 'no-cors',
      headers: {
        'Accept': 'application/json'
      }
    }).then(async response => {
      if (response.ok) {
        const jsonResult = await response.json();
        console.log(jsonResult)
        return jsonResult;
      }

      // Remove token from the storage // TODO: Ask Anoop to return specific code when token does not work on his side
      sessionStorage.removeItem('fileDropToken');
    }).catch(err => {
      console.error(err);
      // Remove token from the storage // TODO: Ask Anoop to return specific code when token does not work on his side
      sessionStorage.removeItem('fileDropToken');
      return Promise.reject('Error fetch url');
    });

  } catch (error) {
    console.error(error);
    return Promise.reject('Error fetching file drops from dropbox.');
  }
};

export const scoreSession = async (sessionId, partnerId, indexName) => {

  const token = await getJWToken(partnerId);

  if (!token) {
    return {
      success: "error",
      message: "Error creating a valid token to process the request."
    }
  }

  const bearer = `Bearer ${  token}`;
  try {
    const url = `https://dropbox-integration-tihy5arxia-uc.a.run.app/api/async/v1/filevault/session/score`;
    return await fetch(url, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        'Authorization': bearer,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        app: 'dropbox',
        partnerId,
        sessionId,
        indexName,
      })
    }).then(async response => {
      if (response.ok) {
        return true
      }

      // Remove token from the storage // TODO: Ask Anoop to return specific code when token does not work on his side
      sessionStorage.removeItem('fileDropToken');
      return Promise.reject('Error fetch url');
    }).catch(err => {
      console.error(err);
      // Remove token from the storage // TODO: Ask Anoop to return specific code when token does not work on his side
      sessionStorage.removeItem('fileDropToken');
      return Promise.reject('Error fetch url');
    });

  } catch (error) {
    console.error(error);
    return Promise.reject('Error fetching file drops from dropbox.');
  }
};

export const scoreMultipleSessions = async (partnerId, indexName) => {

  const token = await getJWToken(partnerId);

  if (!token) {
    return {
      success: "error",
      message: "Error creating a valid token to process the request."
    }
  }

  const bearer = `Bearer ${  token}`;
  try {
    const url = `https://dropbox-integration-tihy5arxia-uc.a.run.app/api/async/v1/filevault/index/score`;
    return await fetch(url, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        'Authorization': bearer,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        app: 'dropbox',
        partnerId,
        indexName,
      })
    }).then(async response => {
      if (response.ok) {
        return true
      }

      // Remove token from the storage // TODO: Ask Anoop to return specific code when token does not work on his side
      sessionStorage.removeItem('fileDropToken');
      return Promise.reject('Error fetch url');
    }).catch(err => {
      console.error(err);
      // Remove token from the storage // TODO: Ask Anoop to return specific code when token does not work on his side
      sessionStorage.removeItem('fileDropToken');
      return Promise.reject('Error fetch url');
    });

  } catch (error) {
    console.error(error);
    return Promise.reject('Error fetching file drops from dropbox.');
  }

};

export const getSessionScore = async (sessionId) => {
  return firebase
    .firestore()
    .collection('restScores').doc(sessionId).get().then((doc) => {
      if (doc.exists) {
        return doc.data();
      } 
        return Promise.reject('No doc available');
      
    }).catch((error) => {
      console.error(error);
      return Promise.reject('Error fetching rest session score');
    });
}
