import firebase from 'firebase';

export const getRecentMeetings = async (partnerId, forLive) => {
  const getMeetings = firebase
    .functions()
    .httpsCallable('dashboardFunctions-getRecentCloudMeetings');

  try {
    const { data } = await getMeetings({ partnerId, forLive });
    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getSessionScore = async (sessionId) => {
  const getRestScoreForSessionId = firebase.functions().httpsCallable('dashboardFunctions-getRestScoreForSessionId');

  try {
    const { data } = await getRestScoreForSessionId({ sessionId });
    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
