import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';

const StatusAlert = ({ message }) => {
  const [open, setOpen] = useState(true);
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StatusAlert.propTypes = {
  message: PropTypes.string
};

export default StatusAlert;
