import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PatientsTable from '../../../common/table/table';
import Dialog from '../../../common/dialog/dialog';
import DeleteDialog from '../../../common/dialog/deleteDialog';
import EditDialog from './editPatientDialog';
import firebase from '../../../../config/firebase';
import Search from '../../../common/search/Search';
import notifier from '../../../common/utils/notifier';
import MessageDialog from './messagePatientDialog';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    paddingTop: 8,
    width: '100%',
    position: 'relative',
    height: 60,
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      alignItems: 'center'
    }
  },
  cont_one: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});

function MiddleMainPage(props) {
  const {
    classes,
    loginState,
    onboardState,
    portalConfig,
    onloadPatients,
    partnerInfo,
    history
  } = props;
  const { configurations, role, showSessionsColumns, columnsName } = portalConfig;
  const { user: { email } } = loginState;
  const config = { configurations, role, email };
  const [deleteP, setDeletePatient] = useState('');
  const [editP, setEditPatient] = useState('');
  const [messageP, setMessagePatient] = useState('');
  const [emailP, setEmailPatient] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = useMemo(() => {
    const defaultCol = [
      'mrn',
      'followedBy',
      'createdAt',
      'totalSessions',
      'patientsActions'
    ]
    const defaultColName = {
      mrn: columnsName.mrn,
      followedBy: 'Followed By',
      createdAt: 'Registration Date',
      totalSessions: 'Total Sessions',
      patientsActions: 'Actions'
    }

    const PII = [];
    if(showSessionsColumns.indexOf('name') !== -1){
      PII.push('name');
      defaultColName.name = 'First Name';
    }
    if(showSessionsColumns.indexOf('lastName') !== -1){
      PII.push('lastName');
      defaultColName.lastName = 'Last Name';
    }
    
    return {
      pos: [...PII, ...defaultCol],
      names: defaultColName
    };

  }, []);

  const deletePatient = () => {
    setLoading(true);
    const deleteAction = firebase
      .functions()
      .httpsCallable('dashboardFunctions-deletePatient');
    deleteAction({
      id: deleteP.uid
    })
      .then(result => {
        console.log(result);
        // Remove patient from props array
        /* onboardState.patientsData.splice(
          onboardState.patientsData.findIndex(item => item.uid === deleteP.uid),
          1
        ); */
        setTimeout(() => {
          notifier.info('Patient deleted correctly.');
          setLoading(false);
          setDeletePatient('');

          const data = {
            sort: onboardState.sort,
            sortOrder: onboardState.sortOrder,
            rowsPerPage: onboardState.rowsPerPage,
            offset: onboardState.offset,
            filters: onboardState.filters
          };
          onloadPatients(data);
        }, 10000);
      })
      .catch(e => {
        console.log(e);
        setDeletePatient('');
        setLoading(false);
        notifier.error(
          'Sorry, an error occurred while deleting the patient. Try again later.'
        );
      });
  };

  const editPatient = async data => {
    setLoading(true);
    const updatePatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-updatePatient');
    setLoading(true);
    try {
      await updatePatient({
        id: editP.uid,
        data
      })
        .then(() => {
          // find index
          const objIndex = onboardState.patientsData.findIndex(
            obj => obj.uid === editP.uid
          );

          // Remove patient from props array
          onboardState.patientsData[objIndex].name = data.name;
          onboardState.patientsData[objIndex].lastName = data.lastName;
          onboardState.patientsData[objIndex].mrn = data.mrn;
          onboardState.patientsData[objIndex].assignedAdminId =
            data.assignedAdminId;

          setEditPatient('');
          notifier.info('Patient edited correctly.');
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setEditPatient('');
          setLoading(false);
          notifier.error(
            'Sorry, an error occurred while editing the patient. Try again later.'
          );
        });
    } catch (e) {
      console.error(e);
      notifier.error(
        'Sorry, an error occurred while editing the patient. Try again later.'
      );
    }

    setLoading(false);
  };

  const sendMessage = async ({ phoneNumber, content }) => {
    setLoading(true);

    const messagePatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-messagePatient');
    
    try {
      await messagePatient({patient: messageP, phone: phoneNumber, content, clinician: loginState.portalConfig.uid });
      setMessagePatient('');
      notifier.info('Message sent successfully');
      setLoading(false);
    } catch (error) {
      setLoading('false');
      notifier.error('Something went wrong while sending a message');
      console.error(error)
    }
  };

  // eslint-disable-next-line no-shadow
  const sendEmail = async ({ email, content, subject }) => {
    setLoading(true);

    const emailPatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-emailPatient');
    
    try {
      await emailPatient({ patient: emailP, email, content, subject, clinician: loginState.portalConfig.uid });
      setEmailPatient('');
      notifier.info('Email sent successfully');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notifier.error('Something went wrong while sending an email');
      console.error(error);
    }
  };

  const OpenDeleteDialog = () => {
    return (
      <Dialog close={() => setDeletePatient('')}>
        <DeleteDialog
          close={() => setDeletePatient('')}
          text={`Are you sure you want to delete this patient? ${deleteP.mrn}`}
          deleteAction={() => deletePatient()}
          loading={loading}
        />
      </Dialog>
    );
  };

  const OpenEditDialog = () => {
    return (
      <Dialog close={() => setEditPatient('')}>
        <EditDialog
          close={() => setEditPatient('')}
          info={editP}
          partnerInfo={partnerInfo}
          editPatient={editPatient}
          loading={loading}
          config={config}
        />
      </Dialog>
    );
  };

  const OpenMessageDialog = () => {
    return (
      <Dialog close={() => setMessagePatient('')}>
        <MessageDialog
          close={() => setMessagePatient('')}
          phoneNumber={messageP?.contactPhone}
          patientName={messageP?.name}
          partnerName={partnerInfo.id}
          template={partnerInfo?.messageTemplate}
          sendMessage={sendMessage}
          loading={loading}
        />
      </Dialog>
    );
  };

  const OpenEmailDialog = () => {
    return (
      <Dialog close={() => setEmailPatient('')}>
        <MessageDialog
          close={() => setEmailPatient('')}
          email={emailP?.email || emailP?.contactEmail}
          patientName={emailP?.name}
          partnerName={partnerInfo.id}
          template={partnerInfo?.messageTemplate}
          sendMessage={sendEmail}
          loading={loading}
        />
      </Dialog>
    );
  };

  const saveConfiguration = value => {
    const data = {
      sort: onboardState.sort,
      sortOrder: onboardState.sortOrder,
      rowsPerPage: onboardState.rowsPerPage,
      offset: onboardState.offset,
      filters: value.filters
    };

    onloadPatients(data);
  };

  return (
    <div className={classes.root}>
      <div style={{ width: '50%', border: '1px solid #D0D0D0', marginTop: 40 }}>
        <Search
          activeFilters={onboardState.filters}
          saveConfiguration={saveConfiguration}
          spinner={onboardState.showSpinner}
          setFilter={() => {}}
        />
      </div>
      <div className={classes.cont_one}>
        <PatientsTable
          config={config}
          lastLogin={new Date().getTime()}
          loadData={onloadPatients}
          sort={onboardState.sort}
          sortOrder={onboardState.sortOrder}
          rowsPerPage={onboardState.rowsPerPage}
          offset={onboardState.offset}
          filters={onboardState.filters}
          settings={{}} // settings
          data={onboardState.patientsData}
          dataCount={onboardState.patientsCount}
          showSpinner={onboardState.showSpinner}
          showColumns={columns.pos}
          hideColumns={[]}
          columnsName={columns.names}
          saveConfiguration={() => {}} // this.saveConfiguration
          saveConfigCompleted={loginState.saveConfigCompleted}
          deletePatient={value => setDeletePatient(value)} // deletePatient
          editPatient={value => setEditPatient(value)} // editPatient
          sendMessage={value => setMessagePatient(value)} // messagePatient
          sendEmail={value => setEmailPatient(value)} // emailPatient
          saveLogs={() => {}}
          downloadCsv={() => {}}
          download={false}
          history={history}
        />
      </div>
      {deleteP && <OpenDeleteDialog />}
      {editP && <OpenEditDialog />}
      {messageP && <OpenMessageDialog />}
      {emailP && <OpenEmailDialog />}
    </div>
  );
}

MiddleMainPage.propTypes = {
  classes: PropTypes.object,
  loginState: PropTypes.object,
  onboardState: PropTypes.object,
  portalConfig: PropTypes.object,
  history: PropTypes.object,
  onloadPatients: PropTypes.func,
  partnerInfo: PropTypes.object,
};

export default withStyles(styles)(MiddleMainPage);
