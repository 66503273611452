import React, { useState, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles, Typography, CircularProgress } from '@material-ui/core';
import { PHQ9AlertCard } from './components/PHQ9AlertCard';
import { NotificationAlertCard } from './components/NotificationAlertCard';

import { getCliniciansMap } from '../common/form/getClinicians';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: !isMobile ? 20 : 0

  },
  cont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  control: {
    width: '100%',
    height: '40px',
    backgroundColor: '#D0D1C9',
    display: 'flex'
  }
});

function AlertComponent({ classes, alertState, portalConfig, getAlertsData, updateAck, acknowledgeAlert }) {
  const [clinicians, setClinicians] = useState(null);
  const { portalActivePartners, configurations } = portalConfig;
  const { phq9Config, notificationConfig, partnerTimezone } = configurations;
  const days =
    phq9Config && phq9Config.phq9PortalShowDays
      ? phq9Config.phq9PortalShowDays
      : 365;
  const { showSpinner, alertsData, patientsMap } = alertState;
  let interval = null;

  useMemo(async () => {
    // Need the list of clinicians for the current partner.
    const data = await getCliniciansMap(portalActivePartners[0]);
    setClinicians(data);
  }, []);

  const onloadAlerts = () => {
    if (showSpinner || updateAck.spinner) {
      // Do not load while the getAlertsData function has already been called
      console.log("onLoadAlerts called while loading previous call.")
      clearInterval(interval);
      return
    }

    const subDate = moment()
      .utc()
      .subtract(days, 'd')
      .valueOf();
    const dateFrom = `${moment
      .utc(subDate, 'x')
      .format('YYYY-MM-DDTHH:mm:ss')}`;

    getAlertsData(portalActivePartners, dateFrom);
  };

  useEffect(() => {
    if ((phq9Config && phq9Config.phq9Alert) || (notificationConfig?.thresholdAlert) || (notificationConfig?.upwardAlert)) {
      console.log("INITIAL load of alerts..")
      onloadAlerts();
    }
  }, []);

  useEffect(() => {
    if ((phq9Config && phq9Config.phq9Alert) || (notificationConfig?.thresholdAlert) || (notificationConfig?.upwardAlert)) {
      /**
       * Load alerts every 15 seconds
       */
      // console.log("Set reloading interval every 15 sec")
      interval = setInterval(() => {
        // console.log("Loading alerts after 15 sec")
        onloadAlerts();
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [alertsData]);

  if ((!phq9Config || !phq9Config.phq9Alert) && (!notificationConfig?.thresholdAlert) && (!notificationConfig?.upwardAlert)) {
    return (
      <>
        <div className={classes.cont}>
          <Typography
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            {`No Alerting feature active for the ${portalActivePartners[0]} partner. Please, contact support@ellipsishealth.com if you wish to use this feature.`}
          </Typography>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Typography
          style={{ marginTop: 20, marginBottom: 20 }}
        >{`Showing alerts for the last ${days} days`}
        </Typography>

        <div className={classes.cont}>
          {(showSpinner && alertsData.length === 0) || !clinicians ? (
            <>
              <CircularProgress style={{ marginTop: 20 }} color="secondary" />
              <Typography style={{ marginTop: 20 }}>Loading alerts</Typography>
            </>
          ) : (
            alertsData.map(alert => {

              if (alert.type === "DAILY_NOTIFICATION") {
                return (
                  <>
                    <NotificationAlertCard key={alert.alertId} data={alert} patientsMap={patientsMap} loading={updateAck} clinicians={clinicians} config={{ ...phq9Config, timezone: partnerTimezone }} />
                    <div style={{ height: 20 }} />
                  </>
                )
              }
              if (alert.type === "PHQ9Q9ALERT") {
                return (
                  <>
                    <PHQ9AlertCard key={alert.alertId} data={alert} loading={updateAck} config={{ ...phq9Config, timezone: partnerTimezone }} acknowledgeAlert={(data) => { clearInterval(interval); acknowledgeAlert(data); }} />
                    <div style={{ height: 20 }} />
                  </>
                )
              }

              return <></>

            })
          )}
          {!showSpinner && (!alertsData || alertsData.length === 0) ? (
            <Typography style={{ marginTop: 20 }}>
              No Alerts available for your account.
            </Typography>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
}

AlertComponent.propTypes = {
  classes: PropTypes.object,
  getAlertsData: PropTypes.func,
  alertState: PropTypes.object,
  portalConfig: PropTypes.object,
  portalActivePartners: PropTypes.array,
  updateAck: PropTypes.object,
  acknowledgeAlert: PropTypes.func
};

export default withStyles(styles)(AlertComponent);
