import { createTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#394f68', main_two: '#3abab6' },
  secondary: {
    main: '#f7d571',
    main_two: '#5687d6'
  },
  tertiary: {
    green: '#81de80',
    orange: '#f29335',
    purple: '#a773ff'
  },
  special: {
    special: '#d43a51',
    special_two: '#ebe3c8'
  }
};

const typography = {
  fontFamily: [
    'Open Sans', 
    'sans-serif'
  ].join(','),
}


export default createTheme({ palette, typography });
