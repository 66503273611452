import { connect } from 'react-redux';
import StreamComponent from './StreamComponent';

const mapStateToProps = state => {
  return {
    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login,
    portalConfig: state.login.portalConfig
  };
};

const StreamContainer = connect(
  mapStateToProps,
)(StreamComponent);

export default StreamContainer;
