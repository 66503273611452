import firebase from 'firebase';
import fetch from 'cross-fetch';
import { STREAM_ENDPOINT } from 'config/stream';

const fetchToken = async ({ clientId, clientSecret }) => {
  try {
    if (!clientId || !clientSecret) return null;

    const response = await fetch(
      `${STREAM_ENDPOINT}/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `client_id=${clientId}&client_secret=${clientSecret}`,
      }
    );

    return response.json().then(res => {
      if (response.status !== 200 && response.success) {
        return Promise.reject(
          new Error(`Error while fetching token: ${res.message}`)
        );
      }

      return res.token;
    });
  } catch (err) {
    console.log('Error while fetching token ', err);
    return Promise.reject(err);
  }
};

export const getCurrentSteamingSessions = async partnerId => {
  const getSessions = firebase
    .functions()
    .httpsCallable('dashboardFunctions-getCurrentStreamingSessions');

  try {
    const { data } = await getSessions({ partnerId });
    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getTokenForStreamSessions = async (partnerId) => {
  const getData = firebase
    .functions()
    .httpsCallable('dashboardFunctions-getStreamApiKeysForPartner');

  try {
    const { data: { success, message, data }} = await getData({ partnerId });
    if (!success) {
      return Promise.reject(message);
    };

    const { key, secret } = data;
    const token = await fetchToken({ clientId: key, clientSecret: secret });

    return token;
  } catch (error) {
    console.error()
  }
}
