import types from './types';

const INITIAL_STATE = {
  showLoginSpinner: false,
  isLoggedIn: false,
  saveConfigCompleted: null,
  snackbar: {
    variant: '',
    message: ''
  },
  user: {},
  multi_partner: false,
  portalConfig: {
    role: ""
  }
};
const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_LOGIN: {
      return {
        ...state,
        isLoggedIn: false,
        showLoginSpinner: true,
        snackbar: {
          variant: '',
          message: ''
        }
      };
    }

    case types.RECEIVE_USER_INFO: {
      const { user, portalConfig } = action;

      // Hide recaptcha after login
      const recaptcha = document.querySelector('.grecaptcha-badge');
      recaptcha.style.visibility = 'hidden';

      return {
        ...state,
        user,
        followedby: user.email,
        portalConfig,
        snackbar: {
          variant: '',
          message: ''
        }
      };
    }

    case types.RECEIVE_PORTAL_CONFIG_INFO: {
      const { configurations, portalActivePartners } = action;

      return {
        ...state,
        portalConfig: {
          ...state.portalConfig,
          portalActivePartners,
          configurations
        },
        isLoggedIn: true,
        showLoginSpinner: false
      };
    }

    case types.SELECT_PARTNERS_TO_SHOW: {
      return {
        ...state,
        multi_partner: true
      };
    }

    case types.REQUEST_REGISTRATION: {
      return {
        ...state,
        isLoggedIn: false,
        showLoginSpinner: true,
        snackbar: {
          variant: '',
          message: ''
        }
      };
    }

    case types.RECEIVE_REGISTRATION_RESULT: {
      const { snackbar } = action;
      return {
        ...state,
        snackbar,
        showLoginSpinner: false
      };
    }

    case types.REQUEST_SAVE_CONFIG: {
      return {
        ...state,
        showLoginSpinner: true,
        saveConfigCompleted: false,
        snackbar: {
          variant: '',
          message: ''
        }
      };
    }

    case types.SAVE_CONFIG_COMPLETED: {
      const { snackbar } = action;

      return {
        ...state,
        showLoginSpinner: false,
        saveConfigCompleted: true,
        snackbar
      };
    }

    case types.REQUEST_SAVE_FILTERS: {
      return {
        ...state,
        showLoginSpinner: true,
        saveConfigCompleted: false,
        snackbar: {
          variant: '',
          message: ''
        }
      };
    }

    case types.SAVE_FILTERS_COMPLETED: {
      const { data } = action;
      // TODO: Implement a switch statement
      if (data.sessionsFilters) {
        return {
          ...state,
          portalConfig: {
            ...state.portalConfig,
            sessionsFilters: data.sessionsFilters
          },
          showLoginSpinner: false,
          saveConfigCompleted: true
        };
      }

      return {
        ...state,
        showLoginSpinner: false,
        saveConfigCompleted: true
      };
    }

    case types.ERROR: {
      const { snackbar } = action;
      return {
        ...state,
        showLoginSpinner: false,
        snackbar
      };
    }

    case types.LOGIN_ERROR: {
      return {
        ...state,
        isLoggedIn: false,
        showLoginSpinner: false
      };
    }

    case types.LOGOUT: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default loginReducer;
