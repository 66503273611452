import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { MdInput } from 'react-icons/md';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '../common/dialog/dialog';
import InvitesDialog from './components/invitesDialog';
import firebase from '../../config/firebase';

import FollowedBySelect from '../common/form/FollowedBySelect';

const styles = theme => ({
  root: {
    width: '100%'
  },
  control: {
    width: '100%',
    height: isMobile ? '60px' : '40px',
    backgroundColor: theme.palette.special.special_two,
    display: 'flex',
  },
  sessionCont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: isMobile ? '10px' : '40px',
    [theme.breakpoints.down(1000)]: {
      alignItems: 'center'
    }
  },
  secondRoot: {
    padding: isMobile ? '0px' : '40px 40px 0px 40px',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row'
  },
  topPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: isMobile ? 'column' : 'row',
    width: isMobile ? '100%' : '65%',
    height: isMobile ? 500 : 250,
    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column'
    }
  },
  cardSession: {
    margin: 5,
    height: 180,
    flex: 1,
    border: '2px solid',
    borderColor: theme.palette.tertiary.orange,
    [theme.breakpoints.up(1000)]: {
      width: 250
    }
  },
  emptyDiv: isMobile ? {} : { flex: 1 },
  cardInvite: {
    margin: 5,
    height: 180,
    flex: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    [theme.breakpoints.up(1000)]: {
      width: 250
    }
  },
  cardContent: {
    // padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '70%'
  },
  cardContentNoActions: {
    // padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%'
  },
  cardActions: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    height: '30%'
  },
  textNumber: {
    fontWeight: 600,
    fontSize: 60,
    [theme.breakpoints.down(1200)]: {
      fontSize: 60
    }
  },
  textNumberInvitesCount: {
    color: theme.palette.tertiary.orange,
    fontWeight: 600,
    fontSize: 60,
    [theme.breakpoints.down(1200)]: {
      fontSize: 60
    }
  },
  textSection: {
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.down(1200)]: {
      fontSize: 15
    }
  },
  button: {
    width: '90%',
    minWidth: 150,
    background: '#AF46AE',
    backgroundColor: theme.palette.primary.main_two,
    color: '#FFFFFF',
    fontSize: 12,
    '&:hover': {
      background: theme.palette.primary.main_two
    }
  }
});

class HomeComponent extends React.Component {
  state = {
    open: false,
    invitesCount: -1,
    invites: []
  };

  componentDidMount() {
    sessionStorage.setItem('page', 'dashboard');
    this.prepareData();
    this.getInvitedPatients();
  }

  openDialog = () => {
    const { invites, invitesCount } = this.state;
    const { portalConfig } = this.props;
    const { configurations } = portalConfig;
    const { partnerTimezone } = configurations;

    return (
      <Dialog maxWidth="md" close={() => this.setState({ open: false })}>
        <InvitesDialog
          close={() => this.setState({ open: false })}
          invites={invites}
          invitesCount={invitesCount}
          getInvitedPatients={this.getInvitedPatients}
          partnerList={portalConfig.portalActivePartners}
          role={portalConfig.role}
          timezone={partnerTimezone}
        />
      </Dialog>
    );
  };

  getInvitedPatients = () => {
    const { portalConfig } = this.props;
    this.setState({
      invitesCount: -1
    });

    const invitesList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_getInvitedPatients');
    invitesList({
      partnerList: portalConfig.portalActivePartners
    })
      .then(result => {
        this.setState({
          invitesCount: JSON.parse(result.data).invitesCount.count,
          invites: JSON.parse(result.data).invitesList
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  prepareData = () => {
    const { homeState, portalConfig } = this.props;
    const { filters } = homeState;

    const partnerList = portalConfig.portalActivePartners;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;

    this.loadData(lastLogin, partnerList, filters);
  };

  loadData = (lastLogin, partnerList, filters) => {
    const {
      homeState,
      onboardState,
      getSessionData,
      getPatientsData
    } = this.props;
    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      filters
    );
  };

  changeFollowedby = value => {
    /**
     * CHANGE OF THE FOLLOWEDBY CONTROL
     * When the followedBy field is changed, the followed action are performed here:
     * 1- SetFollowedBy method called to set the new email
     * 2- Load again data based on the new filter
     */
    const {
      getPatientsData,
      getSessionData,
      onboardState,
      homeState,
      portalConfig,
      setFollowedby,
      setTotalPatients
    } = this.props;

    const partnerList = portalConfig.portalActivePartners;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;
    let filters = [];
    if (value) {
      filters = [
        {
          field: 'assignedAdminId',
          operator: '=',
          value: `${value}`,
          type: 'String'
        }
      ];
    }
    setFollowedby(value);
    setTotalPatients();

    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      filters
    );
  };

  render() {
    const {
      classes,
      loginState,
      homeState,
      history,
      onboardState,
      portalConfig
    } = this.props;
    const { invitesCount, open } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.control}>
          <Typography
            style={{
              margin: 'auto',
              marginLeft: 8,
              display: 'flex',
              color: '#000000'
            }}
          >
            Show patients followed by: &nbsp;
            <FollowedBySelect
              partner={portalConfig.portalActivePartners[0]}
              user={loginState}
              followedby={
                homeState.filters.length > 0 ? homeState.filters[0].value : null
              }
              onChange={value => this.changeFollowedby(value)}
              loadingData={onboardState.showSpinner && homeState.showSpinner}
            />
          </Typography>
        </div>
        <div className={classes.secondRoot}>
          <div className={classes.topPanel}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" style={{ fontSize: 20, marginBottom: 10 }}>REGISTRATION</Typography>
              <div style={{ display: 'flex' }}>
                <Card raised="true" className={classes.cardInvite}>
                  <CardContent className={classes.cardContent}>
                    {homeState.showPSpinner || onboardState.totalPatients === -1 ? (
                      <CircularProgress />
                    ) : (
                      <Typography className={classes.textNumber} variant="h2">
                        {onboardState.totalPatients}
                      </Typography>
                    )}

                    <Typography className={classes.textSection} variant="body1">
                      Total patients registered
                    </Typography>
                  </CardContent>
                </Card>
                <Card raised="true" className={classes.cardInvite}>
                  <CardContent className={classes.cardContent}>
                    {invitesCount === -1 ? (
                      <CircularProgress />
                    ) : (
                      <Typography
                        className={classes.textNumber}
                        variant="h2"
                      >
                        {invitesCount}
                      </Typography>
                    )}
                    <Typography className={classes.textSection} variant="body1">
                      To complete registration
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button
                      disabled={invitesCount === -1 || invitesCount === 0}
                      onClick={() => this.setState({ open: true })}
                      variant="contained"
                      className={classes.button}
                    >
                      Re-Invite Patients
                    </Button>
                  </CardActions>
                </Card>
              </div>
            </div>
            <div className={classes.emptyDiv} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" style={{ fontSize: 20, marginBottom: 10 }}>ACTIVITY</Typography>
              <div style={{ display: 'flex' }}>
                <Card raised="true" className={classes.cardSession}>
                  <CardContent className={classes.cardContent}>
                    {homeState.showSSpinner || homeState.totalSessions === -1 ? (
                      <CircularProgress />
                    ) : (
                      <Typography className={classes.textNumber} variant="h2">
                        {homeState.totalSessions}
                      </Typography>
                    )}
                    <Typography className={classes.textSection} variant="body1">
                      Total patient sessions
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <IconButton
                      onClick={() => {
                        history.push({
                          pathname: '/sessions',
                          search: '?page=1&v=all',
                          state: { value: '0' }
                        });
                      }}
                    >
                      <MdInput style={{ color: '#000000', width: 30, height: 30 }} />
                      <Typography
                        style={{ fontSize: 20, color: '#000000', marginLeft: 10 }}
                        variant="body1"
                      >
                        Sessions
                      </Typography>
                    </IconButton>
                  </CardActions>
                </Card>
                <Card raised="true" className={classes.cardSession}>
                  <CardContent className={classes.cardContent}>
                    {homeState.showSpinner && homeState.newSessionsNumber === -1 ? (
                      <CircularProgress />
                    ) : (
                      <Typography
                        className={classes.textNumberInvitesCount}
                        variant="h2"
                      >
                        {homeState.newSessionsNumber}
                      </Typography>
                    )}
                    <Typography className={classes.textSection} variant="body1">
                      New sessions
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <IconButton
                      onClick={() => {
                        history.push({
                          pathname: '/sessions',
                          search: '?page=1&v=new',
                          state: { value: '1' }
                        });
                      }}
                    >
                      <MdInput style={{ color: '#000000', width: 30, height: 30 }} />
                      <Typography
                        style={{ fontSize: 20, color: '#000000', marginLeft: 10 }}
                        variant="body1"
                      >
                        Sessions
                      </Typography>
                    </IconButton>
                  </CardActions>
                </Card>
              </div>
            </div>
          </div>
          {open === true ? this.openDialog() : ''}
        </div>
      </div>
    );
  }
}

HomeComponent.propTypes = {
  classes: PropTypes.object,
  homeState: PropTypes.object,
  loginState: PropTypes.object,
  onboardState: PropTypes.object,
  portalConfig: PropTypes.object,
  getSessionData: PropTypes.func,
  getPatientsData: PropTypes.func,
  history: PropTypes.object,
  setFollowedby: PropTypes.func,
  setTotalPatients: PropTypes.func
};

export default withStyles(styles)(HomeComponent);
