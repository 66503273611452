import React from 'react';
import { connect } from 'react-redux';

import FileDropComponent from './FileDropsComponent';

const mapStateToProps = state => {
  return {
    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login,
    portalConfig: state.login.portalConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const FileDropContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileDropComponent);

export default FileDropContainer;
