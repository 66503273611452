import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Typography,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { GrDocumentCsv, GrDocumentPdf } from 'react-icons/gr';
import SessionJournal from 'components/SessionJournal';
import AssessmentDoc from '../../../common/reports/pdf/d&a_assessment';

import { formatDate } from '../../../../components/Date';

import SessionScores from './components/SessionScores';
import SurveyResults from './components/SurveyResults';

const styles = theme => ({
  root: {
    // height: '300px',
    margin: 4,
    padding: 20,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#b8b8b8',
    backgroundColor: '#f4f4f4'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexOrientation: 'row'
  },
  actionDivCont: {
    display: 'flex',
    flexOrientation: 'row',
    flex: 1,
    justifyContent: 'flex-end'
  },
  actionDiv: {
    width: 35,
    height: 35,
    backgroundColor: theme.palette.special.special_two,
    margin: 2,
    borderRadius: '20%',
    textAlign: 'center'
  },
  actionPdfReadyDiv: {
    width: 35,
    height: 35,
    margin: 2,
    borderRadius: '20%',
    textAlign: 'center',
    backgroundColor: '#008000',
    '&:hover': {
      backgroundColor: '#008000',
      transition: 'none'
    }
  },
  columnDivs: {
    width: '100%',
    padding: 1,
    margin: 5
  },
  rowDivs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    margin: 5
  },
  bold: {
    fontWeight: 'bold'
  },
  cardPHQGAD: {
    flex: 1,
    height: 150,
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.secondary.main_two
  },
  cardPHQGADContent: {
    display: 'grid',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%'
  },
  textFourWay: {
    flex: 1,
    fontSize: 20,
    fontWeight: 600
  },
  textNumber: {
    flex: 1,
    fontSize: 20,
    fontWeight: 600
  }
});

const prepareDepressionValue = (session, has4WayClassification, hasPHQGADCategories) => {

  if (has4WayClassification) {
    const depressionValue = session.phq_4c;
    if (depressionValue === null || typeof depressionValue === 'undefined') {
      return 'Incomplete'
    }

    return depressionValue === 0 ? "None" :
      depressionValue === 1 ? "Mild" :
        depressionValue === 2 ? "Moderate" :
          depressionValue === 3 ? "Severe" : 'Incomplete'
  }

  if (hasPHQGADCategories) {
    const depressionValue = session.phqTotal;
    if (depressionValue === null || typeof depressionValue === 'undefined' || !session.phqStatus) {
      return 'Incomplete'
    }
    if (session.phqStatus !== 'COMPLETE') {
      return session.phq9Status.charAt(0) + session.phq9Status.substring(1).toLowerCase()
    }

    return depressionValue < 5 ? "None" :
      depressionValue < 10 ? "Mild" :
        depressionValue < 15 ? "Moderate" :
          depressionValue >= 15 ? "Severe" : 'Incomplete'
  }

  const depressionValue = session.phq_r;
  if (depressionValue === null || typeof depressionValue === 'undefined') {
    return 'Incomplete'
  }

  if (depressionValue < 0) {
    return 'Incomplete'
  }
  if (depressionValue > 24) {
    return '24'
  }

  return `${Math.ceil(depressionValue).toString()}`
}

const prepareAnxietyValue = (session, has4WayClassification, hasPHQGADCategories) => {
  if (has4WayClassification) {
    const anxietyValue = session.gad_4c;
    if (anxietyValue === null || typeof anxietyValue === 'undefined') {
      return 'Incomplete'
    }

    return anxietyValue === 0 ? "None" :
      anxietyValue === 1 ? "Mild" :
        anxietyValue === 2 ? "Moderate" :
          anxietyValue === 3 ? "Severe" : 'Incomplete'
  }

  if (hasPHQGADCategories) {
    const anxietyValue = session.gadTotal;
    if (anxietyValue === null || typeof anxietyValue === 'undefined' || !session.gadStatus) {
      return 'Incomplete'
    }
    if (session.gadStatus !== 'COMPLETE') {
      return session.gadStatus.charAt(0) + session.gadStatus.substring(1).toLowerCase()
    }

    return anxietyValue < 5 ? "None" :
      anxietyValue < 10 ? "Mild" :
        anxietyValue < 15 ? "Moderate" :
          anxietyValue >= 15 ? "Severe" : 'Incomplete'
  }

  const anxietyValue = session.gad_r;
  if (anxietyValue === null || typeof anxietyValue === 'undefined') {
    return 'Incomplete'
  }

  if (anxietyValue < 0) {
    return 'Incomplete'
  }
  if (anxietyValue > 21) {
    return '21'
  }

  return `${Math.ceil(anxietyValue).toString()}`
}
function SessionBox({
  classes,
  patient,
  session,
  role,
  timezone,
  ehResults,
  hideRecordings,
  columnsToShow,
  index,
  update,
  saving,
  loadRecordings,
  surveyNames,
  createCSV,
  recordings,
  topicQuestions
}) {
  const [comment, setComment] = React.useState(session.comment);

  // Check if the user has 4 way classification (phq_4c and gad_4c) in the session table columns
  // In that case, show FourWayAssessmentChart
  const has4WayClassification = (columnsToShow.indexOf("phq_4c") !== -1 || columnsToShow.indexOf("gad_4c") !== -1);

  // Check if the user has PHQ and GAD categories in the session table columns
  // In that case, show hasPHQGADCategories
  const hasPHQGADCategories = (columnsToShow.indexOf("phqTotalCategory") !== -1 || columnsToShow.indexOf("gadTotalCategory") !== -1);

  const exportFile = async file => {
    const surveyMap = [];
    const surveyListBetweenSessions = [];
    const dataXSession = [];
    const headersCSV1 = [
      'Patient Name',
      'MRN',
      'Date & Time',
      'PHQ-8'
    ];

    if (ehResults) {
      headersCSV1.splice(3, 0, 'EH Depression');
      headersCSV1.splice(4, 0, 'EH Anxiety');
    }

    if (session.phq9) {
      headersCSV1.push('PHQ-9', 'PHQ-9 Q:9', 'GAD-7');
    } else {
      headersCSV1.push('GAD-7');
    }

    const dataToExport = [];

    let blob = null;

    switch (file) {
      case 'CSV':
        dataXSession[0] = {
          name: `${patient.name ?? ''} ${patient.lastName ?? ''}`,
          mrn: patient.mrn,
          date_time: formatDate(session.startTimeStamp?.value, 'L LT z', timezone),
          ...(ehResults && { EH_Depression: prepareDepressionValue(session, has4WayClassification, hasPHQGADCategories), EH_Anxiety: prepareAnxietyValue(session, has4WayClassification, hasPHQGADCategories) }),
          PHQ8:
            session.phqStatus === 'COMPLETE'
              ? session.phq9 && session.phqScores?.length > 8
                ? session.phqTotal - session.phqScores[8]
                : session.phqTotal
              : session.phqStatus,
          PHQ9:
            session.phq9Status && session.phq9Status === 'COMPLETE' && session.phq9
              ? session.phqTotal
              : session.phq9Status,
          PHQQ9:
            session.phq9Status && session.phq9Status === 'COMPLETE' && session.phq9
              ? session.phqScores[8]
              : session.phq9Status,
          GAD:
            session.gadStatus === 'COMPLETE'
              ? session.gadTotal
              : session.gadStatus,
          surveysResults: []
        };

        session.custom.forEach(item => {
          if (!surveyMap[item.survey]) {
            surveyMap[item.survey] = true;
            surveyListBetweenSessions.push(item.survey);
          }

          dataXSession[0].surveysResults[item.survey] = item.score
            ? item.score
            : 'INCOMPLETE';
        });

        session.customStatus.forEach(value => {
          if (!surveyMap[value.survey]) {
            surveyMap[value.survey] = true;
            surveyListBetweenSessions.push(value.survey);
          }

          if (
            dataXSession[0].surveysResults[value.survey] &&
            value.status !== 'COMPLETE'
          ) {
            dataXSession[0].surveysResults[value.survey] = value.status;
          } else if (!dataXSession[0].surveysResults[value.survey]) {
            dataXSession[0].surveysResults[value.survey] = 'INCOMPLETE';
          }
        });

        dataXSession.forEach((d, i) => {
          dataToExport[0] = [
            `"${d.name}"`,
            `"${d.mrn}"`,
            `"${d.date_time}"`,
            `"${d.PHQ8}"`
          ];

          if (ehResults) {
            dataToExport[0].splice(3, 0, `"${d.EH_Depression}"`);
            dataToExport[0].splice(4, 0, `"${d.EH_Anxiety}"`);
          }

          if (session.phq9) {
            dataToExport[0].push(`"${d.PHQ9}"`, `"${d.PHQQ9}"`, `"${d.GAD}"`);
          } else {
            dataToExport[0].push(`"${d.GAD}"`);
          }

          surveyListBetweenSessions.forEach(s => {
            if (d.surveysResults[s]) {
              dataToExport[0].push(`"${d.surveysResults[s]}"`);
            } else {
              dataToExport[0].push(`""`);
            }
          });
        });

        surveyListBetweenSessions.forEach((s, i) => {
          if (surveyNames[s]) {
            surveyListBetweenSessions[i] = surveyNames[s];
          }
        });

        createCSV(
          dataToExport.reverse(),
          headersCSV1.concat(surveyListBetweenSessions),
          `Ellipsis_${patient.name ?? 'no_name_'}${patient.lastName ?? 'no_last_name'}_${formatDate(new Date(), 'DD_MM_YYYY_hh_mm_ss_A_z', timezone)}.csv`
        );

        break;
      case 'PDF':
        blob = await pdf(
          <AssessmentDoc
            data={[session]}
            selected={[session].map(n => n.uid)}
            config={{ 'showEHResults': ehResults, has4WayClassification, hasPHQGADCategories }}
            user={patient}
          />
        ).toBlob();

        saveAs(
          blob,
          `Ellipsis_${patient.name ?? 'no_name_'}${patient.lastName ?? 'no_last_name'}_${formatDate(new Date(), 'DD_MM_YYYY_hh_mm_ss_A_z', timezone)}.pdf`
        );
        break;
      case '':
        break;
      default:
      // code block
    }
  };

  const handleChange = event => {
    const { value } = event.target;
    setComment(value);
    update({ comment: value, session });
  };

  let hDuration = Math.floor(session.audioDuration / 60 / 60);
  let mDuration = Math.floor(session.audioDuration / 60) - hDuration;
  let sDuration = session.audioDuration % 60;
  if (hDuration < 10) {
    hDuration = `0${hDuration}`;
  }
  if (mDuration < 10) {
    mDuration = `0${mDuration}`;
  }
  if (sDuration < 10) {
    sDuration = `0${sDuration}`;
  }

  const duration = `${hDuration}:${mDuration}:${sDuration}`;

  return (
    <div id={`session-${session.uid}`} className={classes.root}>
      <div className={classes.header}>
        <div>
          <div className={classes.rowDivs}>
            <div style={{ flex: 1 }}>
              <Typography
                style={{ fontWeight: 500 }}
                color="#262626"
                variant="subtitle1"
              >
                {`Session #${index}`}
              </Typography>
            </div>
          </div>
          <div className={classes.rowDivs}>
            <div style={{ marginRight: 5 }}>
              <Typography style={{ fontWeight: 500 }} color="#262626" variant="body1">
                {`Date & Time: ${formatDate(session.startTimeStamp?.value, 'L LT z', timezone)}`}
              </Typography>
            </div>
          </div>
          <div className={classes.rowDivs}>
            <div style={{ marginRight: 5 }}>
              <Typography style={{ fontWeight: 500 }} color="#262626" variant="body1">
                {session.source ? `Patient type: ${session.source}` : <></>}
              </Typography>
            </div>
          </div>
        </div>
        {role !== 'viewer' ? (
          <div className={classes.actionDivCont}>
            <div className={classes.actionDiv}>
              <Tooltip title="Download PDF">
                <div style={{ width: '100%', height: '100%', padding: 4 }}>
                  <GrDocumentPdf
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    onClick={() => {
                      exportFile('PDF');
                    }}
                  />
                </div>
              </Tooltip>
            </div>

            <div className={classes.actionDiv}>
              <Tooltip title="Download CSV">
                <div style={{ width: '100%', height: '100%', padding: 4 }}>
                  <GrDocumentCsv
                    color='white'
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    onClick={() => {
                      exportFile('CSV');
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>


      <div style={{ flex: 1, display: 'flex' }}>

        <SessionScores
          classes={classes}
          session={session}
          has4WayClassification={has4WayClassification}
          hasPHQGADCategories={hasPHQGADCategories}
        />
        
        <SurveyResults
          classes={classes}
          session={session}
          surveyNames={surveyNames}
        />

        <div style={{ height: 5 }} />

        <div className={classes.columnDivs}>
          <div style={{ width: 200, marginRight: 20 }}>
            <Typography style={{ fontWeight: 600 }} color="#262626" variant="body1">
              Notes
            </Typography>
          </div>
          <TextField
            disabled={saving}
            style={{ flex: 1, display: 'flex', height: 150, border: '1px solid', borderRadius: '5px', backgroundColor: '#FFFFFF' }}
            multiline
            rows={6}
            variant="outlined"
            onChange={handleChange}
            value={comment && comment !== 'null' ? comment : ''}
          />
        </div>
      </div>


      {hideRecordings ? <></> : loadRecordings ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <div className={classes.columnDivs}>
            <div style={{ width: 150, marginRight: 20 }}>
              <Typography style={{ fontWeight: 600 }} color="#262626" variant="body1">
                Journal
              </Typography>
            </div>
          </div>
          <SessionJournal
            className={classes.sessionJournal}
            session={session}
            recordings={recordings}
            topicQuestions={topicQuestions}
            partner={session.partnerId}
          />
        </>
      )}

    </div>
  );
}

SessionBox.propTypes = {
  index: PropTypes.number,
  session: PropTypes.object,
  timezone: PropTypes.string,
  classes: PropTypes.object,
  update: PropTypes.func,
  saving: PropTypes.bool
};

const enhance = withStyles(styles);

export default enhance(SessionBox);
