/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

class EHStreamViz extends Component {
  constructor(props) {
    super(props);

    const series = [];
    const yaxis = [];
    series.push({
      name: props.data[0].name,
      data: [props.data[0].value]
    });
    yaxis.push({ seriesName: props.data[0].name });

    series.push({
      name: props.data[1].name,
      data: [props.data[1].value]
    });
    yaxis.push({ seriesName: props.data[1].name, opposite: true });

    series.push({
      name: props.data[2].name,
      data: [props.data[2].value]
    });
    yaxis.push({ seriesName: props.data[2].name, show: false, opposite: true });

    this.state = {
      options: {
        chart: {
          id: `eh-stream-data-chart-${props.sessionId}`,
          toolbar: 'false',
          legend: {
            showForSingleSeries: true
          }
        },
        xaxis: {
          categories: [0],
          labels: {
            show: false
          },
          title: {
            text: 'sec',
            offsetX: 140,
            style: {
              fontWeight: 400
            }
          }
        },
        yaxis
      },
      series,
      seconds: 0,
      data: props.data,
      paused: props.paused
    };

    this.numVars = props.numVars;
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    let changed = false;
    for (let i = 0; i < this.numVars; i+=1) {
      if (
        nextProps.data[i] &&
        this.state.data[i] &&
        nextProps.data[i].value !== this.state.data[i].value
      )
        changed = true;
    }
    if (changed) this.setState({ data: nextProps.data });

    if (nextProps.paused !== this.state.paused) {
      this.setState({ paused: nextProps.paused });
    }
  }

  startTimer() {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    if (this.state.paused) return;

    // add one second, set state so a re-render happens.
    const seconds = this.state.seconds + 1;
    const newSeries = this.state.series;
    const newCategories = this.state.options.xaxis.categories;

    for (let i = 0; i < this.numVars; i+=1) {
      newSeries[i].data.push(this.state.data[i].value);
    }
    newCategories.push(seconds);

    // set state does not update apex chart
    this.setState({
      seconds,
      series: newSeries,
      options: {
        ...this.state.options,
        xaxis: {
          ...this.state.options.xaxis,
          categories: newCategories, // this is how we add a x-value
          title: {
            text: `${seconds  } sec`, // this is where we update the x-axis label
            offsetX: 140,
            style: {
              fontWeight: 400
            }
          }
        }
      }
    });

    // this is to ask apexchart to update itself
    ApexCharts.exec('basic-line', 'updateOptions', this.state.options);
    ApexCharts.exec('basic-line', 'updateSeries', this.state.series);
  }

  render() {
    return (
      <div className="EHStreamViz">
        <Chart
          options={this.state.options}
          toolbar={{ show: false }}
          series={this.state.series}
          type="line"
          width="100%"
          height="300"
        />
      </div>
    );
  }
}

export default EHStreamViz;
