/* 
    This file contains string literals for your action types. 
    This provides an easy reference for the actions available. 
    These strings are exported as an object literal which can then be 
    imported into your reducers and action creators instead of hard-coding them. 
    Although maintaining a separate file containing just your action types is 
    optional, it is highly recommended.
*/

const REQUEST_ALERTS_JSON = 'REQUEST_ALERTS_JSON';
const RECEIVE_ALERTS_JSON = 'RECEIVE_ALERTS_JSON';

const REQUEST_ACK = 'REQUEST_ACK';
const RECEIVE_ACK = 'RECEIVE_ACK';

const RECEIVE_ACK_SUCCESS = 'RECEIVE_ACK_SUCCESS';

export default {
  REQUEST_ALERTS_JSON,
  RECEIVE_ALERTS_JSON,
  REQUEST_ACK,
  RECEIVE_ACK,
  RECEIVE_ACK_SUCCESS
};
