import React from 'react';
import { Typography } from '@material-ui/core';

const SurveyName = ({ children }) => (
  <Typography style={{ fontWeight: 600, marginLeft: 8 }} color="#262626" variant="body1">
    {children}
  </Typography>
);

const SurveyScore = ({ children }) => (
  <Typography style={{ fontWeight: 500, marginLeft: 20 }} color="#262626" variant="subtitle1">
    {children}
  </Typography>
);

const SurveyResults = ({ classes, session, surveyNames }) => (
  <div className={classes.columnDivs}>
    <div style={{ width: 150, marginRight: 20 }}>
      <Typography style={{ fontWeight: 600 }} color="#262626" variant="body1">
        Survey Results
      </Typography>
    </div>

    <div
      style={{
        width: '100%',
        height: 150,
        border: '1px solid',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        display: 'grid',
        gridTemplateColumn: '1fr 1fr',
        overflowY: 'auto',
      }}
    >
      {session.phqStatus !== 'OFF' && (
        <div className={classes.rowDivs}>
          <SurveyName>PHQ-8:</SurveyName>
          <SurveyScore>
            {session.phqStatus === 'COMPLETE' && session.phqScores[8]
              ? `${session.phqTotal - session.phqScores[8]}`
              : session.phqStatus === 'COMPLETE'
              ? `${session.phqTotal}`
              : 'Incomplete'}
          </SurveyScore>
        </div>
      )}

      {session.phqStatus !== 'OFF' && session.phq9 ? (
        <>
          <div className={classes.rowDivs}>
            <SurveyName>PHQ-9:</SurveyName>
            <SurveyScore>
              {session.phq9Status === 'COMPLETE'
                ? `${session.phqTotal}`
                : 'Incomplete'}
            </SurveyScore>
          </div>

          <div className={classes.rowDivs}>
            <SurveyName>PHQ-9 Q:9:</SurveyName>
            <SurveyScore>
              {session.phq9Status === 'COMPLETE'
                ? session.phqScores[8]
                : 'Incomplete'}
            </SurveyScore>
          </div>
        </>
      ) : (
        ''
      )}

      {session.gadStatus !== 'OFF' ? (
        <div className={classes.rowDivs}>
          <SurveyName>GAD-7:</SurveyName>
          <SurveyScore>
            {session.gadStatus === 'COMPLETE'
              ? `${session.gadTotal}`
              : 'Incomplete'}
          </SurveyScore>
        </div>
      ) : (
        ''
      )}

      {session.customStatus.map(s => (
        <div className={classes.rowDivs}>
          <SurveyName>
            {surveyNames[s.survey]
              ? `${surveyNames[s.survey]}:`
              : `${s.survey}:`}
          </SurveyName>
          <SurveyScore>
            {s.status === 'INCOMPLETE'
              ? 'Incomplete'
              : session.custom
                  .filter(e => e.survey === s.survey)
                  .map(value => `${value.score}`)}
          </SurveyScore>
        </div>
      ))}

    </div>
  </div>
);

export default SurveyResults;
