import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Select, MenuItem, Box, makeStyles } from '@material-ui/core';

import LiveCallsComponent from './LiveCallsComponent';

const mapStateToProps = state => {
  return {
    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login,
    portalConfig: state.login.portalConfig
  };
};

const useStyles = makeStyles({
  root: {
    padding: '20px'
  },
  select: {
    marginBottom: '32px'
  }
});

const LiveCallsContainer = props => {
  const [value, setValue] = useState('live');
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Select
        value={value}
        onChange={e => setValue(e.target.value)}
        className={classes.select}
      >
        <MenuItem value="live">Live Calls</MenuItem>
        <MenuItem value="recent">Recent Calls</MenuItem>
      </Select>
      <LiveCallsComponent {...props} forLive={value === 'live'} />
    </Box>
  );
};

export default connect(mapStateToProps)(LiveCallsContainer);
