/*

This is where you define your action creators. 
All action creators must be functions that return an object 
with at least the type property. 
We do not define any async logic in this file.

*/

// eslint-disable-next-line import/extensions
import types from './types.js';

const requestPatientsJson = () => ({
  type: types.REQUEST_PATIENTS_JSON
});

const receivePatientsJson = (
  patientsCount,
  patientsData,
  sort,
  sortOrder,
  limit,
  offset,
  filters
) => ({
  type: types.RECEIVE_PATIENTS_JSON,
  patientsCount,
  patientsData,
  sort,
  sortOrder,
  rowsPerPage: limit,
  offset,
  filters
});

const setTotalPatients = () => ({
  type: types.SET_TOTAL_PATIENTS
});

export { requestPatientsJson, receivePatientsJson, setTotalPatients };
