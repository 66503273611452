/*

This is where you define your action creators. 
All action creators must be functions that return an object 
with at least the type property. 
We do not define any async logic in this file.

*/

import types from './types';

const requestAlertsJson = () => ({
  type: types.REQUEST_ALERTS_JSON
});

const receiveAlertsJson = (alertsData, patientsMap) => ({
  type: types.RECEIVE_ALERTS_JSON,
  alertsData,
  patientsMap
});

const requestACK = () => ({
  type: types.REQUEST_ACK
});

const receiveACK = () => ({
  type: types.RECEIVE_ACK
});

const receiveACKSuccess = (ackDetails) => ({
  type: types.RECEIVE_ACK_SUCCESS,
  ackDetails
});

export { requestAlertsJson, receiveAlertsJson, requestACK, receiveACK, receiveACKSuccess };
