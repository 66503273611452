import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Link,
  Typography,
  CircularProgress
} from '@material-ui/core';
import logo from '../../../../assets/logo.png';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  progress: {
    margin: theme.spacing(2)
  },
  button: {
    backgroundColor: theme.palette.primary.main_two,
    color: '#FFFFFF',
    '&:hover': {
      background: theme.palette.primary.main_two
    }
  }
});

function Login(props) {
  const { classes, history, spinner, submit, next } = props;
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  return (
    <>
      <img alt="" src={logo} height="100" />
      <form
        className={classes.form} 
        onSubmit={(e) => submit(e, email, password)}
      >
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <Input
            value={email}
            id="email"
            name="email"
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
            autoFocus
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            value={password}
            name="password"
            type="password"
            id="password"
            onChange={e => setPassword(e.target.value)}
            autoComplete="current-password"
          />
        </FormControl>

        <Typography
          style={{ marginTop: 10, fontSize: 12 }}
          align="right"
          variant="body1"
        >
          <Link
            style={{cursor: 'pointer'}}
            onClick={() => {
              history.push('/__/auth/reset/2factauth');
            }}
            color="primary"
          >
            Forgot password?
          </Link>
        </Typography>

        {spinner ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>

            <Typography style={{margin: 10}} align="center" variant="body1">
              OR
            </Typography>

            <Button
              className={classes.button}
              fullWidth
              variant="contained"
              // className={classes.submit}
              onClick={() => {
                next(2);
              }}
            >
              Login with one time code
            </Button>
          </>
        )}
      </form>
    </>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func,
  spinner: PropTypes.bool,
  history: PropTypes.object,
  next: PropTypes.func,
};

export default withStyles(styles)(Login);
