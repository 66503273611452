import { connect } from 'react-redux';
import AlertComponent from './AlertComponent';
import { alertsOperations } from './redux'

const mapStateToProps = state => {
  const { portalConfig } = state.login;
  const { updateAck, patientsMap } = state.alert;
  console.log(patientsMap)
  return {
    alertState: state.alert,
    loginState: state.login,
    portalConfig,
    updateAck
  };
};

const mapDispatchToProps = dispatch => {
  const getAlertsData = (partnerList, dateFrom) =>
    dispatch(alertsOperations.getAlertsJson(partnerList, dateFrom));

  const acknowledgeAlert = (data) =>
    dispatch(alertsOperations.acknowledgeAlert(data));

  return {
    getAlertsData,
    acknowledgeAlert
  };
};

const AlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertComponent);

export default AlertContainer;
