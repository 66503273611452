import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import queryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SessionTable from '../common/table/table';
import MiddleMainPage from './components/MiddleMainPage';
import TopMainPage from './components/TopMainPage';
import Dialog from '../common/dialog/dialog';
import SurveyResultDialog from '../common/dialog/surveyResultDialog';
import CustomSurveyResultDialog from '../common/dialog/customSurveyResultDialog';
import BottomDisclaimerPage from '../common/BottomDisclaimerPage';

import FollowedBySelect from '../common/form/FollowedBySelect';

const styles = theme => ({
  root: {
    padding: '10px 20px 20px 20px',
    [theme.breakpoints.down(1000)]: {
      padding: '5px 10px 10px 10px'
    }
  },
  cont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1000)]: {
      alignItems: 'center'
    }
  },
  control: {
    width: '100%',
    height: isMobile ? '60px' : '40px',
    backgroundColor: theme.palette.special.special_two,
    display: 'flex',
  }
});

class SessionsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex:
        queryString.parse(document.location.search).v === 'new' ? 1 : 0,
      dialog: {
        value: '',
        id: '',
      }
    };
  }

  componentDidMount() {
    const { homeState, portalConfig } = this.props;

    const page = sessionStorage.getItem('page');
    sessionStorage.removeItem('page');

    if (!queryString.parse(document.location.search).v && page !== 'patient') {
      this.saveConfiguration({
        filters: []
      });
      return;
    }

    if (page !== 'patient' && page !== 'dashboard') {
      // IF the previous page was patients details or dashboard --> not reloading the sessions
      const data = {
        sort: homeState.sort,
        sortOrder: homeState.sortOrder,
        rowsPerPage: homeState.rowsPerPage,
        offset: homeState.offset,
        filters: portalConfig.sessionsFilters
      };
      this.onloadSessions(data);
      return;
    }

    if (queryString.parse(document.location.search).v === 'new') {
      // history.push('/sessions?page=1');

      // If params of URL has value v=new , load new sessions
      this.saveConfiguration({
        filters: [
          {
            field: 'startTimeStamp',
            operator: '>=',
            value: `${moment
              .utc(portalConfig.lastLogin, 'x')
              .format('YYYY-MM-DDTHH:mm:ss')}`,
            type: 'Date'
          }
        ]
      });
    } else if (
      queryString.parse(document.location.search).v === 'all' &&
      portalConfig.sessionsFilters.length > 0
    ) {
      // history.push('/sessions?page=1');

      this.saveConfiguration({
        filters: []
      });
    }
  }

  onloadSessions = data => {
    const { portalConfig, getSessionData, homeState } = this.props;
    const { filters } = homeState;

    const partnerList = portalConfig.portalActivePartners;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;

    /**
     * Call the method that returns session data.
     * For the last element (filters), combine all the filters passed to the method with the followedBy clinician.
     * [...filters, ...data.filters] merges the 2 arrays without changing the props.
     */
    getSessionData(
      lastLogin,
      partnerList,
      data.sort,
      data.sortOrder,
      data.rowsPerPage,
      data.offset,
      [...filters, ...data.filters]
    );
  };

  saveConfiguration = data => {
    const { loginState, saveFilter } = this.props;
    const { homeState } = this.props;

    data.email = loginState.user.email;
    if (data.filters) {
      data.sessionsFilters = data.filters;
      delete data.filters;
    }

    saveFilter(data);
    this.onloadSessions({
      sort: homeState.sort,
      sortOrder: homeState.sortOrder,
      rowsPerPage: homeState.rowsPerPage,
      offset: homeState.offset,
      filters: data.sessionsFilters
    });
  };

  downloadSpecificCsv = data => {
    const { portalConfig, downloadCsv } = this.props;
    const { columnsName, showSessionsColumns } = portalConfig;

    data.showColumns = showSessionsColumns;
    data.columnsName = columnsName;

    // send also the role of the clinician and configurations
    const { configurations, role } = portalConfig;
    const config = { configurations, role };
    data.config = config;
    //-----------------------------------
    downloadCsv(data);
  };

  changeFollowedBy = value => {
    /**
     * CHANGE OF THE FOLLOWEDBY CONTROL
     * When the followedBy field is changed, the followed action are performed here:
     * 1- SetFollowedBy method called to set the new email
     * 2- Load again data based on the new filter
     */
    const {
      getPatientsData,
      getSessionData,
      onboardState,
      homeState,
      portalConfig,
      setFollowedby,
      setTotalPatients
    } = this.props;

    const partnerList = portalConfig.portalActivePartners;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;
    let filters = [];
    if (value) {
      filters = [
        {
          field: 'assignedAdminId',
          operator: '=',
          value: `${value}`,
          type: 'String'
        }
      ];
    }
    setFollowedby(value);
    setTotalPatients();

    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      [...filters, ...portalConfig.sessionsFilters] // Merge arrays without changing props
      // filters
    );
  };

  createCSV = (data, headers, fileName) => {
    const csvRows = [];
    // get the temp headers (not user friendly, these are the columns name of the db)
    csvRows.push(headers.join('\t'));

    // loop over the rows
    // eslint-disable-next-line no-restricted-syntax
    for (const row of data) {
      // form escaped comma separated values
      csvRows.push(row.join('\t'));
    }

    // Create a blob that is needed to download data
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' }); // csvRows.join('\n') go to next line with every row
    // Send the blob to the browser
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  openDialog = () => {
    const { dialog } = this.state;
    const {
      portalConfig
    } = this.props;
    const {
      columnsName
    } = portalConfig;

    switch (dialog.id) {
      case 'phq9TotalClickable':
        return (
          <Dialog maxWidth="lg" close={() => this.setState(prevState => ({ ...prevState, dialog: { value: '', id: '', } }))}>
            <SurveyResultDialog
              close={() => this.setState(prevState => ({ ...prevState, dialog: { value: '', id: '', } }))}
              scores={dialog.value.phqScores ?? []}
              total={dialog.value.phqTotal ?? ''}
              session={dialog.value}
              survey="PHQ"
              createCSV={this.createCSV}
            />
          </Dialog>
        );

      case 'gadTotalClickable':
        return (
          <Dialog maxWidth="lg" close={() => this.setState(prevState => ({ ...prevState, dialog: { value: '', id: '', } }))}>
            <SurveyResultDialog
              close={() => this.setState(prevState => ({ ...prevState, dialog: { value: '', id: '', } }))}
              scores={dialog.value.gadScores ?? []}
              total={dialog.value.gadTotal ?? ''}
              session={dialog.value}
              survey="GAD"
              createCSV={this.createCSV}
            />
          </Dialog>
        );

      case 'reviewOfSystems':
        return (
          <Dialog maxWidth="lg" close={() => this.setState(prevState => ({ ...prevState, dialog: { value: '', id: '', } }))}>
            <CustomSurveyResultDialog
              close={() => this.setState(prevState => ({ ...prevState, dialog: { value: '', id: '', } }))}
              title={columnsName && columnsName[dialog.id] ? columnsName[dialog.id] : dialog.id}
              session={dialog.value}
              createCSV={this.createCSV}
            />
          </Dialog>
        );

      default:
        break
    }
  };

  render() {
    const {
      classes,
      homeState,
      loginState,
      portalConfig,
      saveLogs,
      history
    } = this.props;
    const {
      sessionsFilters,
      settings,
      showSessionsColumns,
      hideSessionsColumns,
      columnsName,
      configurations,
      showEHResults,
      role,
      lastLogin
    } = portalConfig;
    const config = { configurations, role, showEHResults };
    const { selectedIndex, dialog } = this.state;

    return (
      <>
        <div className={classes.control}>
          <Typography
            style={{
              margin: 'auto',
              marginLeft: 8,
              display: 'flex',
              color: '#000000'
            }}
          >
            Show patients followed by: &nbsp;
            <FollowedBySelect
              partner={portalConfig.portalActivePartners[0]}
              user={loginState}
              followedby={
                homeState.filters.length > 0 ? homeState.filters[0].value : null
              }
              onChange={value => this.changeFollowedBy(value)}
              loadingData={homeState.showSpinner}
            />
          </Typography>
        </div>
        <div className={classes.root}>
          <div className={classes.cont}>
            <TopMainPage
              lastRefresh={homeState.lastRefresh}
              count={homeState.totalSessions}
              lastSession={homeState.lastSession}
              filters={sessionsFilters}
              newSessionsNumber={homeState.newSessionsNumber}
              config={config}
            />
            {config.role !== 'viewer' ? (
              <MiddleMainPage
                filterValue={selectedIndex}
                lastLogin={lastLogin || new Date().getTime()}
                saveConfiguration={this.saveConfiguration}
                activeFilters={sessionsFilters}
                downloadCsv={() => this.downloadSpecificCsv({
                  data: homeState.sessionData
                })}
                download={homeState.download}
                showSpinner={homeState.showSpinner}
              />
            ) : (
              ''
            )}
          </div>
          <SessionTable
            config={config}
            lastLogin={lastLogin || new Date().getTime()}
            loadData={this.onloadSessions}
            sort={homeState.sort}
            sortOrder={homeState.sortOrder}
            rowsPerPage={homeState.rowsPerPage}
            offset={homeState.offset}
            filters={sessionsFilters}
            settings={settings}
            data={homeState.sessionData}
            dataCount={homeState.sessionCount}
            showSpinner={homeState.showSpinner}
            showColumns={showSessionsColumns}
            hideColumns={hideSessionsColumns}
            columnsName={columnsName}
            saveConfiguration={this.saveConfiguration}
            saveConfigCompleted={loginState.saveConfigCompleted}
            saveLogs={saveLogs}
            downloadCsv={this.downloadSpecificCsv}
            download={homeState.download}
            openCellDialog={(val, id) => this.setState(prevState => ({ ...prevState, dialog: { id, value: val } }))}
            history={history}
          />
          <BottomDisclaimerPage />
          {dialog.id ? this.openDialog() : ''}
        </div>
      </>
    );
  }
}

SessionsComponent.propTypes = {
  classes: PropTypes.object,
  homeState: PropTypes.object,
  loginState: PropTypes.object,
  onboardState: PropTypes.object,
  portalConfig: PropTypes.object,
  getSessionData: PropTypes.func,
  getPatientsData: PropTypes.func,
  setFollowedby: PropTypes.func,
  setTotalPatients: PropTypes.func,
  history: PropTypes.object
};

export default withStyles(styles)(SessionsComponent);
