export default ({ palette }) => ({
  overline: {
    fontSize: '0.75rem',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    color: palette.text.secondary,
  },
  heading: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    lineHeight: 2,
  },
  body: {
    color: 'rgba(0, 0, 0, 0.7)',
  },
  infoTitle: {
    display: 'flex',
    marginBottom: 15
  },
  infoLine: {
    display: 'flex',
    marginBottom: 2
  },
  infoLineColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 2
  },
  userTriggerLine: {
    display: 'flex',
    alignItems: 'baseline',
    margin: 10
  },
  expandButton: {
    marginRight: 5,
    height: 20,
    width: 20,
    border: '1px solid black',
    borderColor: '#000000',
    textAlign: 'center',
    alignItems: 'center'
  },
  trigger: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    padding: 10,
    border: '1px solid black',
    borderColor: '#000000',
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
});