import React, { useEffect } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Button,
  Typography,
  LinearProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SurveyAnswerRow from '../../../components/SurveyAnswerRow';
import notifier from '../utils/notifier';
import { getAllCustomSurveys } from '../allCustomSurveys/getAllCustomSurveys';
import SurveyResultDoc from '../reports/pdf/survey_result';

const styles = () => ({});

function CustomSurveyResultDialog({ session, createCSV, title, close }) {
  const [loading, setLoad] = React.useState(false);
  const [surveyList, setSurvey] = React.useState([]);

  useEffect(() => {
    // Loop through the surveys and add them to array
    const loadSurveys = async () => {
      setLoad(true);
      try {
        // Get all custom surveys completed during the session
        const customSurvey = session.custom ?? [];
        const surveyNames = [];
        const surveyScores = [];
        // For each survey, create an array with names and a map of objects to easily access the scores
        customSurvey.forEach(c => {
          if (c.survey && c.survey !== 'MSHPQ') {
            surveyNames.push(c.survey);
            surveyScores[c.survey] = {
              scores: c.scores ?? [],
              score: c.score ?? 'n/a'
            };
          }
        });

        // Now add the standard surveys PHQ and GAD (Only if completed)
        if (
          session.phqStatus &&
          session.phqStatus !== 'OFF' // &&
          // session.phqStatus !== 'INCOMPLETE'
        ) {
          surveyNames.push('PHQ');
          surveyScores.PHQ = {
            scores: session.phqScores ?? [],
            score: session.phqTotal ?? 'n/a',
            tableName: session.phq9 ? `Survey: PHQ-9 - Total Score: ${session.phqTotal ?? 'n/a'}` : `Survey: PHQ - Total Score: ${session.phqTotal ?? 'n/a'}`
          };
        }
        if (
          session.gadStatus &&
          session.gadStatus !== 'OFF' // &&
          // session.gadStatus !== 'INCOMPLETE'
        ) {
          surveyNames.push('GAD');
          surveyScores.GAD = {
            scores: session.gadScores ?? [],
            score: session.gadTotal ?? 'n/a',
            tableName: `Survey: GAD-7 - Total Score: ${session.gadTotal ?? 'n/a'}`
          };
        }
        // -----
        // Get all custom surveys info
        const resp = await getAllCustomSurveys(surveyNames);
        const surveyListXPartner = [];
        // For every survey, prepare an array with all the info needed on render.
        surveyNames.forEach(sname => {
          const scores = surveyScores[sname];
          const value = {
            name: sname,
            total: scores.score,
            tableName: scores.tableName ? scores.tableName : `Survey: ${sname}`,
            questionRows: []
          };
          const { questions } = resp[sname];
          // Order the questions based on 'order' field
          const orderedQuestions = sortBy(questions, 'order');

          /**
           * For each question, check the score and get the correct answer from the array of answers
           * N.B PHQ and GAD answers count starts from 0, while for all other custom survey it starts from 1.
           * This is a problem of configuration in the Firestore db
           */
          let i = 0;
          orderedQuestions.forEach(q => {
            value.questionRows.push({
              id: i + 1,
              question: q.prompt,
              answer:
                scores.scores[i] || scores.scores[i] === 0
                  ? sname === 'PHQ' || sname === 'GAD'
                    ? q.answers[scores.scores[i] + 1]
                    : q.answers[scores.scores[i]]
                  : 'n/a',
              value: scores.scores[i]
            });
            i += 1;
          });
          surveyListXPartner.push(value);
        });

        setSurvey(surveyListXPartner);
      } catch (e) {
        // silence error
        console.error(e);
        notifier.error(
          'Sorry, something went wrong. Please, try again later or contact support@ellipsishealth.com'
        );
      }
      setLoad(false);
    };

    loadSurveys();
  }, []);

  const prepareCSV = () => {
    // Prepare a combined CSV with empty lines divisions
    const dataToExport = [];
    let i = 0;
    surveyList.forEach((survey) => {
      dataToExport[i] = ["","","",""]
      i+=1;
      dataToExport[i] = ["",`"${survey.tableName}"`,"",""]
      i+=1;
      survey.questionRows.forEach((data) => {
        dataToExport[i] = [
          `"${data.id}"`,
          `"${data.question}"`,
          `"${data.answer}"`,
          `"${data.value ?? ''}"`
        ];
        i+=1;
      });
    })
    

    const headersCSV = ['Question #', 'Question', 'Answer', 'Value'];

    createCSV(
      dataToExport,
      headersCSV,
      `Ellipsis_${title.replace(/\s+/g, '')}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
    );
  };

  const preparePDF = async () => {
    const blob = await pdf(
      <SurveyResultDoc
        surveys={surveyList}
        session={session}
      />
    ).toBlob();

    saveAs(
      blob,
      `Ellipsis_SurveyResult_${title.replace(/\s+/g, '')}_${session.name ??
        ''}${session.lastName ?? ''}_${moment(new Date()).format(
        'YYYY-MM-DD'
      )}.pdf`
    );
  };

  return (
    <div>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? (
          <LinearProgress style={{ width: '100%' }} color="primary" />
        ) : (
          ''
        )}
        {surveyList.map(s => (
          <div key={s.name} style={{marginBottom: 10}}>
            <Typography variant="h6">{s.tableName}</Typography>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                marginTop: 10
              }}
            >
              {!loading ? (
                <>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">QUESTION #</Typography>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">QUESTION</Typography>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }} />
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">ANSWER</Typography>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">SCORE</Typography>
                  </div>
                </>
              ) : (
                <LinearProgress style={{ width: '100%' }} color="primary" />
              )}
            </div>
            <Divider />
            {s.questionRows.map(obj => (
              <SurveyAnswerRow
                key={obj.id}
                number={obj.id}
                question={obj.question}
                answer={obj.answer}
                value={obj.value}
              />
            ))}
            <Divider />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        {surveyList.length > 0 ?(
          <>
            <Button onClick={prepareCSV} variant="contained" color="secondary">
              Download CSV
            </Button>
            <Button onClick={preparePDF} variant="contained" color="secondary">
              Download PDF
            </Button>
          </>
        ) : (
          ''
        )}
        <Button onClick={close} color="primary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}

CustomSurveyResultDialog.propTypes = {
  close: PropTypes.func
};

export default withStyles(styles)(CustomSurveyResultDialog);
