import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import NotificationCardHeader from './NotificationCardHeader';
import NotificationTextInfoContent from './NotificationTextInfoContent';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderRadius: 20
  },
  content: {
    padding: 15
  },
  textContent: {
    overline: {
      textTransform: 'uppercase',
      color: '#9e9e9e',
      marginBottom: '0.35em',
      fontWeight: 'bold',
      letterSpacing: '2px',
      fontSize: 16,
      display: 'inline-block'
    },
    heading: {
      fontSize: 24,
      fontWeight: 900,
      marginBottom: '0.72em'
    },
    body: {
      fontSize: 16,
      color: theme.palette.primary.main,
      lineHeight: 1.75
    }
  },
  buttonDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20
  }
}));

export const NotificationAlertCard = React.memo(function NotificationAlertCard(props) {
  const cardStyles = useStyles();
  const { data, clinicians, patientsMap, config } = props;

  return (
    <Card className={cardStyles.root}>
      <NotificationCardHeader
        alert={data.alertId}
        seen={data.alertSeen}
        clinicians={clinicians}
        config={config}
      />
      <CardContent className={cardStyles.content}>
        <NotificationTextInfoContent className={cardStyles.textContent} data={data} patientsMap={patientsMap} config={config} />
      </CardContent>
    </Card>
  );
});

NotificationAlertCard.propTypes = {
  data: PropTypes.object,
  clinicians: PropTypes.object,
  patientsMap: PropTypes.object,
  config: PropTypes.object
};

export default NotificationAlertCard;
