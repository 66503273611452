/*

This is where you define your action creators. 
All action creators must be functions that return an object 
with at least the type property. 
We do not define any async logic in this file.

*/

// eslint-disable-next-line import/extensions
import types from './types.js';

const requestCreateNewUser = () => ({
    type: types.REQUEST_CREATE_NEW_USER
});

const receiveNewUser = (snackbar) => ({
    type: types.RECEIVE_NEW_USER,
    snackbar
});

const editPortalUser = () => ({
    type: types.EDIT_PORTAL_USER
});

const receiveEditPortalUser = (snackbar) => ({
    type: types.RECEIVE_EDIT_PORTAL_USER,
    snackbar
});

const requestUserList = () => ({
    type: types.REQUEST_USER_LIST
});

const receiveUserList = (userList, phq9AlertActive, crisisMembersMap, snackbar) => ({
    type: types.RECEIVE_USER_LIST,
    userList,
    phq9AlertActive, 
    crisisMembersMap,
    snackbar
});

const requestSendInvite = () => ({
    type: types.REQUEST_SEND_INVITE
});

const inviteSent = (snackbar) => ({
    type: types.INVITE_SENT,
    snackbar
});

const requestEditDisable = () => ({
    type: types.REQUEST_EDIT_DISABLE
});

const DisableEdited = (snackbar) => ({
    type: types.DISABLE_EDITED,
    snackbar
});

export {
    requestCreateNewUser,
    receiveNewUser,
    editPortalUser,
    receiveEditPortalUser,
    requestUserList,
    receiveUserList,
    requestSendInvite,
    inviteSent,
    requestEditDisable,
    DisableEdited,
}