import { connect } from 'react-redux';
import PatientComponent from './PatientComponent';
import { patientOperations, patientActions } from './redux';
import { homeOperations, homeActions } from '../home/redux';
// import { loginOperations } from '../auth/login/redux';
import { onboardOperations, onboardActions } from '../onboard/redux';

const mapStateToProps = state => {
  const {
    user,
    portalConfig,
  } = state.login;
  const { download } = state.home;
  const {
    savingSessionInfo,
    loadRecordings,
    recordings,
    savingText,
    topicQuestions,
  } = state.patient;

  return {
    patientState: state.patient,
    user,
    portalConfig,
    download,
    savingSessionInfo,
    savingText,
    loadRecordings,
    recordings,
    topicQuestions,

    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login,
  };
};

const mapDispatchToProps = dispatch => {
  const getUserDetails = (
    userId,
    partnerList,
    sort,
    sortOrder,
    filters,
    sortRecordings,
    sortOrderRecordings,
    recordingFilters,
  ) =>
    dispatch(
      patientOperations.getUserDetails(
        userId,
        partnerList,
        sort,
        sortOrder,
        filters,
        sortRecordings,
        sortOrderRecordings,
        recordingFilters,
      )
    );

  const getSessionData = (
    lastLogin,
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters,
  ) =>
    dispatch(
      homeOperations.getSessionJson(
        lastLogin,
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters,
      )
    );

  const getPatientsData = (
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters,
  ) =>
    dispatch(
      onboardOperations.getPatientsJson(
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters,
      )
    );

  const downloadCsv = data => dispatch(homeOperations.downloadCsv(data));
  const saveSessionInfo = data =>
    dispatch(patientOperations.saveSessionInfo(data));
  const updateUserDetails = data =>
    dispatch(patientActions.updateUserDetail(data));

  const setFollowedby = value => dispatch(homeActions.setFollowedby(value));
  const setTotalPatients = () => dispatch(onboardActions.setTotalPatients());

  const getTopicQuestions = () => dispatch(patientOperations.getTopicQuestions());

  return {
    getUserDetails,
    getSessionData,
    getPatientsData,
    setFollowedby,
    setTotalPatients,
    downloadCsv,
    updateUserDetails,
    saveSessionInfo,
    getTopicQuestions
  };
};

const PatientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientComponent);

export default PatientContainer;
