import React from 'react';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';

Font.register({ 
  family: 'Roboto', 
  src: '/font/Roboto-Bold.ttf', 
  fontWeight: 'bold',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  sectionOne: {
    margin: 30,
    padding: 30,
    flexGrow: 1
  },
  sectionTwo: {
    marginTop: 40
  },
  sectionThree: {},
  header: {
    fontSize: 10
  },
  value: {
    fontSize: 10,
    marginLeft: 4,
    color: '#0277bd'
  },
  details: {
    flexDirection: 'row'
  },
  line: {
    height: 1,
    backgroundColor: '#404040'
  }
});

// Create Document Component
class SurveyResult extends React.Component {
  render() {
    const { surveys, session } = this.props;

    return (
      <Document>
        {surveys.map(survey => (
          <Page key={survey.name} size="A4" style={styles.page}>
            <View style={styles.sectionOne}>
              <Text style={{ marginBottom: 30 }}>{survey.tableName}</Text>

              <View style={styles.details}>
                <Text style={styles.header}>MRN: </Text>
                <Text style={styles.value}>{session.mrn ?? ''}</Text>
              </View>
              <View style={styles.details}>
                <Text style={styles.header}>Year of Birth: </Text>
                <Text style={styles.value}>{session.birthyear ?? ''}</Text>
              </View>
              <View style={styles.details}>
                <Text style={styles.header}>Patient: </Text>
                <Text style={styles.value}>
                  {`${session.name ?? ''} ${session.lastName ?? ''}`}
                </Text>
              </View>
              {/* <View style={styles.details}>
                <Text style={styles.header}>Ordered by: </Text>
                <Text style={styles.value}>
                  {this.props.clinicianDisplayName || this.props.clinician}
                </Text>
              </View> */}
              <View style={styles.details}>
                <Text
                  style={styles.header}
                >Survey Date: 
                </Text>
                <Text style={styles.value}>
                  {session.endTimeStamp !== null
                    ? `${new Date(session.startTimeStamp.value).getMonth() +
                        1}/${new Date(
                        session.startTimeStamp.value
                      ).getDate()}/${new Date(
                        session.startTimeStamp.value
                      ).getFullYear()}`
                    : ''}
                </Text>
              </View>

              <View style={styles.sectionTwo}>
                <Text style={{ fontSize: 10, margin: 8 }}>Results</Text>
                <View style={styles.line} />
              </View>

              <View style={styles.sectionThree}>
                <View
                  style={{
                    height: 30,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      height: '100%',
                      width: '10%',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: 'Roboto',
                        fontWeight: 800,
                        width: '50%',
                        textAlign: 'left',
                        margin: 8
                      }}
                    >
                      #
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: 'Roboto',
                        fontWeight: 800,
                        width: '50%',
                        textAlign: 'left',
                        margin: 8
                      }}
                    >
                      Question
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: 'Roboto',
                        fontWeight: 800,
                        width: '50%',
                        textAlign: 'left',
                        margin: 8
                      }}
                    >
                      Answer
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: 'Roboto',
                        fontWeight: 800,
                        width: '50%',
                        textAlign: 'right',
                        margin: 8
                      }}
                    >
                      Score
                    </Text>
                  </View>
                </View>
                {survey.questionRows.map(s => (
                  <View
                    style={{
                      height: 100,
                      flex: 1,
                      backgroundColor: '#D3D3D3',
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        height: '100%',
                        width: '10%',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, textAlign: 'left', margin: 8 }}
                      >
                        {s.id}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: '100%',
                        width: '30%',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, textAlign: 'left', margin: 8 }}
                      >
                        {s.question}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: '100%',
                        width: '30%',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          width: '100%',
                          textAlign: 'right',
                          margin: 8
                        }}
                      >
                        {s.answer}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: '100%',
                        width: '30%',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Text style={{ fontSize: 10, margin: 8 }}>{s.value}</Text>
                    </View>
                  </View>
                ))}
                <View style={styles.line} />
              </View>
            </View>
          </Page>
        ))}
      </Document>
    );
  }
}

export default SurveyResult;
