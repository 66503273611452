import types from './types';

const INITIAL_STATE = {
  showSpinner: false,
  alertsData: [],
  patientsMap: {},
  updateAck: {
    spinner: false
  }
};
const alertsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_ALERTS_JSON: {
      return {
        ...state,
        showSpinner: true,
      };
    }

    case types.RECEIVE_ALERTS_JSON: {
      const {
        alertsData,
        patientsMap
      } = action;

      if(alertsData.length === 0){
        return {
          ...state,
          showSpinner: false
        };
      }

      return {
        ...state,
        alertsData,
        patientsMap,
        showSpinner: false
      };
    }

    case types.REQUEST_ACK: {
      return {
        ...state,
        updateAck: {
          spinner: true
        }
      };
    }

    case types.RECEIVE_ACK: {
      return {
        ...state,
        updateAck: {
          spinner: false
        }
      };
    }

    case types.RECEIVE_ACK_SUCCESS: {
      const { ackDetails } = action;

      // If the ack succeded, we need to change the state of alertsData, adding three params:
      /*
       * firstAckBy
       * firstAckUtc
       * firstAckName
       */
      const editAlertsData = state.alertsData.map(element => {
        if(element.id === ackDetails.id){
          return {
            ...element,
            firstAckBy: ackDetails.firstAckBy,
            firstAckUtc: ackDetails.firstAckUtc,
            firstAckName: ackDetails.firstAckName
          };
        }
        return element
      });

      return {
        ...state,
        alertsData: editAlertsData,
        updateAck: {
          spinner: false
        }
      };
    }

    default:
      return state;
  }
};

export default alertsReducer;
