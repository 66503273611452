import firebase from '../../../config/firebase';

let cache = [];
const func = firebase
  .functions()
  .httpsCallable('dashboardFunctions-getClinicians');

export async function getClinicians(partner) {
  if (cache[partner]) return Promise.resolve(cache[partner]);
  const { data } = await func(partner);

  cache[partner] = data;
  return data;
}

export async function getCliniciansMap(partner) {
  const obj = {};
  if (cache[partner]) {
    cache[partner].forEach((item) => {
      obj[item.uid] = item;
    });

    return Promise.resolve(obj)
  };

  const { data } = await func(partner);

  cache[partner] = data;

  data.forEach((item) => {
    obj[item.uid] = item;
  });

  return obj;
}

export function deleteCliniciansList() {
  cache = [];
}
