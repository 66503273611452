import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TablePagination,
  LinearProgress,
  Paper,
  Typography
} from '@material-ui/core';
import TableRowLoader from './tableSections/tableRowLoader';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  table: {
    minWidth: 650
  },
  progress: {
    margin: theme.spacing(2),
    bottom: 10
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  footer: {
    width: '100%'
  }
});

const buildColumns = (showColumns, columnsName) => {
  const columns = showColumns.map(col => ({
    id: col,
    label: columnsName[col]
  }));

  return columns;
};

function GeneralTable(props) {
  const {
    classes,
    data,
    filters,
    showColumns,
    columnsName,
    showSpinner
  } = props;

  const columns = buildColumns(showColumns, columnsName);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePageTest(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPageTest(event) {
    setRowsPerPage(+event.target.value);
  }

  return (
    <Paper className={classes.root}>
      {!showSpinner && data.length === 0 ? (
        <div
          style={{
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography version="body1">
            {filters.length > 0
              ? 'There are no results matching that search. Please try again.'
              : 'There are no results available.'}
          </Typography>
        </div>
      ) : (
        <>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} size="small">
              <TableRowLoader
                allProps={props}
                page={page}
                rowsPerPage={rowsPerPage}
                columns={columns}
                checkbox={false}
                showSpinner={showSpinner}
              />
            </Table>
          </div>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={handleChangePageTest}
            onChangeRowsPerPage={handleChangeRowsPerPageTest}
          />
        </>
      )}

      {showSpinner ? <LinearProgress className={classes.progress} /> : null}

    </Paper>
  );
}

GeneralTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GeneralTable);
