import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: 'fit-content',
        padding: '2px',
        minWidth: 100,
        height: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 10
    },
    title: {
        fontSize: 12,
        lineHeight: '24px',
        fontWeight: 600
    },
    level: {
        fontSize: 18,
        lineHeight: '24px',
        fontWeight: 700
    }
});

export const levelColors = {
    moderate: '#DD6633',
    mild: '#FCB130',
    severe: '#FC5130'
};

const Level = ({ title, level, score }) => {
    const classes = useStyles();
    return (
      <Box className={classes.root}>
        <Typography className={classes.title} component="div">
          {title}
        </Typography>
        <Typography
          className={classes.level}
          style={{ color: levelColors?.[level] || 'initial' }}
          component="div"
        >
          {score || score === 0 ? score : '-'}
        </Typography>
      </Box>
    );
};

export default Level;
