import React, { useEffect } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  Typography,
  LinearProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SurveyAnswerRow from '../../../components/SurveyAnswerRow';
import notifier from '../utils/notifier';
import { getAllCustomSurveys } from '../allCustomSurveys/getAllCustomSurveys';
import SurveyResultDoc from '../reports/pdf/survey_result';

const styles = () => ({});

function SurveyResultDialog({ session, scores, createCSV, survey, close }) {
  const [loading, setLoad] = React.useState(false);
  const [surveyList, setSurvey] = React.useState([]);

  useEffect(() => {
    const loadSurveys = async () => {
      setLoad(true);
      try {
        const resp = await getAllCustomSurveys([survey]);
        const { questions } = resp[survey];
        const orderedQuestions = sortBy(questions, 'order');

        let value = {
          name: survey,
          questionRows: []
        };
        // Check for the standard surveys PHQ and GAD (Only if completed)
        if (
          survey === 'PHQ' &&
          session.phqStatus &&
          session.phqStatus !== 'OFF'
        ) {
          value = {
            ...value,
            total: session.phqTotal ?? 'n/a',
            tableName: session.phq9 ? `Survey: PHQ-9 - Total Score: ${session.phqTotal ?? 'n/a'}` : `Survey: PHQ - Total Score: ${session.phqTotal ?? 'n/a'}`
          }
        }
        if (
          survey === 'GAD' &&
          session.gadStatus &&
          session.gadStatus !== 'OFF'
        ) {
          value = {
            ...value,
            total: session.gadTotal ?? 'n/a',
            tableName: `Survey: GAD-7 - Total Score: ${session.gadTotal ?? 'n/a'}`
          }
        }

        // In case there are no values for either GAD or PHQ, return empty 
        if(!value.tableName){
          setSurvey([])
          return
        }

        let i = 0;
        orderedQuestions.forEach(q => {
          value.questionRows.push({
            id: i + 1,
            question: q.prompt,
            answer:
              scores[i] || scores[i] === 0 ? q.answers[scores[i] + 1] : 'n/a',
            value: scores[i]
          });
          i += 1;
        });

        setSurvey([value]);
      } catch (e) {
        // silence error
        console.error(e);
        notifier.error(
          'Sorry, something went wrong. Please, try again later or contact support@ellipsishealth.com'
        );
      }
      setLoad(false);
    };

    loadSurveys();
  }, []);

  const prepareCSV = () => {
    const dataToExport = [];
    surveyList[0].questionRows.forEach((d, i) => {
      dataToExport[i] = [
        `"${d.id}"`,
        `"${d.question}"`,
        `"${d.answer}"`,
        `"${d.value ?? ''}"`
      ];
    });

    const headersCSV = [
      'Question #',
      'Question',
      'Answer',
      'Value'
    ];

    createCSV(
      dataToExport,
      headersCSV,
      `Ellipsis_${survey}_${moment(new Date()).format(
        'YYYY-MM-DD'
      )}.csv`
    );
  }

  const preparePDF = async () => {
    const sessions = [];
    sessions.push(session);

    const blob = await pdf(
      <SurveyResultDoc
        surveys={surveyList}
        session={session}
      />
    ).toBlob();

    saveAs(
      blob,
      `Ellipsis_SurveyResult_${survey}_${session.name ?? ''}${session.lastName ?? ''}_${moment(new Date()).format(
        'YYYY-MM-DD'
      )}.pdf`
    );
  }

  return (
    <div>
      <DialogTitle>{survey} Result</DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? (
          <LinearProgress style={{ width: '100%' }} color="primary" />
        ) : (
          ''
        )}
        {surveyList.map(s => (
          <div key={s.name} style={{marginBottom: 10}}>
            <Typography variant="h6">{s.tableName}</Typography>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                marginTop: 10
              }}
            >
              {!loading ? (
                <>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">QUESTION #</Typography>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">QUESTION</Typography>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }} />
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">ANSWER</Typography>
                  </div>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography variant="body1">SCORE</Typography>
                  </div>
                </>
              ) : (
                <LinearProgress style={{ width: '100%' }} color="primary" />
              )}
            </div>
            <Divider />
            {s.questionRows.map(obj => (
              <SurveyAnswerRow
                key={obj.id}
                number={obj.id}
                question={obj.question}
                answer={obj.answer}
                value={obj.value}
              />
            ))}
            <Divider />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        {surveyList.length > 0 ? (
          <>
            <Button onClick={prepareCSV} variant="contained" color="secondary">
              Download CSV
            </Button>
            <Button onClick={preparePDF} variant="contained" color="secondary">
              Download PDF
            </Button>
          </>
        ) : (
          ''
        )}
        <Button onClick={close} color="primary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}

SurveyResultDialog.propTypes = {
  close: PropTypes.func,
  scores: PropTypes.array,
  survey: PropTypes.string
};

export default withStyles(styles)(SurveyResultDialog);
