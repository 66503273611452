import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Tooltip,
  FormControl,
  Select,
  IconButton,
  Button,
  Paper,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  CircularProgress,
  LinearProgress,
  Divider
} from '@material-ui/core';
import { MdCreate, MdDelete, MdClose } from 'react-icons/md';
import Snackbar from '../common/snackbar/snackbar';
import Dialog from '../common/dialog/dialog';
import { DateComponent } from '../../components/Date';
// eslint-disable-next-line import/no-duplicates
import EditUserForm from './clinicianForm';
// eslint-disable-next-line import/no-duplicates
import NewUserForm from './clinicianForm';

import { deleteCliniciansList } from '../common/form/getClinicians';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginTop: theme.spacing(3)
  },
  inviteButton: {
    display: 'block',
    margin: 'auto'
  },
  Close: {
    width: '100%',
    textAlign: 'right'
  },
  textField: {
    width: '60%'
  },
  formControl: {
    margin: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center'
  },
  enabledFormControl: {
    padding: 0
  },
  progress: {
    margin: theme.spacing(2),
    bottom: 10,
    width: '90%'
  },
  paper: {
    marginTop: theme.spacing(3)
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  phq9actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  phq9green: {
    backgroundColor: '#20ba08',
    width: 20,
    height: 20,
    padding: '0px',
    marginRight: '8px',
    borderRadius: '50%'
  },
  phq9red: {
    backgroundColor: '#c30808',
    width: 20,
    height: 20,
    padding: '0px',
    marginRight: '8px',
    borderRadius: '50%'
  }
});

function desc(a, b, orderBy) {
  if (orderBy === 'creationTime' || orderBy === 'lastSignInTime') {
    const v1 = new Date(a[orderBy]);
    const v2 = new Date(b[orderBy]);

    if (v2 < v1) {
      return -1;
    }
    if (v2 > v1) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const mapAccess = { true: 'disabled', false: 'enabled' };
const roleMap = {
  super: 'Super',
  admin: 'Practice Manager',
  crisisteammember: 'Crisis Member',
  clinician: 'Clinician',
  viewer: 'Viewer'
};

class ManagementComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newUserFormOpen: false,
      editUserFormOpen: false,
      showSnackbar: false,
      showLogsDialog: false,
      showDeleteDialog: false,

      // order of the values in the table
      order: 'desc',
      orderBy: 'creationTime',

      // State about the current user edited
      editRole: '',
      editEmail: '',
      editPhone: '',
      editFirstName: '',
      editLastName: '',
      editDisplayName: ''
    };

    this.createNewUser = this.createNewUser.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
  }

  componentDidMount() {
    const { loginState, getUserList } = this.props;
    const { portalActivePartners } = loginState.portalConfig;
    const data = {
      partner: portalActivePartners[0]
    };

    getUserList(data);

    /*
     * Clear cached staff.
     * In case a new staff gets added, the cache element will be updated.
     */
    deleteCliniciansList();
  }

  componentWillReceiveProps(newProps) {
    const { managementState, loginState, getUserList } = this.props;
    if (
      managementState.snackbar.variant === '' &&
      newProps.managementState.snackbar.variant !== ''
    ) {
      this.setState(
        {
          showSnackbar: true
        },
        () => {
          // Load user data when config saved
          const { portalActivePartners } = loginState.portalConfig;

          const data = {
            partner: portalActivePartners[0]
          };

          getUserList(data);
        }
      );
    } else if (
      managementState.snackbar.variant !== '' &&
      newProps.managementState.snackbar.variant === ''
    ) {
      this.setState({
        showSnackbar: false
      });
    }
  }

  handleClose = () => {
    this.setState({
      newUserFormOpen: false,
      editUserFormOpen: false
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  startEditingUser = user => () => {

    this.setState({
      editRole: user.role,
      editEmail: user.email,
      editPhone: user.phone || '',
      editFirstName: user.firstName,
      editLastName: user.lastName,
      editDisplayName: user.displayName || '',
      editUserFormOpen: true,
      newUserFormOpen: false
    });

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  handleRequestSort = property => () => {
    const { orderBy, order } = this.state;
    const newOrderBy = property;
    let newOrder = 'desc';

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc';
    }

    this.setState({ order: newOrder, orderBy: newOrderBy });
  };

  handleOpenLogsDialog = email => () => {
    this.setState({ showLogsDialog: true, emailForDialog: email });
  };

  openDeleteDialog = () => {
    const { emailForDialog, uidToDelete } = this.state;
    const { deleteDashboardUser, loginState } = this.props;
    const { portalConfig } = loginState;
    const { portalActivePartners } = portalConfig;

    return (
      <Dialog close={this.handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">
          {`Delete ${emailForDialog}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this clinician? All the logs will be
            deleted as well.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteDashboardUser({
                partner: portalActivePartners[0],
                email: emailForDialog,
                uid: uidToDelete,
                disabled: true
              });
              this.handleCloseDialog();
            }}
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleOpenDeleteDialog = (email, uid) => () => {
    this.setState({
      showDeleteDialog: true,
      emailForDialog: email,
      uidToDelete: uid
    });
  };

  handleCloseDialog = () => {
    this.setState({ showLogsDialog: false, showDeleteDialog: false });
  };

  handleClickNewUser = () => {
    const { newUserFormOpen } = this.state;
    this.setState({
      newUserFormOpen: !newUserFormOpen
    });
  };

  createNewUser(props) {
    const {
      email,
      displayName,
      phone,
      firstName,
      lastName,
      role,
    } = props;
    const { createNewUser, loginState } = this.props;
    const { portalConfig } = loginState;
    const { portalActivePartners } = portalConfig;

    /**
     *  --> Add important fields to the data object
     * */
    const data = {};
    const partners = portalActivePartners;

    data.email = email.toLowerCase();
    data.displayName = displayName;
    if (phone.length > 0) {
      data.phone = phone;
    }
    data.role = role;
    data.firstName = firstName;
    data.lastName = lastName;
    data.partners = partners;

    createNewUser(data);
  }

  handleSendInvite(email) {
    const { sendInviteEmail } = this.props;
    sendInviteEmail(email);
  }

  handleEditUser(values) {
    const { editPortalUser, loginState } = this.props;
    const { portalConfig } = loginState;
    const { portalActivePartners } = portalConfig;
    const {
      firstName,
      lastName,
      email,
      displayName,
      phone,
      role,
    } = values;
    const {
      editRole,
      editPhone,
      editFirstName,
      editLastName,
      editDisplayName
    } = this.state;

    /**
     *  --> Add  important fields to the data object
     */
    const data = {};
    if (role !== editRole) {
      data.role = role;
    }
    if (displayName !== editDisplayName) {
      data.displayName = displayName;
    }
    if (phone !== editPhone) {
      data.phone = phone;
    }
    if (firstName !== editFirstName) {
      data.firstName = firstName;
    }
    if (lastName !== editLastName) {
      data.lastName = lastName;
    }

    data.email = email.toLowerCase();
    editPortalUser({ admin: data, partner: portalActivePartners[0] });
  }

  handleEditPHQ9AlertUser(values) {
    const { editPortalUser, loginState } = this.props;
    const { portalConfig } = loginState;
    const { portalActivePartners } = portalConfig;

    const {
      phq9AlertPartners,
      enabled,
      email
    } = values;
    const data = {
      email: email.toLowerCase(),
    }

    switch (enabled) {
      case 'sms':
        data.phq9AlertPartners = {
          ...phq9AlertPartners,
          [`${portalActivePartners[0]}`]: ["sms"]
        }
        break;
      case 'email':
        data.phq9AlertPartners = {
          ...phq9AlertPartners,
          [`${portalActivePartners[0]}`]: ["email"]
        }
        break;
      case 'sms&email':
        data.phq9AlertPartners = {
          ...phq9AlertPartners,
          [`${portalActivePartners[0]}`]: ["email", "sms"]
        }
        break;
      case 'disabled':
        if (phq9AlertPartners && phq9AlertPartners[portalActivePartners[0]]) {
          data.phq9AlertPartners = phq9AlertPartners;
          delete data.phq9AlertPartners[portalActivePartners[0]];
        }
        break;
      default:
      // code block
    }

    editPortalUser({ admin: data, partner: portalActivePartners[0] });

  }

  handleEditNotificationAlertUser(values) {
    const { editPortalUser, loginState } = this.props;
    const { portalConfig } = loginState;
    const { portalActivePartners } = portalConfig;

    const {
      notificationPartners,
      enabled,
      email
    } = values;
    const data = {
      email: email.toLowerCase(),
    }

    switch (enabled) {
      case 'sms':
        data.notificationPartners = {
          ...notificationPartners,
          [`${portalActivePartners[0]}`]: ["sms"]
        }
        break;
      case 'email':
        data.notificationPartners = {
          ...notificationPartners,
          [`${portalActivePartners[0]}`]: ["email"]
        }
        break;
      case 'sms&email':
        data.notificationPartners = {
          ...notificationPartners,
          [`${portalActivePartners[0]}`]: ["email", "sms"]
        }
        break;
      case 'disabled':
        if (notificationPartners && notificationPartners[portalActivePartners[0]]) {
          data.notificationPartners = notificationPartners;
          delete data.notificationPartners[portalActivePartners[0]];
        }
        break;
      default:
      // code block
    }

    editPortalUser({ admin: data, partner: portalActivePartners[0] });

  }

  render() {
    const { classes, loginState, managementState, changeUserStatus } = this.props;
    const { portalConfig } = loginState;
    const { portalActivePartners, role, configurations } = portalConfig;
    const { partnerTimezone, phq9Config, notificationConfig } = configurations;
    const {
      showSpinner,
      userList,
      showInviteSpinner,
      showEditDisableSpinner
    } = managementState;
    const {
      showSnackbar,
      order,
      orderBy,
      editRole,
      editEmail,
      editPhone,
      editFirstName,
      editLastName,
      editDisplayName,
      newUserFormOpen,
      editUserFormOpen,
      showLogsDialog,
      showDeleteDialog
    } = this.state;

    /**
     * FILTER THE USERLIST
     * 1)If the user is admin, super users cannot be seen.
     */
    let users = userList;
    if (portalConfig.role === 'admin') {
      users = userList.filter(a => a.role !== 'super');
    }
    //--------------------------------------------------

    return (
      <div style={{ padding: '20px 20px 20px 20px' }}>
        {/* <BackButton history={history} path="/home" /> */}

        <div className={classes.root}>
          <Typography variant="h5">Manage Staff</Typography>

          {!newUserFormOpen && !editUserFormOpen ? (
            <Button
              onClick={this.handleClickNewUser}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Add New Member
            </Button>
          ) : (
            <Button
              onClick={this.handleClose}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <MdClose style={{ marginRight: 10 }} />
              Close
            </Button>
          )}

          <NewUserForm
            open={newUserFormOpen}
            role={role}
            email=""
            phone=""
            firstName=""
            lastName=""
            displayName=""
            snackbar={managementState.snackbar}
            showSpinner={showSpinner}
            emailDisabled={false}
            buttonClick={this.createNewUser}
            closeForm={this.handleClose}
            buttonTitle="Create User"
            configurations={configurations}
          />

          <EditUserForm
            open={editUserFormOpen}
            role={editRole}
            email={editEmail}
            phone={editPhone.startsWith("+1") ? editPhone.substring(2, editPhone.length) : editPhone} // Remove +1 in front
            firstName={editFirstName}
            lastName={editLastName}
            displayName={editDisplayName}
            snackbar={managementState.snackbar}
            showSpinner={showSpinner}
            emailDisabled
            buttonClick={this.handleEditUser}
            closeForm={this.handleClose}
            buttonTitle="Edit User"
            configurations={configurations}
          />
        </div>

        {/* Dashboard users' table. Show only if other users are available. During loading, show spinner. */}
        {showSpinner && users.length < 1 ? (
          <div className={classes.root}>
            {' '}
            <CircularProgress />{' '}
          </div>
        ) : users.length < 1 ? (
          <div className={classes.root}>
            {' '}
            <Typography variant="body1">
              {' '}
              No users currently available.
            </Typography>{' '}
          </div>
        ) : (
          <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="email"
                      sortDirection={orderBy === 'email' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'email'}
                          direction={order}
                          onClick={this.handleRequestSort('email')}
                        >
                          E-Mail
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="firstName"
                      sortDirection={orderBy === 'firstName' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'firstName'}
                          direction={order}
                          onClick={this.handleRequestSort('firstName')}
                        >
                          First Name
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="lastName"
                      sortDirection={orderBy === 'lastName' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'lastName'}
                          direction={order}
                          onClick={this.handleRequestSort('lastName')}
                        >
                          Last Name
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="role"
                      sortDirection={orderBy === 'role' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'role'}
                          direction={order}
                          onClick={this.handleRequestSort('role')}
                        >
                          Role
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell>Access</TableCell>
                    {phq9Config && phq9Config.phq9Alert ? <TableCell>PHQ 9 Alerts</TableCell> : ""}
                    {notificationConfig && (notificationConfig.thresholdAlert || notificationConfig.upwardAlert) ? <TableCell>Daily Notification</TableCell> : ""}

                    <TableCell
                      key="creationTime"
                      sortDirection={orderBy === 'creationTime' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'creationTime'}
                          direction={order}
                          onClick={this.handleRequestSort('creationTime')}
                        >
                          Created
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="lastSignInTime"
                      sortDirection={
                        orderBy === 'lastSignInTime' ? order : false
                      }
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'lastSignInTime'}
                          direction={order}
                          onClick={this.handleRequestSort('lastSignInTime')}
                        >
                          Last Login
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell style={{ paddingRight: '0px' }}>
                      Actions
                    </TableCell>
                    {/* TableCell>Invite</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(users, getSorting(order, orderBy)).map(user => (
                    <TableRow key={user.email}>
                      <TableCell component="th" scope="row">
                        {user.email}
                      </TableCell>

                      <TableCell>{user.firstName}</TableCell>

                      <TableCell>{user.lastName}</TableCell>

                      <TableCell>{roleMap[user.role] || 'n/a'}</TableCell>

                      <TableCell>
                        {(portalConfig.role === 'admin' &&
                          user.role === 'admin') ||
                          showInviteSpinner ||
                          showEditDisableSpinner ||
                          showSpinner ? (
                          mapAccess[user.disabled]
                        ) : (
                          <FormControl variant="outlined">
                            <Select
                              value={mapAccess[user.disabled]}
                              onChange={e => {
                                changeUserStatus({
                                  uid: user.uid,
                                  disabled: e.target.value !== 'enabled',
                                  partner: portalActivePartners[0],
                                });
                              }}
                            >
                              <MenuItem value={mapAccess[user.disabled]}>
                                {mapAccess[user.disabled]}
                              </MenuItem>
                              <MenuItem value={mapAccess[!user.disabled]}>
                                {mapAccess[!user.disabled]}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </TableCell>

                      {phq9Config && phq9Config.phq9Alert ? (
                        <TableCell>
                          {showInviteSpinner ||
                            showEditDisableSpinner ||
                            showSpinner ? (
                            user.phq9AlertPartners && user.phq9AlertPartners[portalActivePartners[0]] ? user.phq9AlertPartners[portalActivePartners[0]].length === 2 ? 'sms&email'
                              : user.phq9AlertPartners[portalActivePartners[0]][0] === 'sms' ? 'sms' : user.phq9AlertPartners[portalActivePartners[0]][0] === 'email' ? 'email'
                                : 'disabled'
                              : 'disabled'
                          ) : (
                            <FormControl variant="outlined">
                              <Select
                                disabled={user.role === 'viewer'} // If the clinician is a viewer, no option to activate phq9Alerts
                                value={
                                  user.phq9AlertPartners && user.phq9AlertPartners[portalActivePartners[0]] ? user.phq9AlertPartners[portalActivePartners[0]].length === 2 ? 'sms&email'
                                    : user.phq9AlertPartners[portalActivePartners[0]][0] === 'sms' ? 'sms' : user.phq9AlertPartners[portalActivePartners[0]][0] === 'email' ? 'email'
                                      : 'disabled'
                                    : 'disabled'
                                }
                                onChange={e => {
                                  this.handleEditPHQ9AlertUser({
                                    phq9AlertPartners: user.phq9AlertPartners || null,
                                    enabled: e.target.value,
                                    email: user.email || null
                                  });
                                }}
                              >
                                <MenuItem value="sms">
                                  sms
                                </MenuItem>
                                <MenuItem value="email">
                                  email
                                </MenuItem>
                                <MenuItem value="sms&email">
                                  sms & email
                                </MenuItem>
                                <MenuItem value="disabled">
                                  disabled
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </TableCell>
                      ) : ""}

                      {notificationConfig && (notificationConfig.thresholdAlert || notificationConfig.upwardAlert) ? (
                        <TableCell>
                          {showInviteSpinner ||
                            showEditDisableSpinner ||
                            showSpinner ? (
                            user.notificationPartners && user.notificationPartners[portalActivePartners[0]] ? user.notificationPartners[portalActivePartners[0]].length === 2 ? 'sms&email'
                              : user.notificationPartners[portalActivePartners[0]][0] === 'sms' ? 'sms' : user.notificationPartners[portalActivePartners[0]][0] === 'email' ? 'email'
                                : 'disabled'
                              : 'disabled'
                          ) : (
                            <FormControl variant="outlined">
                              <Select
                                disabled={user.role === 'viewer'} // If the clinician is a viewer, no option to activate notificationAlerts
                                value={
                                  user.notificationPartners && user.notificationPartners[portalActivePartners[0]] ? user.notificationPartners[portalActivePartners[0]].length === 2 ? 'sms&email'
                                    : user.notificationPartners[portalActivePartners[0]][0] === 'sms' ? 'sms' : user.notificationPartners[portalActivePartners[0]][0] === 'email' ? 'email'
                                      : 'disabled'
                                    : 'disabled'
                                }
                                onChange={e => {
                                  this.handleEditNotificationAlertUser({
                                    notificationPartners: user.notificationPartners || null,
                                    enabled: e.target.value,
                                    email: user.email || null
                                  });
                                }}
                              >
                                <MenuItem value="sms">
                                  sms
                                </MenuItem>
                                <MenuItem value="email">
                                  email
                                </MenuItem>
                                <MenuItem value="sms&email">
                                  sms & email
                                </MenuItem>
                                <MenuItem value="disabled">
                                  disabled
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </TableCell>
                      ) : ""}

                      <TableCell>
                        <DateComponent
                          date={user.creationTime}
                          timezone={partnerTimezone}
                        />
                      </TableCell>
                      <TableCell>
                        <DateComponent
                          date={user.lastSignInTime}
                          timezone={partnerTimezone}
                        />
                      </TableCell>

                      <TableCell style={{ paddingRight: '0px' }}>
                        <div className={classes.actions}>
                          <Tooltip title="Edit User">
                            <IconButton
                              style={{ padding: '0px', marginRight: '8px' }}
                              onClick={this.startEditingUser(user)}
                            >
                              <MdCreate style={{ color: '#000000' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete User">
                            <IconButton
                              style={{ padding: '0px' }}
                              onClick={this.handleOpenDeleteDialog(
                                user.email,
                                user.uid
                              )}
                            >
                              <MdDelete style={{ color: '#000000' }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>

                      {/* <TableCell>
                        <Button
                          disabled={showInviteSpinner}
                          onClick={() => this.handleSendInvite(user.email)}
                          variant="outlined"
                          color="primary"
                          className={classes.inviteButton}
                        >
                          Re-Invite
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        )}

        {showDeleteDialog ? this.openDeleteDialog() : null}

        {showEditDisableSpinner ||
          (showSpinner && users.length > 1) ||
          showInviteSpinner ? (
            <LinearProgress />
        ) : (
          ''
        )}

        {!showSnackbar ? (
          ''
        ) : (
          <Snackbar
            open
            variant={managementState.snackbar.variant}
            message={managementState.snackbar.message}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ManagementComponent);
