import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';

import {
  getCurrentSteamingSessions,
  getTokenForStreamSessions
} from './utils/getStreamSessions';
import SingleStream from './components/SingleStream';

import firebase from '../../config/firebase';

const StreamComponent = props => {
  const { portalConfig } = props;
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentSessions, setCurrentSessions] = useState([]);

  const partnerId = portalConfig.portalActivePartners[0];

  useEffect(() => {
    const collectionRef = firebase
      .firestore()
      .collection('streamSessions')
      .where('partnerId', '==', partnerId);

    const unsub = collectionRef.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const data = change.doc.data();

          if (
            data.endedAtUtc === undefined &&
            !currentSessions.find(el => el.sessionId === data.sessionId)
          ) {
            setCurrentSessions(prev => [data, ...prev]);
          }
        } else if (change.type === 'modified') {
          const data = change.doc.data();

          
          if (
            data.endedAtUtc !== undefined &&
            currentSessions.find(el => el.sessionId === data.sessionId)
            ) {
            const index = currentSessions.findIndex(
              el => el.sessionId === data.sessionId
            );
            setCurrentSessions([
              ...currentSessions.slice(0, index),
              data,
              ...currentSessions.slice(index + 1)
            ]);
          }
        }
      });
    });

    return () => {
      unsub();
    };
  });

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const _token = await getTokenForStreamSessions(partnerId);
      setToken(_token);
      setLoading(false);
    };

    getData();
  }, [getCurrentSteamingSessions, getTokenForStreamSessions, partnerId]);

  const loadSessions = async () => {
    setLoading(true);
    const data = await getCurrentSteamingSessions(partnerId);
    setCurrentSessions(data || []);
    setLoading(false);
  };

  const loadingPlaceholder = loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ padding: 5 }}
    >
      <CircularProgress />
    </Box>
  ) : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ marginTop: 32, marginBottom: 16 }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Currently streaming sessions
      </Typography>
      {loadingPlaceholder}
      {currentSessions.map(session => (
        <Box padding={1} key={session.sessionId}>
          <SingleStream
            token={token}
            sessionId={session.sessionId}
            sessionSecret={session.secret}
            finished={!!session?.endedAtUtc}
          />
        </Box>
      ))}
      {currentSessions.length === 0 && !loading && (
        <Typography variant="body2" align="center">
          No sessions are currently streaming
        </Typography>
      )}
      <Box display="flex" justifyContent="center" sx={{ marginTop: 20 }}>
        <Button onClick={loadSessions}>Load more</Button>
      </Box>
    </Box>
  );
};

export default StreamComponent;
