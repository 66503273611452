import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import cardHeaderStyles from './CardHeader.styles';

const BrandCardHeader = withStyles(cardHeaderStyles, {
  name: 'BrandCardHeader'
})(({ classes, alert, seen, confirmedBy, confirmedDate }) => (
  <>
    <div className={classes.root}>
      <div className={classes.header}>
        <div style={{
          display: 'flex', alignItems: 'center'
        }}
        >
          <div
            className={classes.shape}
            style={
              seen
                ? { backgroundColor: green[500] }
                : { backgroundColor: red[500] }
            }
          />
          <div style={{ display: 'flex' }}>
            <Typography className={classes.alertTitle}>Alert ID:</Typography>
            <Typography className={classes.phqalert}>{alert}</Typography>
          </div>
        </div>

        <div className={classes.confirmSection}>
          <Typography className={classes.extra}>{confirmedBy}</Typography>
          <Typography className={classes.extra}>{confirmedDate}</Typography>
        </div>
      </div>
    </div>
    <hr className={classes.divider} />
  </>
));

BrandCardHeader.propTypes = {
  image: PropTypes.string,
  confirmedBy: PropTypes.string,
  confirmedDate: PropTypes.string,
  alert: PropTypes.string
};
BrandCardHeader.defaultProps = {
  image: '',
  extra: ''
};

export default BrandCardHeader;
