import React, { useState } from 'react';
import moment from 'moment';
// Library that helps finding the correct types
import xtype from 'xtypejs';
import { TableBody, TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

import getTableField from './getTableField';

const sortingDisabled = ['details', 'actions', 'gadScores', 'phqScores', 'reviewOfSystems', 'mshServices'];

const customizedColumnIds = {
  "gadTotalClickable": { "name": "gadTotal", "flagCheck": "gadStatus" },
  "phq9TotalClickable": { "name": "phqTotal", "flagCheck": "phq9Status" },
  "gadTotalCategory": { "name": "gadTotal", "flagCheck": "gadStatus" },
  "phqTotalCategory": { "name": "phqTotal", "flagCheck": "phqStatus" },
  "phq9Total": { "name": "phqTotal", "flagCheck": "phq9Status" },
  "phqTotal": { "name": "phqTotal", "flagCheck": "phqStatus" },
  "gadTotal": { "name": "gadTotal", "flagCheck": "gadStatus" },
  "phqQ9": { "name": "phqScores", "type": "ARRAY9", "flagCheck": "phq9Status" }
}

function desc(a, b, orderBy) {

  // Check if the value used to order is part of the customizedColumnIds map
  if (customizedColumnIds[orderBy]) {
    const defaultColumnValue = customizedColumnIds[orderBy].name;
    const defaultColumnType = customizedColumnIds[orderBy].type;
    const flag = customizedColumnIds[orderBy].flagCheck;

    let v1 = a[defaultColumnValue];
    const v1Flag = a[flag] ?? null;
    let v2 = b[defaultColumnValue];
    const v2Flag = b[flag] ?? null;

    if(flag){
      // Working on a field that relies on a flag. E.g., phq9Status
      if(v1Flag === "COMPLETE" && v2Flag !== "COMPLETE"){
        return -1
      }
      if(v1Flag !== "COMPLETE" && v2Flag === "COMPLETE"){
        return 1
      }
    }

    // If the type is an array9, we sort based on the value with index 8
    if (defaultColumnType === "ARRAY9") {
      v1 = v1 && v1[8] ? v1[8] : -1;
      v2 = v2 && v2[8] ? v2[8] : -1;
    }

    if (v2 < v1) {
      return -1;
    }
    if (v2 > v1) {
      return 1;
    }
    return 0;
  }

  // Check if one of the values is a date E.g., {value: "date_element"}
  if ((xtype(a[orderBy]) === 'single_prop_object' && a[orderBy]?.value )||
    (xtype(b[orderBy]) === 'single_prop_object' && b[orderBy]?.value)) {
    const v1 = a[orderBy]?.value ? new Date(a[orderBy].value) : '';
    const v2 = b[orderBy]?.value ? new Date(b[orderBy].value) : '';

    if (v2 < v1) {
      return -1;
    }
    if (v2 > v1) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { order, orderBy, config, onRequestSort, columns, showSpinner } = props;

  /**
    * If the user has a viewer role, remove the restricted columns
  */
  const { configurations, role } = config;
  const { restricted_fields_viewer } = configurations.user_restriction;
  const filteredRows = [].concat(columns);

  if (role === 'viewer') {
    for (let i = filteredRows.length - 1; i >= 0; i -= 1) {
      if (restricted_fields_viewer.indexOf(filteredRows[i].id) > -1) {
        filteredRows.splice(i, 1);
      }
    }
  }

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {filteredRows.map(row => (
          <TableCell
            key={row.id}
            align="left" // This value will not be present
            padding="default" // This value will not be present
            sortDirection={
              orderBy === row.id ? order : false
            }
          >
            {sortingDisabled.indexOf(row.id) === -1 ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                disabled={showSpinner}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              row.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableRowLoader = props => {
  const { allProps, columns, page, rowsPerPage, showSpinner } = props;
  const { data, lastLogin, config } = allProps;

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('startTimeStamp');

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  /**
   * Session new (not yet seen from the user):
   * HOW: Check if lastLogin is < than session endTimeStamp
   * RESULT: The text is bold or normal
   * - Send a variable with the correct style
   * --> {fontWeight: 500} OR {}
   */
  let newSession = {};
  if (typeof lastLogin !== 'undefined') {
    // 600 is the bold value set for tableCells representing newSessions not yet viewed
    newSession = 600;
  }

  return (
    <>
      <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        config={config}
        onRequestSort={handleRequestSort}
        rowCount={data.length}
        columns={columns}
        showSpinner={showSpinner}
      />
      <TableBody>
        {stableSort(data, getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((session, index) => {
            const labelId = `table-checkbox-${index}`;

            return (
              <TableRow key={labelId}>
                {lastLogin &&
                  session.startTimeStamp &&
                  Number(lastLogin) <=
                  Number(moment(session.startTimeStamp.value).format('x'))
                  ? getTableField(columns, session, allProps, newSession)
                  : getTableField(columns, session, allProps, 0)}
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};

export default TableRowLoader;
