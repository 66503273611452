import { connect } from 'react-redux';
import ManagementComponent from './ManagementComponent';
import { managementOperations } from './redux';
import { loginOperations } from '../auth/login/redux';

const mapStateToProps = state => {
  const managementState = state.management;
  const loginState = state.login;

  return {
    loginState,
    managementState
  };
};

const mapDispatchToProps = dispatch => {
  const createNewUser = data =>
    dispatch(managementOperations.createNewUser(data));
  const editPortalUser = data =>
    dispatch(managementOperations.editExistingPortalUser(data));
  const getUserList = data => dispatch(managementOperations.getUserList(data));
  const sendInviteEmail = email =>
    dispatch(managementOperations.sendInviteEmail(email, ''));
  const changeUserStatus = data => dispatch(managementOperations.changeUserStatus(data));
  const deleteDashboardUser = data =>
    dispatch(managementOperations.deleteDashboardUser(data));
  const getUserLogs = email =>
    dispatch(managementOperations.getUserLogs(email));

  return {
    createNewUser,
    editPortalUser,
    getUserList,
    sendInviteEmail,
    changeUserStatus,
    deleteDashboardUser,
    getUserLogs
  };
};

const ManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementComponent);

export default ManagementContainer;
