import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@material-ui/core';
import { loginOperations } from '../../../auth/login/redux';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);

    this.clearTimeout();

    this.state = {
      open: false
    };

    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
      'onmousewheel',
      'onwheel'
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in this.events) {
      // console.log('Setting the auto logout event listener for: ');
      // console.log(this.events[i]);
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 840 * 1000); // s //14 minuti x warning
    this.logoutTimeout = setTimeout(this.logout, 900 * 1000); // s //15 minuti x auto logout
  }

  handleClickOpenDialog = () => {
    this.setState({ open: true });
  };

  handleCloseDialog = () => {
    this.setState({ open: false });
    this.clearTimeout();
  };

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  resetTimeout() {
    this.setState({
      open: false
    });
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    this.setState({
      open: true
    });
  }

  logout() {
    const { logout } = this.props;
    console.log('Sending a logout request to the API...');
    sessionStorage.removeItem('page');
    this.destroy(); // Cleanup
    this.setState({
      open: false
    });

    logout();
  }

  destroy() {
    this.clearTimeout();

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  render() {
    const { open } = this.state;
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
        >
          <DialogTitle id="alert-dialog-slide-title">
            You will be logged out automatically in 1 minute.
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              It seems like you have not been active for a while. For security
              reasons, you will be logged out in 1 minute.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              I'm here
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  const logout = () => dispatch(loginOperations.logUserOut());

  return {
    logout
  };
};

export default connect(null, mapDispatchToProps)(AutoLogout);
