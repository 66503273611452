import React from 'react';
import PropTypes from 'prop-types';

import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';

Font.register({ 
  family: 'Roboto', 
  src: '/font/Roboto-Bold.ttf', 
  fontWeight: 'bold',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 40
  },
  pageSessionBox: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sectionOne: {
    margin: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imagerowone: {
    width: 200
  },
  imagerow: {
    width: 250
  },
  imagesessionbox: {
    width: '80%'
  },
  imagemiddle: {
    padding: 10,
    width: '90%'
  },
  sectionTwo: {
    marginTop: 40
  },
  sectionThree: {},
  header: {
    fontSize: 10
  },
  value: {
    fontSize: 10,
    marginLeft: 4,
    color: '#0277bd'
  },
  details: {
    flexDirection: 'row'
  },
  line: {
    height: 1,
    backgroundColor: '#404040'
  },
  text: {
    fontSize: 8
  }
});

// Create Document Component
class Screenshot extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Document>
        <Page key={1} size="A4" style={styles.page}>
          <View style={styles.sectionOne}>
            <Image style={styles.imagerowone} src={data.top1} alt="images" />
            <Image style={styles.imagerow} src={data.top2} alt="images" />
          </View>
          <View style={styles.sectionOne}>
            <Image style={styles.imagemiddle} src={data.middle} alt="images" />
          </View>
        </Page>
        <Page key={2} size="A4" style={styles.page}>
          {data.bottom.map((session, i) => (
            <View key={`${session}_${i}`} style={styles.sectionOne}>
              <Image
                style={styles.imagesessionbox}
                src={session}
                alt="images"
              />
            </View>
          ))}
          <View style={styles.sectionOne}>
            <Text style={styles.text}>
              "The values provided are clinical support tools. Depression and
              Anxiety Symptom Assessments are a preliminary analysis, and not
              for diagnostic purposes. For definitive diagnosis, a clinical
              interview must be performed. Symptom Assessments are analyzed
              using acoustic and semantic characteristics of voice, which
              provide insights and are suggestive of depression and anxiety. For
              additional information, please refer to 1) Multimodal Assessment
              of Depression from Behavioral Signals (2018).
              https://doi.org/10.1145/3107990.3108004. 2) A Cross-modal Review
              of Indicators for Depression Detection Systems (2017).
              https://doi.org/10.18653/v1/W17-3101. 3) Natural Language
              Processing in Mental Health Applications Using Non-clinical Texts
              (2017). https://doi.org/10.1017/S1351324916000383."{' '}
            </Text>
          </View>
        </Page>
      </Document>
    );
  }
}

Screenshot.propTypes = {
  data: PropTypes.object
};

export default Screenshot;
