import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Button, CircularProgress } from '@material-ui/core';
import PHQ9AlertCardHeader from './PHQ9AlertCardHeader';
import TextInfoContent from './TextInfoContent';

import { formatDate } from '../../../components/Date';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderRadius: 20
  },
  content: {
    padding: 15
  },
  textContent: {
    overline: {
      textTransform: 'uppercase',
      color: '#9e9e9e',
      marginBottom: '0.35em',
      fontWeight: 'bold',
      letterSpacing: '2px',
      fontSize: 16,
      display: 'inline-block'
    },
    heading: {
      fontSize: 24,
      fontWeight: 900,
      marginBottom: '0.72em'
    },
    body: {
      fontSize: 16,
      color: theme.palette.primary.main,
      lineHeight: 1.75
    }
  },
  buttonDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20
  }
}));

export const PHQ9AlertCard = React.memo(function PHQ9AlertCard(props) {
  const cardStyles = useStyles();
  const { data, loading, acknowledgeAlert, config } = props;

  return (
    <Card className={cardStyles.root}>
      <PHQ9AlertCardHeader
        alert={data.alertId}
        seen={data.firstAckBy} // seen={data.firstAckBy} which means that someone has seen/not seen the alert
        confirmedBy={data.firstAckUtc && data.firstAckName ? `Confirmed By: ${data.firstAckName || ""}` : ''}
        confirmedDate={formatDate(data.firstAckUtc?.value, 'L LT z', config.timezone)}
      />
      <CardContent className={cardStyles.content}>
        <TextInfoContent className={cardStyles.textContent} data={data} config={config} />
        <div className={cardStyles.buttonDiv}>
          {!loading.spinner || data.firstAckBy ? (
            <Button
              disabled={data.firstAckBy}
              style={{ width: '100%', maxWidth: 300 }}
              onClick={() => acknowledgeAlert({
                id: data.id,
                partnerId: data.partnerId
              })}
              variant="contained"
              color="secondary"
            >
              {config && config.phq9AlertFollowUp ? "Confirm and text team" : "Confirm receipt"}
            </Button>
          ) : (
            <CircularProgress />
          )}
        </div>
      </CardContent>
    </Card>
  );
});

PHQ9AlertCard.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.object,
  acknowledgeAlert: PropTypes.func,
  config: PropTypes.object
};

export default PHQ9AlertCard;
