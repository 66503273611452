import React from 'react';
import PropTypes from 'prop-types';

function SurveyAnswerRow({ number, question, answer, value }) {
  return (
    <>
      <div style={{ width: '100%', display: 'flex', flex: 1, marginTop: 15 }}>
        <div style={{ display: 'flex', flex: 1, margin: 10 }}>{number}</div>
        <div style={{ display: 'flex', flex: 1, margin: 10 }}>{question}</div>
        <div style={{ flex: 1, margin: 10}} />
        <div style={{ flex: 1, margin: 10}}>{answer}</div>
        <div style={{ flex: 1, margin: 10 }}>{value}</div>
      </div>
    </>
  );
}

SurveyAnswerRow.propTypes = {
  number: PropTypes.number,
  question: PropTypes.string,
  answer: PropTypes.string,
  value: PropTypes.number
};

export default SurveyAnswerRow;
