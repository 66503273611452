import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    marginBottom: 20,
    marginRight: 40,
    marginLeft: 40,
    alignItems: 'center',
    [theme.breakpoints.down(850)]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  columnDivs: {
    width: '100%',
    padding: 1,
    margin: 5
  },
  rowDivs: {
    width: '100%',
    display: 'flex',
    padding: 1,
    margin: 5
  },
  bold: {
    fontWeight: 'bold'
  },
  cardPHQGAD: {
    flex: 1,
    height: 150,
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.secondary.main_two
  },
  cardPHQGADContent: {
    display: 'grid',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%'
  },
  textFourWay: {
    flex: 1,
    fontSize: 20,
    fontWeight: 600
  },
  textNumber: {
    flex: 1,
    fontSize: 20,
    fontWeight: 600
  }
});

const getDepressionValue = (session, showEHResults, has4WayClassification, hasPhq9) => {
  if (!showEHResults) { return null }

  if (has4WayClassification) {
    /**
     * phq_4c could present 4 values: 0, 1, 2, 3
     * 0 --> Values between 0 and 5
     * 1 --> Values between 5 and 10
     * 2 --> Values between 10 and 15
     * 3 --> Values above 15
     */
    if (session.phq_4c === null) { return null }

    return session.phq_4c === null || session.phq_4c < 0 ? null : session.phq_4c === 0 ? 2.5 :
      session.phq_4c === 1 ? 7.5 :
        session.phq_4c === 2 ? 12.5 :
          (session.phq_4c === 3 && hasPhq9) ? 21 : 19.5
  }

  return session.phq_r < 0 ? 0 : session.phq_r > 24 ? 24 : session.phq_r

}
const getAnxietyValue = (session, showEHResults, has4WayClassification) => {
  if (!showEHResults) { return null }

  if (has4WayClassification) {
    /**
     * gad_4c could present 4 values: 0, 1, 2, 3
     * 0 --> Values between 0 and 5
     * 1 --> Values between 5 and 10
     * 2 --> Values between 10 and 15
     * 3 --> Values above 15
     */
    if (session.gad_4c === null) { return null }

    return session.gad_4c === null || session.gad_4c < 0 ? null : session.gad_4c === 0 ? 2.5 :
      session.gad_4c === 1 ? 7.5 :
        session.gad_4c === 2 ? 12.5 : 18
  }

  return session.gad_r < 0 ? 0 : session.gad_r > 21 ? 21 : session.gad_r

}
function Recent({ classes, session, columnsToShow }) {

  // Check if the user has 4 way classification (phq_4c and gad_4c) in the session table columns
  // In that case, show FourWayAssessmentChart
  const has4WayClassification = (columnsToShow.indexOf("phq_4c") !== -1 || columnsToShow.indexOf("gad_4c") !== -1);

  // Check if the user has PHQ and GAD categories in the session table columns
  // In that case, show hasPHQGADCategories
  const hasPHQGADCategories = (columnsToShow.indexOf("phqTotalCategory") !== -1 || columnsToShow.indexOf("gadTotalCategory") !== -1);

  return (
    <div id="middleContOne" className={classes.root}>

      <div className={classes.columnDivs}>
        <div style={{ margin: 0 }} className={classes.rowDivs}>
          <div style={{ flex: 1, height: 150, width: 150, marginRight: 20 }}>
            <Card className={classes.cardPHQGAD}>
              <CardContent className={classes.cardPHQGADContent}>
                {has4WayClassification ?
                  (
                    <>
                      <Typography variant="body1">
                        Recent Ellipsis Category (Depression)
                      </Typography>
                      <Typography className={classes.textFourWay} variant="h2">
                        {session.phq_4c === null || session.phq_4c < 0 ? "Incomplete" : session.phq_4c === 0 ? "None" : session.phq_4c === 1 ? "Mild" : session.phq_4c === 1 ? "Mild" : session.phq_4c === 2 ? "Moderate" : (session.phq_4c === 3 && (session.phq9 || session.phqScores?.length === 9)) ? "Severe" : session.phq_4c === 3 ? "Severe" : session.phq_4c}
                      </Typography>
                      <div style={{ height: '40%' }} />
                    </>
                  )
                  : hasPHQGADCategories ?
                    (
                      <>
                        <Typography variant="body1">
                          {`Recent PHQ-${session.phq9Status !== 'OFF' ? '9' : '8'} Category (Depression)`}
                        </Typography>
                        <Typography className={classes.textFourWay} variant="h2">
                          {!session.phqStatus ? "Incomplete" : session.phqStatus !== 'COMPLETE' ? session.phqStatus.charAt(0) + session.phqStatus.substring(1).toLowerCase() : session.phqTotal < 5 ? "None" : session.phqTotal < 10 ? "Mild" : session.phqTotal < 15 ? "Moderate" : session.phqTotal >= 15 ? "Severe" : ''}
                        </Typography>
                        <div style={{ height: '40%' }} />
                      </>

                    )
                    :
                    (
                      <>
                        <Typography variant="body1">
                          Recent Ellipsis Score (Depression)
                        </Typography>
                        <Typography className={classes.textNumber} style={session.phq_r >= 10 ? { color: '#be2020', fontWeight: 'bold' } : {}} variant="h2">
                          {session && (session.phq_r || session.phq_r === 0) && session.phq_r !== -1 ? Math.ceil(session.phq_r).toString() : 'Incomplete'}
                        </Typography>
                        <div style={{ height: '40%' }} />
                      </>

                    )
                }
              </CardContent>
            </Card>
          </div>
          <div style={{ flex: 1, height: 150, width: 150 }}>
            <Card className={classes.cardPHQGAD}>
              <CardContent className={classes.cardPHQGADContent}>
                {has4WayClassification ?
                  (
                    <>
                      <Typography variant="body1">
                        Recent Ellipsis Category (Anxiety)
                      </Typography>
                      <Typography className={classes.textFourWay} variant="h2">
                        {session.gad_4c === null || session.gad_4c < 0 ? "Incomplete" : session.gad_4c === 0 ? "None" : session.gad_4c === 1 ? "Mild" : session.gad_4c === 1 ? "Mild" : session.gad_4c === 2 ? "Moderate" : session.gad_4c === 3 ? "Severe" : session.gad_4c}
                      </Typography>
                      <div style={{ height: '40%' }} />
                    </>
                  )
                  : hasPHQGADCategories ?
                    (
                      <>
                        <Typography variant="body1">
                          Recent GAD-7 Category (Anxiety)
                        </Typography>
                        <Typography className={classes.textFourWay} variant="h2">
                          {!session.gadStatus ? "Incomplete" : session.gadStatus !== 'COMPLETE' ? session.gadStatus.charAt(0) + session.gadStatus.substring(1).toLowerCase() : session.gadTotal < 5 ? "None" : session.gadTotal < 10 ? "Mild" : session.gadTotal < 15 ? "Moderate" : session.gadTotal >= 15 ? "Severe" : ''}
                        </Typography>
                        <div style={{ height: '40%' }} />
                      </>

                    )
                    :
                    (
                      <>
                        <Typography variant="body1">
                          Recent Ellipsis Score (Anxiety)
                        </Typography>
                        <Typography className={classes.textNumber} style={session.gad_r >= 10 ? { color: '#be2020', fontWeight: 'bold' } : {}} variant="h2">
                          {session && (session.gad_r || session.gad_r === 0) && session.gad_r !== -1 ? Math.ceil(session.gad_r).toString() : 'Incomplete'}
                        </Typography>
                        <div style={{ height: '40%' }} />
                      </>
                    )
                }
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

Recent.propTypes = {
  session: PropTypes.object,
  classes: PropTypes.object,
  columnsToShow: PropTypes.array
};

const enhance = withStyles(styles);

export default enhance(Recent);
