import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, Link } from '@material-ui/core';

const styles = theme => ({
  cont_search: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: 20
    }
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: 350,
    height: '100%',
    marginLeft: 8,
    [theme.breakpoints.down(500)]: {
      width: 300
    }
  },
  button: {
    backgroundColor: theme.palette.primary.main_two,
    margin: 8,
    color: '#FFFFFF',
    '&:hover': {
      background: theme.palette.primary.main_two
    }
  }
});

function Search(props) {
  const {
    classes,
    spinner,
    activeFilters,
    saveConfiguration,
    setFilter
  } = props;

  const [timeout, setT] = useState();
  const [search, setSearchFilter] = useState(
    localStorage.getItem('Search') || ''
  );

  const searchChangeValue = event => {
    const string = event.target.value;
    if (timeout) {
      setT(clearTimeout(timeout));
    }

    /**
     * - Check if string is only white chars OR is empty
     */
    if (!string.replace(/\s/g, '').length) {
      setT(
        setTimeout(() => {
          setSearchFilter('');
          // Check if there are already search filters active, if not why reloading? USE IF
          if (activeFilters.length > 0) {
            if (activeFilters[0].type === 'Search') {
              localStorage.setItem('Search', '');
              saveConfiguration({
                filters: []
              });
            }
          }
        }, 3000)
      );
    }

    setSearchFilter(event.target.value);
  };

  const handleFilterClick = () => {
    /**
     * Run the search only if seach string is not empty or blank
     */
    if (search.replace(/\s/g, '').length) {
      const filters = [
        {
          field: null,
          operator: 'contains',
          value: search.toLowerCase().trim(),
          type: 'Search'
        }
      ];

      saveConfiguration({
        filters
      });
      localStorage.setItem('Search', search);
      setFilter(0);
    }
  };

  const handleClearSearch = () => {
    if (timeout) clearTimeout(timeout);

    if (search.length || activeFilters.length > 0) {
      setSearchFilter('');
      localStorage.setItem('Search', '');
      // Check also if there are already search filters active, if not => no reload
      if (activeFilters.length > 0) {
        setFilter(0);
        saveConfiguration({
          filters: []
        });
      }
    }
  };

  return (
    <div className={classes.cont_search}>
      <Typography variant="body1">Filter by patient:</Typography>
      <div className={classes.search}>
        <TextField
          disabled={spinner}
          style={{ width: '70%' }}
          // InputProps={{ classes: { input: classes.input } }}
          placeholder="Use full name or ID #"
          value={search}
          onChange={searchChangeValue}
          variant="outlined"
          size="small"
        />
        <Button
          disabled={spinner}
          className={classes.button}
          variant="contained"
          onClick={handleFilterClick}
        >
          Filter
        </Button>
      </div>
      {spinner ? (
        <Typography variant="body1">Clear filter</Typography>
      ) : (
        <Link
          style={{ cursor: 'pointer' }}
          variant="body1"
          onClick={handleClearSearch}
        >
          Clear filter
        </Link>
      )}
    </div>
  );
}

Search.propTypes = {
  classes: PropTypes.object,
  spinner: PropTypes.bool,
  activeFilters: PropTypes.array,
  saveConfiguration: PropTypes.func,
  setFilter: PropTypes.func
};

const enhance = withStyles(styles);

export default enhance(Search);
