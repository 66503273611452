import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Audio from '../../app/common/audioComponent/audio';
import { ReactComponent as SvgArrowRight } from '../../assets/chevron-right.svg';
import './style.css';

const LinearProgressStyles = makeStyles(() => ({
  root: {
    height: 10,
    borderRadius: 12,
    boxShadow: '0px 1px 2px rgba(26, 41, 72, 0.3)',
  },
  colorPrimary: {
    backgroundColor: '#ECEDF9',
  },
  level0: {
    borderRadius: 5,
    background: 'linear-gradient(180deg, #FFC184 0%, #DF8831 100%)',
  },
  level1: {
    borderRadius: 5,
    background: 'linear-gradient(180deg, #36E7E1 0%, #3ABAB6 100%)',
  },
  level2: {
    borderRadius: 5,
    background: 'linear-gradient(180deg, #4387CB 0%, #314F7B 100%)',
  },
  level3: {
    borderRadius: 5,
    background: 'linear-gradient(180deg, #D3D4EC 0%, #8F91A6 100%)',
  },
}));

const JournalTopic = ({
  topic,
  duration,
  totalDuration,
  ind,
}) => {
  const [percent, setPercent] = useState(0);
  const classes = LinearProgressStyles();

  // animate the progress bar by setting percent after some delay
  useEffect(() => {
    const id = setTimeout(() => {
      setPercent(100 * duration / totalDuration);
    }, 500);

    return () => {
      clearTimeout(id);
    }
  }, [duration, totalDuration]);

  return (
    <div className="journal-topic">
      <div className="text">{topic}</div>
      <div className="progress">
        <Tooltip title={`${duration} sec`} placement="right">
          <LinearProgress
            variant="determinate"
            value={percent}
            classes={{
              root: classes.root,
              colorPrimary: classes.colorPrimary,
              bar: classes[`level${ind}`],
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

/**
 * 
 * @param {{session:Session, topicQuestions: Array<TopicQuestions>}} param0
 * @returns 
 */
const SessionJournal = ({
  className,
  session,
  recordings = [],
  topicQuestions = [],
  partner
}) => {
  // keeps track of which transcription is visible
  const [viewTranscriptionId, setViewTranscriptId] = useState();

  /**
   * @type {[Map<string, Topic>, Map<string, Topic>]}
   */
  const [mapQuestionToTopics, mapTopics] = useMemo(() => {
    const mapQuesToTopics = new Map();
    const _mapTopics = new Map();

    if (!topicQuestions || topicQuestions.length === 0) {
      return [mapQuesToTopics, _mapTopics];
    }

    topicQuestions.forEach(({ topic, questions }) => {
      _mapTopics.set(topic.id, topic);

      questions.forEach(({ id }) => {
        mapQuesToTopics.set(id, topic);
      });
    });

    return [mapQuesToTopics, _mapTopics];
  }, [topicQuestions]);

  // Map recordings by Id
  const mapRecordings = useMemo(() => {
    return recordings.reduce((prev, recording) => {
      prev[recording.uid] = recording;
      return prev;
    }, {})
  }, [recordings]);

  // setup topics
  const [topics, totalDuration] = useMemo(() => {
    if (mapQuestionToTopics.size === 0 || !session) {
      return [[], 0];
    }

    // map of topicId => (recording duration, recordings)
    const mapTopicRecordings = {};
    let _totalDuration = 0;

    // Prepare the map topics (duration per topic)
    session.questionIds.forEach((qid, ind) => {
      const questionId = qid.substr(0, qid.length - 3);
      const topic = mapQuestionToTopics.get(questionId) || { id: 0 };

      _totalDuration += session.questionDurations[ind];

      if (!mapTopicRecordings[topic.id]) {
        mapTopicRecordings[topic.id] = {
          duration: session.questionDurations[ind],
          recordings: session.recordings.filter((recordingId) => recordingId.indexOf(`${qid}_part`) >= 0),
        };

        return;
      }

      mapTopicRecordings[topic.id] = {
        duration: mapTopicRecordings[topic.id].duration + session.questionDurations[ind],
        recordings: [
          ...mapTopicRecordings[topic.id].recordings,
          ...session.recordings.filter((recordingId) => recordingId.indexOf(`${qid}_part`) >= 0),
        ],
      };

    });

    // prepare the final topics result
    const result = Object.entries(mapTopicRecordings)
      .sort((a, b) => b[1].duration - a[1].duration)
      .map(([id, topicSession]) => {
        const finalRecordings = topicSession.recordings.map((rid) => mapRecordings[rid]).filter(a => a).sort((a, b) => {
          const aDate = a.createdAt?.value ? new Date(a.createdAt.value) : null;
          const bDate = b.createdAt?.value ? new Date(b.createdAt.value) : null;

          return (!aDate || aDate < bDate) ? -1 : (!bDate || aDate > bDate) ? 1 : 0;
        });

        return {
          id,
          topic: (mapTopics.get(id) || { text: '-' }).text,
          duration: topicSession.duration,
          transcript: finalRecordings.map((r) => (r.transcription || '').trim()).filter((t) => t.length > 0).join('. '),
          recordings: finalRecordings,
        };
      });

    return [result, _totalDuration];
  }, [session, mapQuestionToTopics, mapRecordings]);

  return (
    <div className={`session-journal ${className}`}>
      <div className="session-journal__header">
        <div className="session-journal__row">
          <div className="journal-topic">Journal Topics</div>
          <div className="transcript-preview">Transcript Preview</div>
          <div className="audio-clip">Audio Clip</div>
        </div>
      </div>

      <div className="session-journal__body">
        {topics.map((item, ind) => (
          <div
            className={`session-journal__row ${viewTranscriptionId === item.id ? 'visible-transcription' : ''}`}
            key={item.id}
          >
            <JournalTopic
              ind={ind}
              topic={item.topic}
              duration={item.duration}
              totalDuration={totalDuration}
            />
            <div className="transcript-preview">
              <span onClick={() => console.log(item)} className="text">{item.transcript}</span>
              <button
                className={`btn-img btn-right ${viewTranscriptionId === item.id ? 'rotate' : ''}`}
                onClick={() => setViewTranscriptId(viewTranscriptionId === item.id ? null : item.id)}
              >
                <SvgArrowRight />
              </button>
            </div>
            <div className="audio-clip">
              {item.recordings[0] && (
                <Audio
                  key={`${item.recordings[0].id}`}
                  style={{ width: '50' }}
                  recordings={item.recordings}
                  partner={partner}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SessionJournal.propTypes = {
  className: PropTypes.string,
  session: PropTypes.any,
  recordings: PropTypes.any,
  topicQuestions: PropTypes.any,
};

export default SessionJournal;
