import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LinearProgress, Typography } from '@material-ui/core';

import html2canvas from 'html2canvas';

import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import ScreenshotDoc from '../common/reports/pdf/patientScreenshot';
import ScreenshotGraph from '../common/reports/pdf/graphScreenshot';

import { formatDate } from '../../components/Date';

import Top from './components/top/top';
import Middle from './components/middle/middle';
import Recent from './components/recent/recent';
import Bottom from './components/bottom/bottom';
import BottomDisclaimerPage from '../common/BottomDisclaimerPage';

import FollowedBySelect from '../common/form/FollowedBySelect';

class PatientComponent extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      userId: match.params.userId,
      loadingFile: false
    };
  }

  componentDidMount() {
    const { getUserDetails, getTopicQuestions, portalConfig } = this.props;
    const partnerList = portalConfig.portalActivePartners;
    const { userId } = this.state;

    /**
     * We save the current page to sessionStorage
     * WHY? If the user goes back to home page, we know that the previous page was "details"
     */

    sessionStorage.setItem('page', 'patient');

    getUserDetails(userId, partnerList, {}, [], [], {}, [], []);
    getTopicQuestions();
    // The original call would be getUserDetails(userId, sort, sortOrder, filters, sortRecordings, sortRecordingsOrder, filterRecordings);
    // Right now, for the demo, we keep the same request as the previous dashboard.
  }

  downloadCsv = data => {
    const { portalConfig, downloadCsv } = this.props;
    const { columnsName } = portalConfig;

    data.showColumns = [
      'name',
      'mrn',
      'startTimeStamp',
      'phq_r',
      'gad_r'
      // 'phqTotal',
      // 'gadTotal'
    ];
    data.columnsName = columnsName;

    // send also the role of the clinician and configurations
    const { configurations, role } = portalConfig;
    const config = { configurations, role };
    data.config = config;
    //-----------------------------------
    downloadCsv(data);
  };

  createCSV = (data, headers, fileName) => {
    this.setState({
      loadingFile: true
    });

    const csvRows = [];
    // get the temp headers (not user friendly, these are the columns name of the db)
    csvRows.push(headers.join('\t'));

    // loop over the rows
    // eslint-disable-next-line no-restricted-syntax
    for (const row of data) {
      // form escaped comma separated values
      csvRows.push(row.join('\t'));
    }

    // Create a blob that is needed to download data
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' }); // csvRows.join('\n') go to next line with every row
    // Send the blob to the browser
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    this.setState({
      loadingFile: false
    });

  };

  downloadGraphScreenshot = async (type) => {
    const { patientState, portalConfig } = this.props;
    const { partnerTimezone } = portalConfig;

    this.setState({
      loadingFile: true
    });

    const data = {
      top1: '',
      top2: '',
      middle: '',
      bottom: []
    };
    await html2canvas(document.getElementById(type), {
      scale: '1'
    }).then(canvas => {
      data.middle = canvas.toDataURL('image/png');
    });

    console.log('Preparing PDF');

    const blob = await pdf(<ScreenshotGraph data={data} />).toBlob();
    saveAs(
      blob,
      `Ellipsis_${type}GraphScreenshot_${patientState.patient?.name ?? ''}${patientState.patient?.lastName ?? ''
      }_${formatDate(new Date(), 'DD_MM_YYYY_hh_mm_ss_A_z', partnerTimezone)}.pdf`
    );
    this.setState({
      loadingFile: false
    });
  };

  downloadScreenshot = async () => {
    const { patientState, portalConfig } = this.props;
    const sessions = patientState.patientSessions;
    const { partnerTimezone } = portalConfig;

    this.setState({
      loadingFile: true
    });

    const data = {
      top1: '',
      top2: '',
      middle: '',
      bottom: []
    };
    await html2canvas(document.getElementById('topContOne'), {
      scale: '1'
    }).then(canvas => {
      data.top1 = canvas.toDataURL('image/png');
    });
    await html2canvas(document.getElementById('topContTwo'), {
      scale: '1'
    }).then(canvas => {
      data.top2 = canvas.toDataURL('image/png');
    });
    await html2canvas(document.getElementById('middleContOne'), {
      scale: '1'
    }).then(canvas => {
      data.middle = canvas.toDataURL('image/png');
    });

    // Paginate the different sessionBox png in arrays. 4 elements x array
    await Promise.all(
      sessions.map(async (session, i) => {
        return Promise.all([
          await html2canvas(
            document.getElementById(`session-${session.uid}`)
          ).then(canvas => {
            data.bottom.push(canvas.toDataURL('image/png'));
          })
        ]);
      })
    );

    console.log('Preparing PDF');
    console.log(patientState);
    const blob = await pdf(<ScreenshotDoc data={data} />).toBlob();
    saveAs(
      blob,
      `Ellipsis_PatientScreenshot_${patientState.patient?.name ?? ''}${patientState.patient?.lastName ?? ''
      }_${formatDate(new Date(), 'DD_MM_YYYY_hh_mm_ss_A_z', partnerTimezone)}.pdf`
    );
    this.setState({
      loadingFile: false
    });
  };

  updateSessionInfo = data => {
    const { saveSessionInfo } = this.props;
    saveSessionInfo(data);
  };

  changeFollowedby = value => {
    /**
     * CHANGE OF THE FOLLOWEDBY CONTROL
     * When the followedBy field is changed, the followed action are performed here:
     * 1- SetFollowedBy method called to set the new email
     * 2- Load again data based on the new filter
     */
    const {
      getPatientsData,
      getSessionData,
      onboardState,
      homeState,
      portalConfig,
      setFollowedby,
      setTotalPatients,
      history
    } = this.props;

    const partnerList = portalConfig.portalActivePartners;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;
    let filters = [];
    if (value) {
      filters = [
        {
          field: 'assignedAdminId',
          operator: '=',
          value: `${value}`,
          type: 'String'
        }
      ];
    }
    setFollowedby(value);
    setTotalPatients();

    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      filters
    );

    /**
     * Change followedby general filter:
     * N.B (!!)
     * 1) If the user is inside a patient details page (E.g, /patient/HXfgtw45jk)
     * redirect to session page.
     */
    if (window.location.pathname.startsWith('/patient')) {
      if (history.location.state.from === '/sessions') {
        history.push('/sessions?page=1&v=all');
      } else if (history.location.state.from === '/onboard') {
        history.push('/onboard?page=3&load=false');
      }
    }
  };

  render() {
    const {
      history,
      loginState,
      homeState,
      patientState,
      updateUserDetails,
      portalConfig,
      download,
      savingSessionInfo,
      savingText,
      loadRecordings,
      recordings,
      topicQuestions
    } = this.props;
    const { role, configurations } = portalConfig;
    const { partnerTimezone } = configurations;
    const { user: { email } } = loginState;
    const config = { role, email, timezone: partnerTimezone };
    const sessions = patientState.patientSessions;
    const { loadingFile } = this.state;
    return (
      <div style={{ padding: '0px 0px 20px 0px' }}>
        {/* <BackButton history={history} path="/home" /> */}
        <div
          style={{
            width: '100%',
            height: isMobile ? '60px' : '40px',
            backgroundColor: '#ebe3c8',
            display: 'flex',
          }}
        >
          <Typography
            style={{
              margin: 'auto',
              marginLeft: 8,
              display: 'flex',
              color: '#000000'
            }}
          >
            Show patients followed by: &nbsp;
            <FollowedBySelect
              partner={portalConfig.portalActivePartners[0]}
              user={loginState}
              followedby={
                homeState.filters.length > 0 ? homeState.filters[0].value : null
              }
              onChange={value => this.changeFollowedby(value)}
              loadingData={loadRecordings}
            />
          </Typography>
        </div>
        {!patientState.showSpinner ? (
          <>
            <Top
              info={patientState.patient}
              sessions={sessions}
              clinician={`${portalConfig.firstName} ${portalConfig.lastName}`}
              displayName={portalConfig.displayName}
              showEHResults={portalConfig.showEHResults}
              columnsToShow={portalConfig.showSessionsColumns ?? []}
              columnsName={portalConfig.columnsName ?? {}}
              updateUserDetails={updateUserDetails}
              downloadScreenshot={this.downloadScreenshot}
              downloadGraphScreenshot={this.downloadGraphScreenshot}
              loadingFile={loadingFile}
              createCSV={this.createCSV}
              loadRecordings={loadRecordings}
              downloadCsv={this.downloadCsv}
              download={download}
              config={config}
            />
            {sessions.length ? <Recent session={sessions[0]} columnsToShow={portalConfig.showSessionsColumns ?? []} /> : <></>}
            <Middle showEHResults={portalConfig.showEHResults} sessions={sessions} columnsToShow={portalConfig.showSessionsColumns ?? []} />
            <Bottom
              info={patientState.patient}
              sessions={sessions}
              role={role}
              timezone={partnerTimezone}
              showEHResults={portalConfig.showEHResults}
              hideRecordings={portalConfig.hideRecordings}
              columnsToShow={portalConfig.showSessionsColumns ?? []}
              update={this.updateSessionInfo}
              saving={savingSessionInfo}
              savingText={savingText}
              loadRecordings={loadRecordings}
              recordings={recordings}
              createCSV={this.createCSV}
              topicQuestions={topicQuestions}
            />
            <BottomDisclaimerPage />
          </>
        ) : (
          <div style={{ width: '100%' }}>
            <LinearProgress />
          </div>
        )}
      </div>
    );
  }
}

PatientComponent.propTypes = {
  getUserDetails: PropTypes.func,
  getTopicQuestions: PropTypes.func,
  patientState: PropTypes.object,
  portalConfig: PropTypes.object,
  downloadCsv: PropTypes.func,
  download: PropTypes.bool,
  saveSessionInfo: PropTypes.func,
  savingSessionInfo: PropTypes.bool
};

export default PatientComponent;
