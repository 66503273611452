/* eslint-disable no-bitwise */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Box, Card } from '@material-ui/core';
import io from 'socket.io-client';

class EHStreamData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recording: false,
      transcript: '...',
      complete: '',
      wordCount: 0,
      measures: {}
    };

    this.data = {
      url: props.url,
      subscriptions: props.subscriptions,
      token: props.token
    };

    this.processResponse = this.processResponse.bind(this);
    this.initSocketConnection = this.initSocketConnection.bind(this);
    this.startRecording = this.startRecording.bind(this);

    this.onStart = props.onStart;
    this.onPause = props.onPause;
    this.onResume = props.onResume;
    this.onStop = props.onStop;
    this.onError = props.onError;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.start && !this.props.start) {
      this.initSocketConnection();
      this.onStart();
    }
    if (nextProps.pause && !this.props.pause) {
      this.setState({ recording: false });
      this.onPause();
    }
    if (
      !nextProps.pause &&
      this.props.pause &&
      this.props.start &&
      !this.props.stop
    ) {
      this.setState({ recording: true });
      this.onResume();
    }
    if (nextProps.stop && !this.props.stop) {
      this.setState({ recording: false });
      this.onStop();
    }
  }

  processResponse(data) {
    if (data.type === 'measures' && data.measures)
      this.setState({ measures: data.measures });
    if (data.type === 'wordCount' && data.wordCount)
      this.setState({ wordCount: data.wordCount });
    if (data.text) {
      this.setState({
        transcript: `... ${  data.text.slice(-30)}`,
        complete: `${this.state.complete  } ${  data.text}`
      });
      console.log(`complete transcript: ${  this.state.complete}`);
    }

    this.props.onStreamResponse({
      transcript: this.state.transcript,
      wordCount: this.state.wordCount,
      measures: this.state.measures
    });
  }

  initSocketConnection() {
    console.log(
      `EHStreamData: init connection data = ${JSON.stringify(this.data)}`
    );
    const payload = {
      transports: ['websocket'],
      auth: {
        token: this.data.token
      },
      query: {
        partnerId: 'ELLIPSIS',
        subscriptions: JSON.stringify(this.data.subscriptions),
        sessionId: this.props.sessionId,
        sessionSecret: this.props.sessionSecret
      }
    };

    console.log(
      `EHStreamData: init connection payload = ${JSON.stringify(payload)}`
    );
    const _this = this;
    const socketio = io(this.data.url, payload);

    this.socket = socketio.on('connect', function() {
      console.log('EHStreamData: socket connected');
    });

    this.socket.on('session-results', function(data) {
      if (!_this.state.recording) return;
      _this.processResponse(data);
    });

    this.socket.on('session-started', function(data) {
      console.log('EHStreamData: session started');
      console.log(data);
      _this.sessionId = data.sessionId;
      _this.sessionSecret = data.sessionSecret;

      // reuse the session when reconnected
      _this.socket.io.opts.query.sessionId = _this.sessionId;
      _this.socket.io.opts.query.sessionSecret = _this.sessionSecret;

      // this is the only difference between the patient and provider
      _this.startRecording();
    });

    this.socket.on('connect_error', err => {
      console.log('EHStreamData: connect error');
      console.log(err);
      this.onError(err);
    });

    this.socket.on('session-error', err => {
      console.log('EHStreamData: session error');
      console.log(err);
      this.onError(err);
    });
  }

  startRecording() {
    this.setState({ recording: true });
  }

  render() {
    return (
      <div>
        <Card style={{ textAlign: 'center', padding: '1rem' }}>
          {this.state.transcript}
        </Card>
        <Card style={{ padding: 12, marginTop: 16 }}>
          <Box p={1} width={1 / 2} color="black" bg="secondary">
            words: {this.state.wordCount}
          </Box>
          <Box p={1} width={1 / 2} color="black" bg="primary">
            anxietyScore: {this.state.measures?.anxietyScore | 0}
          </Box>
          <Box p={1} width={1 / 2} color="black" bg="primary">
            depressionScore: {this.state.measures?.depressionScore | 0}
          </Box>
        </Card>
      </div>
    );
  }
}

export default EHStreamData;
