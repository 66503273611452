// import fetch from 'cross-fetch';
import firebase from '../../../config/firebase';
import {
  requestUserDetails,
  receiveUserDetails,
  requestRecordings,
  receiveRecordings,
  requestSaveSessionInfo,
  savedSessionInfo,
  requestTopicQuestions,
  receiveTopicQuestions

} from './actions';

const saveLogs = data => {
  const saveLogsFunction = firebase
    .functions()
    .httpsCallable('dashboardFunctions-saveLogs');
  saveLogsFunction(data)
    .then(function (result) {
      if (result.data !== 'success') {
        console.log(
          `Something wrong happened while saving logs.`
        );
      }
    })
    .catch(function (error) {
      console.error('Error saving logs: ', error.message);
    });
};

const getSessionRecordings = ({
  partner,
  sessionIds,
  sortRecordings,
  sortOrderRecordings,
  recordingFilters
}) => {
  return dispatch => {
    dispatch(requestRecordings());

    const sessionRecordings = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_sessionRecordings');
    sessionRecordings({
      partner,
      sessionIds,
      sortRecordings,
      sortOrderRecordings,
      recordingFilters,
    })
      .then(function (result) {
        const { recordings, query } = JSON.parse(result.data);

        dispatch(
          receiveRecordings(recordings, sortRecordings, sortOrderRecordings)
        );

        saveLogs({
          type: 'query/bigQuery',
          success: `Returned recordings count: ${recordings.length} for the following sessionIds ${sessionIds.join(", ")}`,
          text: query,
        });
      })
      .catch(function (error) {
        console.log('Error getting user details:', error);
        // dispatch() --> TODO dispatch error method that will give feedback to the user
      });
  };
};

const getUserDetails = (
  userId,
  partnerList,
  sort,
  sortOrder,
  filters,
  sortRecordings,
  sortOrderRecordings,
  recordingFilters
) => {
  return dispatch => {
    dispatch(requestUserDetails());

    const userDetails = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_userData');
    userDetails({ userId, partnerList, sort, sortOrder, filters })
      .then(function (result) {
        const { sessions = [], query } = JSON.parse(result.data);
        const patientSessions = sessions.map(
          session => {
            return {
              ...session,
              phq_r: session.phq_r,
              gad_r: session.gad_r
            };
          }
        );
        const patient = JSON.parse(result.data).user ?? {};

        dispatch(receiveUserDetails(patient, patientSessions, sort, sortOrder));

        // Call the next function to get all the recordings
        // First, prepare a new array with all the sessionIds
        const sessionIds = [];
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const s in patientSessions) {
          sessionIds.push(patientSessions[s].uid);
        }

        dispatch(
          getSessionRecordings({
            partner: partnerList[0],
            sessionIds,
            sortRecordings,
            sortOrderRecordings,
            recordingFilters,
          })
        );

        saveLogs({
          type: 'query/bigQuery',
          success: `Success. ${sessions.length} sessions`,
          text: query,
        });
      })
      .catch(function (error) {
        console.log('Error getting user details:', error);
        // dispatch() --> TODO dispatch error method that will give feedback to the user
      });
  };
};

const saveSessionInfo = data => {
  return dispatch => {
    dispatch(requestSaveSessionInfo());

    const sessionId = data.session.uid;
    const { sessions } = data;

    firebase
      .firestore()
      .collection('sessionStore')
      .doc(sessionId)
      .set({ comment: data.comment }, { merge: true })
      .then(function () {
        console.log('Session info successfully saved!');

        for (let i = 0; i < sessions.length; i += 1) {
          if (sessions[i].uid === data.session.uid) {
            sessions[i].comment = data.comment;
            break;
          }
        }

        dispatch(savedSessionInfo(sessions, 'Saved!'));

        saveLogs({
          type: 'saveInfo/Session',
          success: `Success`,
          text: `Successfully saved comment ${data.comment} for session ${sessionId}`,
        });

      })
      .catch(function (error) {
        console.error(
          'Error writing session info in document: ',
          error.message
        );

        dispatch(savedSessionInfo(sessions, 'Not saved!'));
      });
  };
};

const getTopicQuestions = () => {
  return dispatch => {
    dispatch(requestTopicQuestions());

    const userDetailsCall = firebase
      .functions()
      .httpsCallable('dashboardFunctions-getTopicQuestions');

    userDetailsCall()
      .then((result) => {
        dispatch(receiveTopicQuestions(result.data));
      })
      .catch((error) => {
        console.log('Error getting user details:', error);
        // dispatch() --> TODO dispatch error method that will give feedback to the user
      });
  };
};

export default {
  getUserDetails,
  getSessionRecordings,
  saveSessionInfo,
  getTopicQuestions
};