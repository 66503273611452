import { connect } from 'react-redux';
import LoginComponent from './LoginComponent';
import { loginOperations /* loginActions */ } from './redux';

const mapStateToProps = state => {
  const {
    portalConfig,
    showLoginSpinner,
    isLoggedIn,
    multi_partner,
    error,
    snackbar
  } = state.login;

  return {
    portalConfig,
    role: portalConfig.role,
    userPartnerList: portalConfig.userPartnerList,
    showLoginSpinner,
    isLoggedIn,
    multi_partner,
    error,
    snackbar
  };
};

const mapDispatchToProps = dispatch => {
  const getUserData = (user, password) =>
    dispatch(loginOperations.getUserJson(user, password));
  const getPortalConfig = partners => dispatch(loginOperations.getPortalConfig(partners));
  const logout = () => dispatch(loginOperations.logUserOut());

  const getUserJsonWithToken = (token) => dispatch(loginOperations.getUserJsonWithToken(token));

  return { getUserData, getUserJsonWithToken, getPortalConfig, logout };
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);

export default LoginContainer;
