import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { acceptedAlertRoles } from './routesObj'

const AlertRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isLoggedIn ? (
        acceptedAlertRoles.includes(rest.role) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/home' }} />
        )
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
);

AlertRoute.propTypes = {
    component: PropTypes.element,
};

export default AlertRoute;
