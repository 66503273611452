import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Collapse,
  MenuItem,
  Typography,
  LinearProgress
} from '@material-ui/core';

const styles = theme => ({
  newUserForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3)
  },
  collapse: {
    width: '60%',
    [theme.breakpoints.down(600)]: {
      width: '90%'
    }
  },
  button: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '60%'
  },
  phoneTextField: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    margin: theme.spacing(2),
    bottom: 10,
    width: '90%'
  },
  paper: {
    marginTop: theme.spacing(3)
  }
});

class ClinicianForm extends React.Component {
  constructor(props) {
    super(props);

    const { email, phone, firstName, lastName, displayName, role } = props;

    this.state = {
      formOpen: false,
      email,
      phone,
      firstName,
      lastName,
      displayName,
      emailError: false,
      emailErrorMessage: '',
      phoneError: false,
      phoneErrorMessage: '',
      role,
      showSnackbar: false,
      edited: false
    };

    this.buttonClick = this.buttonClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { snackbar, closeForm, open, email } = this.props;
    const { formOpen } = this.state;

    if (
      snackbar.variant === '' &&
      newProps.snackbar.variant !== ''
    ) {
      if (newProps.snackbar.variant === 'success') {
        this.setState(
          {
            email: ''
          },
          () => {
            // Call the method of the parent to close the create/edit form
            closeForm();
          }
        );
      }
    }

    if (open !== newProps.open) {
      /**
       * The first mount of the component has empty values. In order to re render the component,
       * the state has to change. The code updates the state when the open props changes.
       */
      this.setState({
        formOpen: !formOpen,
        email: newProps.email,
        phone: newProps.phone,
        firstName: newProps.firstName,
        lastName: newProps.lastName,
        displayName: newProps.displayName,
        role: newProps.role,
        edited: false
      });
    } else if (email !== newProps.email) {
      /**
       * - The create/edit dialog is still open but the user decides to click on edit another user
       * - If the previous user has not been edited, refresh the component
       */
      this.setState({
        email: newProps.email,
        phone: newProps.phone,
        firstName: newProps.firstName,
        lastName: newProps.lastName,
        displayName: newProps.displayName,
        role: newProps.role,
        edited: false,
        phoneError: false,
        emailError: false
      });
    }
  }

  handleChangeEmail = event => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      this.setState({
        email: event.target.value,
        emailError: false,
        emailErrorMessage: '',
        edited: true
      });
    } else {
      this.setState({
        email: event.target.value,
        emailError: true,
        emailErrorMessage: 'Check the email format'
      });
    }
  };

  handleChangePhone = event => {
    const re = /^([0-9]{3})?([0-9]{3})?([0-9]{4})$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      this.setState({
        phone: event.target.value,
        phoneError: false,
        phoneErrorMessage: '',
        edited: true
      });
    } else {
      this.setState({
        phone: event.target.value,
        phoneError: true,
        phoneErrorMessage: 'Check the phone format'
      });
    }

    if (event.target.value.length < 1) {
      this.setState({
        phoneError: false,
        phoneErrorMessage: ''
      });
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      edited: true
    });
  };

  buttonClick() {
    const { phone } = this.state;
    const { buttonClick } = this.props;

    buttonClick({
      ...this.state,
      phone: `+1${phone}`
    });
  }

  render() {
    const { classes, configurations, showSpinner, buttonTitle, emailDisabled } = this.props;
    const { phq9Config } = configurations;

    const {
      emailError,
      emailErrorMessage,
      phoneError,
      phoneErrorMessage,
      role,
      firstName,
      lastName,
      email,
      displayName,
      phone,
      edited,
      formOpen
    } = this.state;

    return (
      <Collapse className={classes.collapse} in={formOpen}>
        <Paper className={classes.newUserForm}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={this.handleChange('firstName')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={this.handleChange('lastName')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="email"
            value={email}
            onChange={this.handleChangeEmail}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            disabled={emailDisabled}
            error={emailError}
            helperText={emailErrorMessage}
          />
          <TextField
            label="Display Name"
            placeholder="display name (including credentials)"
            value={displayName}
            onChange={this.handleChange('displayName')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            // disabled={this.props.emailDisabled}
            // error={emailError}
            // helperText={emailErrorMessage}
          />

          <div style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
            <TextField
              style={{ width: 50 }}
              disabled
              label="+1"
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="phone"
              value={phone}
              style={{ marginLeft: 5 }}
              onChange={this.handleChangePhone}
              className={classes.phoneTextField}
              margin="normal"
              variant="outlined"
              error={phoneError}
              helperText={phoneErrorMessage}
            />
          </div>

          <TextField
            select
            label="Select Role"
            className={classes.textField}
            value={role}
            onChange={this.handleChange('role')}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            helperText="Please select the new user's role"
            margin="normal"
            variant="outlined"
          >
            {role === 'super' ? (
              <MenuItem key="super" value="super">
                Super
              </MenuItem>
            ) : (
              ''
            )}
            <MenuItem key="admin" value="admin">
              Practice Manager
            </MenuItem>
            <MenuItem key="clinician" value="clinician">
              Clinician
            </MenuItem>
            <MenuItem key="viewer" value="viewer">
              Viewer
            </MenuItem>
            {phq9Config && phq9Config.phq9Alert ? (
              <MenuItem key="crisisteammember" value="crisisteammember">
                Crisis Team Member
              </MenuItem>
            ) : ""}
          </TextField>

          <Typography
            style={{ marginTop: 5, fontSize: '12px', width: '60%' }}
            align="center"
            variant="body1"
          >
            Clinician is the most common role, and allows for monitoring, adding
            and removing of patients. The Practice Manager role includes all
            Clinician features plus adding and removing Staff, while Viewer
            role shows sessions without PHI.
            {phq9Config && phq9Config.phq9Alert ? " The Crisis Team Member role allows only to receive PHQ9 Alerts." : ""}
          </Typography>

          {showSpinner ? <LinearProgress className={classes.progress} /> : null}

          
          <Button
            disabled={!(!emailError &&
          !showSpinner &&
          !phoneError &&
          email !== '' &&
          firstName !== '' &&
          lastName !== '' &&
          phone !== '' &&
          displayName !== '' &&
          edited === true)}
            style={{ margin: 10 }}
            variant="contained"
            color="secondary"
            onClick={this.buttonClick}
          >
            {buttonTitle}
          </Button>
        </Paper>
      </Collapse>
    );
  }
}

export default withStyles(styles)(ClinicianForm);
