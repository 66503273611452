import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Typography,
  Select,
  FormControl
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import LableValue from './label';

import { formatDate } from '../../../../components/Date';

import AssessmentDoc from '../../../common/reports/pdf/d&a_assessment';
import ClinicianSelect from '../../../common/form/ClinicianSelect';
import { getCustomSurveys } from '../../../common/form/getCustomSurveys';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 8,
    width: '100%',
    [theme.breakpoints.down(850)]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  cont_one: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '25%',
    minWidth: 200
  },
  cont_two: {
    height: '50%', // best 75
    // width: '40%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#b8b8b8',
    padding: 6,
    minWidth: 300,
    marginBottom: 10
  },
  cont_three: {
    height: '100%',
    // width: '30%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    minWidth: 200
  },
  cont_three_content: {
    // width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: 20
    }
  },
  button: {
    height: 50,
    width: 150,
    marginTop: 8,
    backgroundColor: theme.palette.primary.main_two,
    color: '#f4f4f4'
  },
  loading: {
    height: 50,
    marginTop: 8
  },
  buttonPdfReady: {
    height: 50,
    marginTop: 8,
    backgroundColor: '#008000',
    '&:hover': {
      backgroundColor: '#008000',
      transition: 'none'
    }
  },
  buttonCSV: {
    height: 30,
    width: 20,
    marginLeft: 10,
    backgroundColor: theme.palette.primary.main_two,
    color: '#f4f4f4'
  },
  text: {
    color: theme.palette.text.main
  },
  link: {
    textDecoration: 'none'
  },
  followedBy: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  }
});

function ButtonComponent(props) {
  const { onClick, loading, disabled, style } = props;
  return (
    <Button
      className={style}
      color="secondary"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {loading && <CircularProgress size={14} />}
      {!loading && 'GO'}
    </Button>
  );
}

const prepareDepressionValue = (session, has4WayClassification) => {

  if (has4WayClassification) {
    /**
     * phq_4c could present 4 values: 0, 1, 2, 3
     * 0 --> Values between 0 and 5
     * 1 --> Values between 5 and 10
     * 2 --> Values between 10 and 15
     * 3 --> Values above 15
     */
    if (session.phq_4c === null || session.phq_4c < 0) { return 'Incomplete' }

    return session.phq_4c === 0 ? "None" :
      session.phq_4c === 1 ? "Mild" :
        session.phq_4c === 2 ? "Moderate" : "Severe"
  }

  const depressionValue = session.phq_r;
  if (depressionValue === null || typeof depressionValue === 'undefined') {
    return 'Incomplete'
  }

  if (depressionValue < 0) {
    return 'Incomplete'
  }
  if (depressionValue > 24) {
    return '24'
  }

  return `${Math.ceil(depressionValue).toString()}`
}

const prepareAnxietyValue = (session, has4WayClassification) => {
  if (has4WayClassification) {
    /**
     * phq_4c could present 4 values: 0, 1, 2, 3
     * 0 --> Values between 0 and 5
     * 1 --> Values between 5 and 10
     * 2 --> Values between 10 and 15
     * 3 --> Values above 15
     */
    if (session.gad_4c === null || session.gad_4c < 0) { return 'Incomplete' }

    return session.gad_4c === 0 ? "None" :
      session.gad_4c === 1 ? "Mild" :
        session.gad_4c === 2 ? "Moderate" : "Severe"
  }

  const anxietyValue = session.gad_r;
  if (anxietyValue === null || typeof anxietyValue === 'undefined') {
    return 'Incomplete'
  }

  if (anxietyValue < 0) {
    return 'Incomplete'
  }
  if (anxietyValue > 21) {
    return '21'
  }

  return `${Math.ceil(anxietyValue).toString()}`
}

function Top({
  classes,
  info,
  sessions,
  updateUserDetails,
  clinician,
  displayName,
  columnsToShow,
  columnsName,
  config,
  showEHResults,
  downloadScreenshot,
  downloadGraphScreenshot,
  loadingFile,
  createCSV,
  loadRecordings
}) {
  const [exportfile, setExport] = React.useState('CSV1');
  const [loading, setLoading] = React.useState(false);
  const [surveyNames, setSurveyNames] = React.useState([]);

  const { role, email, timezone } = config;

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const resp = await getCustomSurveys();
        const surveys = [];
        resp.forEach(s => {
          surveys[s.id] = s.name;
        });
        setSurveyNames(surveys);
      } catch (e) {
        // silence error
        console.error(e);
      }
      setLoading(false);
    };

    loadData();
  }, [clinician]);

  const exportFile = async () => {
    // Check if EH results must be shown
    const ehResults = typeof showEHResults === 'undefined' || showEHResults;
    // Check if the user has 4 way classification (phq_4c and gad_4c) in the session table columns
    // In that case, show FourWayAssessmentChart
    const has4WayClassification = (columnsToShow.indexOf("phq_4c") !== -1 || columnsToShow.indexOf("gad_4c") !== -1);

    const surveyMap = [];
    const surveyListBetweenSessions = [];
    const dataXSession = [];
    let surveysMaxLength = 9; // Default value to PHQ9
    let isPHQ9present = false;

    const headersCSV1 = [
      'Patient Name',
      'MRN',
      'Date & Time',
      'GAD-7',
      'PHQ-8'
    ];
    const headersCSV1PHQ9 = [
      'Patient Name',
      'MRN',
      'Date & Time',
      'GAD-7',
      'PHQ-8',
      'PHQ-9',
      'PHQ-9 Q:9'
    ];

    if (ehResults) {
      headersCSV1.splice(3, 0, 'EH Depression');
      headersCSV1.splice(4, 0, 'EH Anxiety');
      headersCSV1PHQ9.splice(3, 0, 'EH Depression');
      headersCSV1PHQ9.splice(4, 0, 'EH Anxiety');
    }

    const headersCSV2 = ['Date & Time', 'Instrument', 'Total Score'];
    const dataToExport = [];

    let blob = null;

    switch (exportfile) {
      case 'CSV1':
        setLoading(true);
        sessions.forEach((s, i) => {
          dataXSession[i] = {
            name: `${info.name ?? ''} ${info.lastName ?? ''}`,
            mrn: info.mrn,
            date_time: formatDate(s.startTimeStamp?.value, 'L LT z', timezone),
            ...(ehResults && { EH_Depression: prepareDepressionValue(s, has4WayClassification), EH_Anxiety: prepareAnxietyValue(s, has4WayClassification) }),
            PHQ8:
              s.phqStatus === 'COMPLETE'
                ? s.phq9 && s.phqScores?.length > 8
                  ? s.phqTotal - s.phqScores[8]
                  : s.phqTotal
                : s.phqStatus,
            PHQ9:
              s.phq9Status && s.phq9Status === 'COMPLETE' && s.phq9 ? s.phqTotal : s.phq9Status,
            PHQQ9:
              s.phq9Status && s.phq9Status === 'COMPLETE' && s.phq9
                ? s.phqScores[8]
                : s.phq9Status,
            GAD: s.gadStatus === 'COMPLETE' ? s.gadTotal : s.gadStatus,
            surveysResults: [],
            phq9: s.phq9 || false
          };

          s.custom.forEach(item => {
            if (!surveyMap[item.survey]) {
              surveyMap[item.survey] = true;
              surveyListBetweenSessions.push(item.survey);
            }

            dataXSession[i].surveysResults[item.survey] = item.score
              ? item.score
              : 'INCOMPLETE';
          });

          s.customStatus.forEach(value => {
            if (!surveyMap[value.survey]) {
              surveyMap[value.survey] = true;
              surveyListBetweenSessions.push(value.survey);
            }

            if (
              dataXSession[i].surveysResults[value.survey] &&
              value.status !== 'COMPLETE'
            ) {
              dataXSession[i].surveysResults[value.survey] = value.status;
            } else if (!dataXSession[i].surveysResults[value.survey]) {
              dataXSession[i].surveysResults[value.survey] = 'INCOMPLETE';
            }
          });
        });

        dataXSession.forEach((d, i) => {
          dataToExport[i] = [
            `"${d.name}"`,
            `"${d.mrn}"`,
            `"${d.date_time}"`,
            `"${d.GAD}"`,
            `"${d.PHQ8}"`
          ];

          if (ehResults) {
            dataToExport[i].splice(3, 0, `"${d.EH_Depression}"`);
            dataToExport[i].splice(4, 0, `"${d.EH_Anxiety}"`);
          }

          if (d.phq9) {
            dataToExport[i].push(`"${d.PHQ9}"`, `"${d.PHQQ9}"`);
            if (!isPHQ9present) {
              isPHQ9present = true;
            }
          }

          surveyListBetweenSessions.forEach((s, index) => {
            if (d.surveysResults[s]) {
              dataToExport[i].push(`"${d.surveysResults[s]}"`);
            } else {
              dataToExport[i].push(`""`);
            }
          });
        });

        surveyListBetweenSessions.forEach((s, index) => {
          if (surveyNames[s]) {
            surveyListBetweenSessions[index] = surveyNames[s];
          }
        });

        createCSV(
          dataToExport.reverse(),
          isPHQ9present
            ? headersCSV1PHQ9.concat(surveyListBetweenSessions)
            : headersCSV1.concat(surveyListBetweenSessions),
          `Ellipsis_${info.name ?? 'no_name_'}${info.lastName ?? 'no_last_name'}_${formatDate(new Date(), 'DD_MM_YYYY_hh_mm_ss_A_z', timezone)}.csv`
        );

        setLoading(false);
        break;
      case 'CSV2':
        setLoading(true);
        sessions.forEach((s, i) => {
          const date = formatDate(s.startTimeStamp?.value, 'L LT z', timezone);

          if (ehResults) {
            dataToExport.push([
              `"${date}"`,
              `"EH Depression"`,
              `"${prepareDepressionValue(s, has4WayClassification)}"`
            ]);
            dataToExport.push([
              `"${date}"`,
              `"EH Anxiety"`,
              `"${prepareAnxietyValue(s, has4WayClassification)}"`
            ]);
          }

          if (s.phqStatus) {
            if (s.phq9) {
              dataToExport.push(
                [
                  `"${date}"`,
                  `"PHQ-9 Q:9"`,
                  `"${s.phq9Status && s.phq9Status === 'COMPLETE' ? s.phqScores[8] : s.phq9Status
                  }"`
                ].concat(
                  s.phq9Status === 'COMPLETE'
                    ? ['', '', '', '', '', '', '', '', s.phqScores[8]]
                    : []
                )
              );
              dataToExport.push(
                [
                  `"${date}"`,
                  `"PHQ-9"`,
                  `"${s.phq9Status && s.phq9Status === 'COMPLETE' ? s.phqTotal : s.phq9Status}"`
                ].concat(s.phqScores)
              );
              dataToExport.push(
                [
                  `"${date}"`,
                  `"PHQ-8"`,
                  `"${s.phqStatus === 'COMPLETE'
                    ? s.phq9Status === 'COMPLETE' ? s.phqTotal - s.phqScores[8] : s.phqTotal
                    : s.phqStatus
                  }"`
                ].concat(s.phqScores.filter((score, index) => index < 8))
              );
            } else {
              dataToExport.push(
                [
                  `"${date}"`,
                  `"PHQ-8"`,
                  `"${s.phqStatus === 'COMPLETE' ? s.phqTotal : s.phqStatus}"`
                ].concat(s.phqScores)
              );
            }
          }
          if (s.gadStatus) {
            dataToExport.push(
              [
                `"${date}"`,
                `"GAD-7"`,
                `"${s.gadStatus === 'COMPLETE' ? s.gadTotal : s.gadStatus}"`
              ].concat(s.gadScores)
            );
          }

          s.custom.forEach(item => {
            surveyMap[item.survey] = item;
          });

          s.customStatus.forEach(value => {
            if (
              surveyMap[value.survey] &&
              surveysMaxLength < surveyMap[value.survey].scores.length
            ) {
              surveysMaxLength = surveyMap[value.survey].scores.length;
            }

            if (value.status === 'COMPLETE') {
              dataToExport.push(
                [
                  `"${date}"`,
                  `"${surveyNames[value.survey]
                    ? surveyNames[value.survey]
                    : value.survey
                  }"`,
                  `"${surveyMap[value.survey] ? surveyMap[value.survey].score : ''
                  }"`
                ].concat(
                  surveyMap[value.survey] ? surveyMap[value.survey].scores : []
                )
              );
            } else {
              dataToExport.push([
                `"${date}"`,
                `"${surveyNames[value.survey]
                  ? surveyNames[value.survey]
                  : value.survey
                }"`,
                `"${value.status}"`
              ]);
            }
          });
        });

        for (let i = 0; i < surveysMaxLength; i += 1) {
          headersCSV2.push(`Q${i + 1}`);
        }

        createCSV(
          dataToExport.reverse(),
          headersCSV2,
          `Ellipsis_${info.name ?? 'no_name_'}${info.lastName ?? 'no_last_name'}_details_${formatDate(new Date(), 'DD_MM_YYYY_hh_mm_ss_A_z', timezone)}.csv`
        );

        setLoading(false);

        break;
      case 'PDF1':
        setLoading(true);

        blob = await pdf(
          <AssessmentDoc
            data={sessions}
            selected={sessions.map(n => n.uid)}
            user={info}
            config={{ showEHResults, has4WayClassification }}
            clinician={clinician}
            clinicianDisplayName={displayName}
          />
        ).toBlob();

        saveAs(
          blob,
          `Ellipsis_${info.name ?? 'no_name_'}${info.lastName ?? 'no_last_name'}_${formatDate(new Date(), 'DD_MM_YYYY_hh_mm_ss_A_z', timezone)}.pdf`
        );
        setLoading(false);
        break;
      case 'PDF2':
        downloadScreenshot();
        break;
      case 'PDF3':
        downloadGraphScreenshot('depressionScore');
        break;
      case 'PDF4':
        downloadGraphScreenshot('anxietyScore');
        break;
      case '':
        break;
      default:
      // code block
    }
  };

  return (
    <div className={classes.root}>
      <div id="topContOne" className={classes.cont_one}>
        {role !== 'viewer' ? (
          <>
            {columnsToShow.indexOf('name') !== -1 ? <LableValue name="First Name" value={info.name} /> : <></>}

            {columnsToShow.indexOf('lastName') !== -1 ? <LableValue name="Last Name" value={info.lastName} /> : <></>}

            <LableValue name={columnsName.mrn ?? 'MRN'} value={info.mrn} />
            {columnsToShow.indexOf('birthyear') !== -1 ? <LableValue name="Birth Year" value={info.birthyear} /> : <></>}

          </>
        ) : (
          ''
        )}
        <LableValue name="Total Sessions" value={sessions.length} />
      </div>
      <div id="topContTwo" className={classes.cont_two}>
        <Typography className={classes.text} variant="body1">
          {info.createdAt
            ? `Monitoring was set up on ${formatDate(info?.createdAt?.value, 'L LT z', timezone)}`
            : `Monitoring information not available`}
        </Typography>
        <Typography className={classes.text} variant="body1">
          {info.userType && info.userType === 'UserType.INCLINIC'
            ? 'Patient has registered with the Ellipsis Health In-Clinic app'
            : 'Patient has registered with the Ellipsis Health app'}
        </Typography>
        <Typography className={classes.text} variant="body1">
          {info.userType && info.userType === 'UserType.INCLINIC'
            ? `Patient has conducted ${sessions.length} in-clinic sessions`
            : `Patient has conducted ${sessions.length} personal sessions`}
        </Typography>
        <div className={classes.followedBy}>
          <Typography className={classes.text}>
            Followed By: &nbsp;&nbsp;
          </Typography>
          <ClinicianSelect
            patient={info}
            onChange={d => updateUserDetails(d)}
            role={role}
            email={email}
            shouldUpdate
          />
        </div>
      </div>
      {role !== 'viewer' ? (
        <div className={classes.cont_three}>
          <div className={classes.cont_three_content}>
            <Typography
              style={{ color: '#394f68', fontWeight: 'bold', marginRight: 10 }}
              variant="body1"
            >
              EXPORT:
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                value={exportfile} // state.age
                onChange={event => {
                  setExport(event.target.value);
                }} // handleChange('age')
                style={{
                  height: 40,
                  fontSize: 14,
                  width: '100%'
                }}
              >
                <option value="CSV1">All Sessions Summary (CSV)</option>
                <option value="CSV2">All Sessions With Details (CSV)</option>
                <option value="PDF1">All Sessions Summary (PDF)</option>
                <option value="PDF2">Patient Screenshot (PDF)</option>
                <option value="PDF3">Depression Score Graph (PDF)</option>
                <option value="PDF4">Anxiety Score Graph (PDF)</option>
              </Select>
            </FormControl>

            <ButtonComponent
              style={classes.buttonCSV}
              loading={loading || loadingFile || loadRecordings}
              disabled={loading || loadingFile || loadRecordings}
              onClick={exportFile}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

Top.propTypes = {
  classes: PropTypes.object,
  info: PropTypes.object,
  sessions: PropTypes.array,
  clinician: PropTypes.string,
  config: PropTypes.object,
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object
};

const enhance = withStyles(styles);

export default enhance(Top);
