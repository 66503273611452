import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { Input, InputLabel, FormControl } from '@material-ui/core';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      inputId="masktextfield"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function FormattedInputs({phone, setPhone}) {

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel htmlFor="formatted-text-mask-input">Phone</InputLabel>
        <Input
          variant="outlined"
          value={phone}
          onChange={(event) => {
            setPhone(event)
          }}
          onClick={(event) => {
            event.target.setSelectionRange(4, 4);
          }}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
        />
      </FormControl>
    </div>
  );
}

FormattedInputs.propTypes = {
  phone: PropTypes.string,
  setPhone: PropTypes.func
};