import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Select, CircularProgress } from '@material-ui/core';
import Search from '../../common/search/Search';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 80
    }
  },
  cont_filter: {
    display: 'flex',
    alignItems: 'left',
    [theme.breakpoints.up(1000)]: {
      width: '20%'
    }
  },
  cont_search: {
    width: '60%'
  },
  cont_export: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    [theme.breakpoints.up(1000)]: {
      marginTop: 0,
      width: '20%'
    }
  },
  button: {
    backgroundColor: theme.palette.primary.main_two,
    margin: 8,
    color: '#FFFFFF',
    '&:hover': {
      background: theme.palette.primary.main_two
    }
  },
  input: {
    height: 8
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: 350,
    height: '100%',
    marginLeft: 8,
    [theme.breakpoints.down(500)]: {
      width: 300
    }
  }
});

function MiddleMainPage({
  classes,
  filterValue,
  lastLogin,
  activeFilters,
  saveConfiguration,
  saveConfigCompleted,
  downloadCsv,
  download,
  showSpinner
}) {
  const [filter, setFilter] = useState(
    typeof filterValue !== 'undefined' ? filterValue : 0
  );

  const handleChangeShow = event => {
    let filters;

    switch (event.target.value) {
      case 0:
        // setSearchFilter('');
        window.history.replaceState(null, null, '?page=1&v=all');
        filters = [];
        break;
      case 1:
        // setSearchFilter('');
        window.history.replaceState(null, null, '?page=1&v=new');
        filters = [
          {
            field: 'startTimeStamp',
            operator: '>=',
            value: `${moment
              .utc(lastLogin, 'x')
              .format('YYYY-MM-DDTHH:mm:ss')}`,
            type: 'Date'
          }
        ];
        break;
      default:
        filters = [];
    }

    saveConfiguration({
      filters
    });

    setFilter(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.cont_filter}>
        <Select
          style={{
            fontSize: '14px',
            fontWeight: 500,
            verticalAlign: 'middle'
          }}
          disableUnderline
          value={filter}
          onChange={handleChangeShow}
        >
          <MenuItem value={0}>Show All Sessions</MenuItem>
          <MenuItem value={1}>Show New Sessions</MenuItem>
        </Select>
      </div>
      <div className={classes.cont_search}>
        <Search
          activeFilters={activeFilters}
          saveConfiguration={saveConfiguration}
          spinner={showSpinner}
          setFilter={setFilter}
        />
      </div>
      <div style={{ marginTop: 0 }} className={classes.cont_export}>
        {download === false ? (
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => downloadCsv()}
          >
            Export All
          </Button>
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}

MiddleMainPage.propTypes = {
  classes: PropTypes.object,
  downloadCsv: PropTypes.func,
  download: PropTypes.bool
};

const mapStateToProps = state => ({
  portalActivePartners: get(state, 'login.portalConfig.portalActivePartners', [])
});

const enhance = compose(connect(mapStateToProps), withStyles(styles));

export default enhance(MiddleMainPage);
