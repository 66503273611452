import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  CssBaseline,
  Paper
} from '@material-ui/core';
import notifier from '../../common/utils/notifier';

import Login from './components/Login';
import SendOTP from './components/SendOTP';
import VerifyOTP from './components/VerifyOTP';

import firebase from '../../../config/firebase';

import Dialog from '../../common/dialog/dialog';
import SelectPartnersDialog from '../../common/dialog/selectPartnersDialog';
import pckg from '../../../../package.json';

const GOOGLE_CAPTCHA_KEY = '6LfTBOEZAAAAAEb9DDo_qLC7n1I-0Jq8fMhi8VZc';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  }
});

function LoginComponent(props) {
  const {
    classes,
    userPartnerList,
    isLoggedIn,
    showLoginSpinner,
    history,
    getPortalConfig,
    logout,
    portalConfig,
    role,
    multi_partner
  } = props;
  const [partnersSelected, setPS] = useState(false);
  const [step, setStep] = useState(1);
  const [load, setLoad] = useState(false);
  const [otpValues, setOtpValues] = useState({
    email: '', phone: '', primaryValue: ''
  })

  const loginUserWithEmailAndPassword = (e, email, password) => {
    const { getUserData } = props;
    e.preventDefault();
    getUserData(email.trim(), password);
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (role === 'crisisteammember') {
        history.push('/alerts?page=4');
        return;
      }

      // If the user has options to invite new patients, then show the OVERVIEW page. Otherwise, go to session page
      if(!portalConfig.newPatientOptions || portalConfig.newPatientOptions.length < 1){
        history.push('/sessions?page=1');
        return
      }

      history.push('/home');
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_CAPTCHA_KEY}`;
    script.async = false;
    document.body.appendChild(script);

  }, [isLoggedIn]);

  const sendOTPcode = data => {
    const otpFunction = firebase
      .functions()
      .httpsCallable('initiateCustomAuth');
    otpFunction(data)
      .then(result => {
        if (result.data.success) {
          setLoad(false);
          setOtpValues({
            email: result.data.email,
            phone: result.data.phone,
            primaryValue: data.phone
          })
          setStep(3);
        } else {
          notifier.error(result.data.message);
          setLoad(false);
        }
      })
      .catch(error => {
        console.error('Error: ', error.message);
        notifier.error(error.message);
        setLoad(false);
      });
  };

  const verifyOTPcode = data => {
    const { getUserJsonWithToken } = props;
    setLoad(true);
    const otpFunction = firebase
      .functions()
      .httpsCallable('verifyCustomAuthCode');
    otpFunction({
      phone: otpValues.primaryValue,
      code: data,
      partner: '',
      activationCode: 'PORTAL'
    }).then(result => {
        if (result.data.success) {
          setLoad(false);
          getUserJsonWithToken(result.data.token);
        } else {
          notifier.error(result.data.message);
          setLoad(false);
          console.log(`Something wrong happened: ${result.data.message}`);
        }
      })
      .catch(error => {
        console.error('Error while verifying token: ', error.message);
        notifier.error(error.message);
        setLoad(false);
      });
  };

  const captcha = data => {
    setLoad(true)
    console.log(data);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(GOOGLE_CAPTCHA_KEY, { action: 'portalSignUp' })
        .then(token => {
          try {
            data.token = token;
            sendOTPcode(data);
          } catch (e) {
            setLoad(false)
            console.log('Error', e);
          }
        });
    });
  };

  const openDialog = () => {
    return (
      <Dialog close={logout}>
        <SelectPartnersDialog
          close={logout}
          text="Please select at least one partner to show in the portal"
          selectPartners={partners => {
            getPortalConfig(partners);
            setPS(true);
          }}
          partners={userPartnerList}
        />
      </Dialog>
    );
  };

  const loginPage = () => {
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          {step === 1 ? (
            <Login
              history={history}
              spinner={showLoginSpinner}
              next={(index) => setStep(index)}
              submit={loginUserWithEmailAndPassword}
            />
          ) : step === 2 ? (
            <SendOTP load={load || showLoginSpinner} submit={captcha} next={(index) => setStep(index)} />
          ) : <VerifyOTP values={otpValues} load={load || showLoginSpinner} submit={verifyOTPcode} next={(index) => setStep(index)} />}
          
          <p
            style={{
              textAlign: 'right',
              marginRight: '3%',
              fontSize: 12
            }}
          >
            Version: {pckg.version}_{process.env.REACT_APP_ENV}
          </p>
          {multi_partner && !partnersSelected ? openDialog() : ''}
        </Paper>
      </main>
    );
  };

  return loginPage();
}

LoginComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  snackbar: PropTypes.object,
  getUserData: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  showLoginSpinner: PropTypes.bool,
  history: PropTypes.object,
  getPortalConfig: PropTypes.func,
  multi_partner: PropTypes.bool
};

export default withStyles(styles)(LoginComponent);
