import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@material-ui/core';

function SelectPartnersDialog({
  text,
  close,
  selectPartners,
  partners
}) {
  const [state, setState] = React.useState([]);
  const [error, setError] = React.useState(false);

  const handleChange = event => {
    if (event.target.checked) {
      setState([event.target.name]);;
      setError(false);
      return;
    }

    setState([]);
  };

  const handleContinue = () => {

    if (state.length < 1) {
      setError(true);
      return;
    }

    setError(false);
    selectPartners(state);
  };

  return (
    <div>
      <DialogTitle>Select Partners</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <div style={{ display: 'flex' }}>
          <FormControl required error={error} component="fieldset">
            <RadioGroup>
              {partners.map(partner => (
                <FormControlLabel
                  key={partner}
                  control={(
                    <Radio
                      checked={state[0] === partner}
                      onChange={handleChange}
                      name={partner}
                    />
                  )}
                  label={partner}
                />
              ))}
            </RadioGroup>
            {error ? (
              <FormHelperText>Please, select one partner</FormHelperText>
            ) : (
              ''
            )}
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={handleContinue} color="primary">
          Continue
        </Button>
      </DialogActions>
    </div>
  );
}

SelectPartnersDialog.propTypes = {
  close: PropTypes.func,
  selectPartners: PropTypes.func,
  partners: PropTypes.array,
  text: PropTypes.string
};

export default SelectPartnersDialog;
