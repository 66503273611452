// import fetch from 'cross-fetch';
import moment from 'moment';
import firebase from '../../../config/firebase';
import { MSHanswers } from "../../common/utils/mshUtils";

import {
  requestSessionJson,
  receiveSessionJson,
  requestPatientJson,
  receivePatientJson,
  requestDownloadCsv,
  downloadCsvCompleted
} from './actions';

import { formatDate } from '../../../components/Date';

require('firebase/functions');

const saveLogs = data => {
  const saveLogsFunction = firebase
    .functions()
    .httpsCallable('dashboardFunctions-saveLogs');
  saveLogsFunction(data)
    .then(function (result) {
      if (result.data !== 'success') {
        console.log(
          `Something wrong happened while saving logs.`
        );
      }
    })
    .catch(function (error) {
      console.error('Error saving logs: ', error.message);
    });
};

const createCSV = (config, data, h, headersName, fileName, dispatch) => {
  const csvRows = [];
  const csvRowsHeadersName = [];
  const dateObject = ['startTimeStamp', 'endTimeStamp', 'createdAt']; // dates object. the date is in the value field
  const dateSecondsObject = ['lastSession']; // dates represented as 'seconds'. Need to be converted
  const headersToRemove = ['details', 'actions', 'reviewOfSystems', 'surveyScores'];
  const { configurations, role } = config;
  /* Create headers object not affected by reference */
  const headers = h.slice();
  // get the temp headers (not user friendly, these are the columns name of the db)
  csvRows.push(headers.join('\t'));
  /**
   * HEADERS
   * - Remove columns into headersToRemove array
   * - Remove the restricted columns if role of the user is viewer
   */
  for (let r = 0; r < headersToRemove.length; r += 1) {
    if (headers.indexOf(headersToRemove[r]) > -1) {
      headers.splice(headers.indexOf(headersToRemove[r]), 1);
    }
  }
  if (role === 'viewer') {
    for (let i = headers.length - 1; i >= 0; i -= 1) {
      if (
        configurations.user_restriction.restricted_fields_viewer.indexOf(
          headers[i]
        ) > -1
      ) {
        headers.splice(i, 1);
      }
    }
  }
  //

  // loop over the rows
  // eslint-disable-next-line no-restricted-syntax
  for (const row of data) {
    const values = headers.map(header => {
      let escaped;

      // We don't want to see null or undefined values in the csv
      /* if (row[header] === null || row[header] === undefined) {
        escaped = ''.replace(/"/g, '\\"');

        return `"${escaped}"`;
      } */

      // (''+row[header]) converts everything into a string and allows us to use replace() also with no string values
      // Moreover, with this escaped solution all commas inside values are going to be safe and not create problems.
      escaped = `${row[header]}`.replace(/"/g, '\\"');
      if (dateObject.indexOf(header) > -1) {
        try {
          escaped = formatDate(row[header]?.value, 'L LT z', configurations.partnerTimezone).replace(/"/g, '\\"');
        } catch (err) {
          console.error(
            `The value "${header}" of the user ${row.userId} has an error: ${err}`
          );
          escaped = ' '.replace(/"/g, '\\"');
        }
      } else if (dateSecondsObject.indexOf(header) > -1) {
        try {
          const d = formatDate(parseInt(row[header], 10) * 1000, 'L LT z', configurations.partnerTimezone);
          escaped = `${d}`.replace(/"/g, '\\"');
        } catch (err) {
          console.error(
            `The value "${row[header]}" of the user ${row.userId} has an error: ${err}`
          );
          escaped = ' '.replace(/"/g, '\\"');
        }
      }

      // EDIT certain fields values:
      let value = '';
      // Change phqTotal in case phq9 was on for the session:
      // Subtract the 9th answer value to the phqTotal
      if (header === 'phqTotal') {
        value =
          row.phqStatus && row.phqStatus !== 'OFF'
            ? row.phqStatus === 'INCOMPLETE'
              ? 'Incomplete'
              : row.phq9Status && row.phq9Status === 'COMPLETE'
              ? row.phqTotal - row.phqScores[8]
              : row.phqTotal
            : 'n/a';
        escaped = `${value}`.replace(/"/g, '\\"');
      }
      //----------------------------------------------

      // PHQ_R GAD_R ELLIPSIS_SCORE ANXIETY_SCORE:
      // If the header is phq_r or gad_r we need to change the representation
      if (header === 'phq_r' || header === 'gad_r') {

        if (row[header] === null || typeof row[header] === 'undefined') {
          value = 'Incomplete';
        }

        if (row[header] < 0) {
          value = 'Incomplete'
        } else if (header === 'phq_r' && row[header] > 24) {
          value = 24
        } else if (header === 'gad_r' && row[header] > 21) {
          value = 21
        } else {
          value = row[header] ? Math.ceil(row[header]).toString() : '';
        }

        escaped = `${value}`.replace(/"/g, '\\"');
      }
      //----------------------------------------------

      // PHQ_4C GAD_4C ELLIPSIS_SCORE ANXIETY_SCORE:
      // If the header is phq_4c or gad_4c we need to change the representation
      if (header === 'phq_4c' || header === 'gad_4c') {

        if (row[header] === null || typeof row[header] === 'undefined' || row[header] < 0) {
          value = 'Incomplete';
        }
        if (row[header] === 0) {
          value = 'None'
        } else if (row[header] === 1) {
          value = 'Mild'
        } else if (row[header] === 2) {
          value = 'Moderate'
        } else if (row[header] === 3) {
          value = 'Severe'
        }

        escaped = `${value}`.replace(/"/g, '\\"');
      }
      //----------------------------------------------

      // phqTotalCategory gadTotalCategory:
      // If the header is phqTotalCategory or gadTotalCategory we need to change the representation
      if (header === 'gadTotalCategory') {

        if (row.gadTotal < 5) {
          value = 'None'
        } else if (row.gadTotal < 10) {
          value = 'Mild'
        } else if (row.gadTotal < 15) {
          value = 'Moderate'
        } else if (row.gadTotal > 15) {
          value = 'Severe'
        }

        if (!row.gadStatus) {
          value = 'Incomplete'
        }

        if (row.gadStatus === 'INCOMPLETE' || row.gadStatus === 'OFF') {
          value = row.gadStatus.charAt(0) + row.gadStatus.substring(1).toLowerCase()
        }

        escaped = `${value}`.replace(/"/g, '\\"');
      }

      if (header === 'phqTotalCategory') {

        if (row.phqTotal < 5) {
          value = 'None'
        } else if (row.phqTotal < 10) {
          value = 'Mild'
        } else if (row.phqTotal < 15) {
          value = 'Moderate'
        } else if (row.phqTotal > 15) {
          value = 'Severe'
        }

        if (!row.phqStatus) {
          value = 'Incomplete'
        }

        if (row.phqStatus === 'INCOMPLETE' || row.phqStatus === 'OFF') {
          value = row.phqStatus.charAt(0) + row.phqStatus.substring(1).toLowerCase()
        }

        escaped = `${value}`.replace(/"/g, '\\"');
      }
      //----------------------------------------------

      // PHQ-9 Q:9 and PHQ9
      if (header === 'phqQ9') {
        value =
          !row.phq9 || row.phq9Status === 'OFF'
            ? 'n/a'
            : row.phq9Status === 'COMPLETE'
              ? row.phqScores[8]
              : 'Incomplete';
        escaped = `${value}`.replace(/"/g, '\\"');
      }

      if (header === 'phq9Total' || header === 'phq9TotalClickable') {
        value =
          !row.phq9 || row.phq9Status === 'OFF'
            ? 'n/a'
            : row.phq9Status === 'COMPLETE'
              ? row.phqTotal
              : 'Incomplete';
        escaped = `${value}`.replace(/"/g, '\\"');
      }

      if (header === 'gadTotal' || header === 'gadTotalClickable') {
        value =
          row.gadStatus && row.gadStatus !== 'OFF'
            ? row.gadStatus === 'INCOMPLETE'
              ? 'Incomplete'
              : row.gadTotal
            : 'n/a';
        escaped = `${value}`.replace(/"/g, '\\"');
      }
      //----------------------------------------------

      // mshServices
      if (header === 'mshServices') {
        const serviceValueArray = [];
        if (!row.custom) {
          escaped = `${""}`.replace(/"/g, '\\"');
        } else {
          for (let i = 0; i < row.custom.length; i += 1) {
            if (row.custom[i].survey === 'MSHPQ') {
              for (let j = 0; j < row.custom[i].scores.length; j += 1) {
                if (row.custom[i].scores[j] === 1) {
                  serviceValueArray.push(MSHanswers[j]);
                }
              }
              break;
            }
          }
          if (serviceValueArray.length < 1) {
            escaped = `${""}`.replace(/"/g, '\\"');
          } else {
            escaped = `${serviceValueArray.join(", ")}`.replace(/"/g, '\\"');
          }
        }
      }
      //----------------------------------------------

      // sessionId
      if (header === 'sessionId') {
        escaped = `${row.uid}`.replace(/"/g, '\\"');
      }
      //----------------------------------------------

      return `"${escaped}"`;
    });

    // form escaped comma separated values
    csvRows.push(values.join('\t'));
  }

  // Change the columns name with user friendly ones
  headers.forEach(header => {
    csvRowsHeadersName.push(headersName[header]);
  });
  csvRows[0] = csvRowsHeadersName.join('\t');
  //-----------------------------------------------

  // Get the current timestamp that will be used as filename
  const date = new Date();

  const filename =
    fileName ||
    `Ellipsis_AllRecords_${moment(date).format('YYYY-MM-DD_HH:MM:SS')}_.csv`;

  // Create a blob that is needed to download data
  const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' }); // csvRows.join('\n') go to next line with every row
  // Send the blob to the browser
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  dispatch(downloadCsvCompleted());

  saveLogs({
    type: 'download/csv',
    success: `Success: ${data.length}`,
    text: `User downloaded a csv with these info: ${headers.toString()}`
  });
};

const getSessionJson = (
  lastLogin,
  partnerList,
  sort,
  sortOrder,
  limit,
  offset,
  filters
) => {
  return dispatch => {
    dispatch(requestSessionJson());

    const sessionList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_sessionList');
    sessionList({
      lastLogin,
      partnerList,
      sort,
      sortOrder,
      filters,
    }).then(function (result) {
      const { sessionCount, query } = JSON.parse(result.data);
      const sessions = JSON.parse(result.data).sessionList;
      const newSessionsNumber = JSON.parse(result.data).newSessionsCount.count;

      dispatch(
        receiveSessionJson(
          sessionCount,
          sessions,
          newSessionsNumber,
          sort,
          sortOrder,
          limit,
          offset
        )
      );

      saveLogs({
        type: 'query/bigQuery',
        success: `Success: ${sessionCount}`,
        text: query,
      });

    });
  };
};

const getPatientJson = partnerList => {
  return dispatch => {
    dispatch(requestPatientJson());

    const patientList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_patientsNumber');
    patientList({
      partnerList
    }).then(result => {
      const { query } = JSON.parse(result.data);
      const patientsCount = JSON.parse(result.data).patientsCount.count;
      // const patients = JSON.parse(result.data).patientList;
      dispatch(receivePatientJson(patientsCount));

      saveLogs({
        type: 'query/bigQuery',
        success: `Successfully returned patient count: ${patientsCount}`,
        text: query,
      });
    });
  };
};

const downloadCsv = data => {
  return dispatch => {
    dispatch(requestDownloadCsv());

    const fileName = data.fileName || null;

    if (data.data) {
      createCSV(
        data.config,
        data.data,
        data.showColumns,
        data.columnsName,
        fileName,
        dispatch
      );
      
    }
  };
};

export default {
  getSessionJson,
  getPatientJson,
  downloadCsv
};