import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography
} from '@material-ui/core';

const styles = theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 20
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: 20
  },
  bottomRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 20
  },
  contentBox: {
    border: '1px solid #0000003b',
    borderRadius: '4px',
    padding: 8
  },
  confirmButton: {
    background: '#3abab6',
    color: 'white'
  },
  helperText: {
    marginLeft: 'auto',
    marginTop: 12,
    fontSize: 12,
    color: '#9e9e9e'
  }
});

const MAX_LENGTH = 200;

const MessageDialog = ({
  classes,
  close,
  phoneNumber,
  email,
  partnerName,
  patientName,
  template,
  sendMessage,
  loading
}) => {
  const initialContent = (template || '')
    .replace('{patientName}', patientName)
    .replace('{partnerName}', partnerName);

  const [content, setContent] = useState(initialContent);
  const [subject, setSubject] = useState('');

  const handleClick = () => {
    if (content && phoneNumber) {
      sendMessage({ phoneNumber, content });
    }

    if (content && email) {
      sendMessage({ email, content, subject })
    }
  };

  const handleType = e => {
    const text = e.target.value;

    if (text.length > MAX_LENGTH) {
      return;
    }
    setContent(text);
  };

  if (!phoneNumber && !email) {
    return (
      <Box>
        <DialogTitle>
          Patient doesn't have a phone number or email.
        </DialogTitle>
      </Box>
    );
  }

  return (
    <Box>
      <DialogTitle>Send a message to {patientName || 'patient'}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {phoneNumber ? 'Edit the message content below' : 'Edit subject and email content'}
        </Typography>

        {email && (
          <Box className={classes.row}>
            <TextField
              className={classes.contentBox}
              loading={loading}
              value={subject}
              placeholder="Type your subject here"
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
          </Box>
        )}

        <Box className={classes.row}>
          <TextField
            className={classes.contentBox}
            loading={loading}
            value={content}
            placeholder="Please update the message content"
            helperText="Please do not remove texts in curly braces. e.g. {shareLink}"
            onChange={handleType}
            multiline
            fullWidth
            rows={5}
            InputProps={{ disableUnderline: true }}
            maxRows={5}
          />
          <Typography className={classes.helperText}>
            {content.length} / {MAX_LENGTH}
          </Typography>
        </Box>

        <Box className={classes.bottomRow}>
          {loading ? (
            <CircularProgress size={32} style={{ margin: 'auto' }} />
          ) : (
            <>
              <Link
                color="primary"
                disabled={loading}
                onClick={close}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  style={{ cursor: 'pointer', fontWeight: 700, fontSize: 15 }}
                  variant="body1"
                  color="primary"
                >
                  Cancel
                </Typography>
              </Link>
              <Button
                className={classes.confirmButton}
                disabled={loading || !content || (email && !subject)}
                onClick={handleClick}
                variant="contained"
              >
                Send {phoneNumber ? 'Message' : 'email'}
              </Button>
            </>
          )}
        </Box>
      </DialogContent>
    </Box>
  );
};

MessageDialog.propTypes = {
  classes: PropTypes.object,
  close: PropTypes.func,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  patientName: PropTypes.string,
  template: PropTypes.string,
  sendMessage: PropTypes.func,
  loading: PropTypes.bool
};

export default withStyles(styles)(MessageDialog);
