import React from 'react';
import PropTypes from 'prop-types';

import {
  Document,
  Page,
  Image,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';

Font.register({ 
  family: 'Roboto', 
  src: '/font/Roboto-Bold.ttf', 
  fontWeight: 'bold',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10
  },
  sectionOne: {
    margin: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imagemiddle: {
    width: '100%'
  },
});

// Create Document Component
class Screenshot extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Document>
        <Page key={1} size="A4" style={styles.page}>
          <View style={styles.sectionOne}>
            <Image style={styles.imagemiddle} src={data.middle} alt="images" />
          </View>
        </Page>
      </Document>
    );
  }
}

Screenshot.propTypes = {
  data: PropTypes.object
};

export default Screenshot;
