import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

import { Button, Link, Typography, CircularProgress } from '@material-ui/core';
import ReactInputVerificationCode from 'react-input-verification-code';

import logo from '../../../../assets/logo.png';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(4)
  },
  progress: {
    margin: theme.spacing(2)
  },
  codeInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
    '--ReactInputVerificationCode-itemWidth': '2.5rem',
    '--ReactInputVerificationCode-itemHeight': '2.5rem',
    '--ReactInputVerificationCode-itemSpacing': '0.5rem',
    [theme.breakpoints.down(400)]: {
      '--ReactInputVerificationCode-itemWidth': '2.0rem',
      '--ReactInputVerificationCode-itemHeight': '2.0rem',
    }
  },
  values: {
    color: theme.palette.primary.main
  }
});

function VerifyOTP(props) {
  const { classes, load, next, submit, values } = props;
  const [code, setCode] = useState('');
  const [errorMessage, setError] = useState('');

  const validateCode = value => {
    if (!value || value.length < 6) {
      setError('Invalid code. Please insert 6 digits');
      return;
    }
    setError('');
    submit(value);
  };

  const maskEmail = values.email
    ? values.email.replace(/^(.{2})[^@]+/, '$1XXX')
    : 'n/a';
  const maskPhone = values.phone
    ? `XXXXX${values.phone.substring(8, values.phone.length)}`
    : '';

  return (
    <>
      <img alt="" src={logo} height="100" />      
      <form className={classes.form}>
        <Typography style={{ marginTop: 10 }} align="center" variant="body1">
          Enter the one time code sent to
          <span className={classes.values}> {maskEmail} </span>
          {maskPhone ? (
            <>
              <span>and</span>
              <span className={classes.values}> {maskPhone} </span>
            </>
          ) : (
            ''
          )}
        </Typography>

        <div className={classes.codeInput}>
          <ReactInputVerificationCode
            length={6}
            onChange={setCode}
            value={code}
            placeholder=""
          />
        </div>

        <Typography
          style={{ marginTop: 10, fontSize: 12, color: '#ff0000' }}
          align="center"
          variant="body1"
        >
          {errorMessage}
        </Typography>

        {load ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => validateCode(code)}
          >
            Verify
          </Button>
        )}

        <Typography
          style={{ marginTop: 10, fontSize: 12 }}
          align="center"
          variant="body1"
        >
          <Link
            underline="always"
            onClick={() => {
              next(2);
            }}
            color="primary"
            style={{ cursor: 'pointer' }}
          >
            Back
          </Link>
        </Typography>
      </form>
    </>
  );
}

VerifyOTP.propTypes = {
  classes: PropTypes.object.isRequired,
  load: PropTypes.bool,
  next: PropTypes.func,
  submit: PropTypes.func,
  values: PropTypes.object
};

export default withStyles(styles)(VerifyOTP);
