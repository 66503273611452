export const statuses = [
  'Meeting Started',
  'Clinician Joined',
  'Patient Joined',
  'In Progress',
  'Meeting Finished',
  'Completed - Patient did not join',
  'Completed - Provider did not join',
  "Incomplete - Audio unavailable",
  'Recording Received (~3 mins)',
  'Calculating Insights (~5 mins)',
  'Completed',
  'Incomplete'
];