// react-app-polyfill ensures that some functionalities gets supported on IE
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// redux-logger is a middleware that lets you log every state change
import logger from 'redux-logger';

// redux-thunk is a middleware that lets you dispatch async actions
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';

//-----------------------------------------------------------------------------------------
/**
 * We have two different memories we can use in the browser: localStorage and sessionStorage
 * The only difference is that sessionStorage gets cleared when the user closes the tab.
 * This is the behaviour needed for security reasons.
 */
// In order to import the localStorage memory use the following:
// import storage from 'redux-persist/lib/storage';

// In order to import the sessionStorage memory use the following:
import storageSession from 'redux-persist/es/storage/session';
//-----------------------------------------------------------------------------------------

import { PersistGate } from 'redux-persist/integration/react';

import App from './app/App';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';

const persistConfig = {
  key: 'root',
  // In order to use the sessionStorage memory use the following:
  storage: storageSession
  // In order to use the localStorage memory use the following:
  // storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'prod2') ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const store = createStore(persistedReducer, middleware);

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <App />
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();