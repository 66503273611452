import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';

const SessionScores = ({ classes, session, has4WayClassification, hasPHQGADCategories }) => (
  <div style={{ display: 'flex' }}>
    <div className={classes.columnDivs}>
      <div style={{ width: 150, marginRight: 20 }}>
        <Typography style={{ fontWeight: 600 }} color="#262626" variant="body1">
          Session Scores
        </Typography>
      </div>
      <div style={{ margin: 0 }} className={classes.rowDivs}>
        <div style={{ flex: 1, height: 150, width: 150, marginRight: 20 }}>
          <Card className={classes.cardPHQGAD}>
            <CardContent className={classes.cardPHQGADContent}>
              {has4WayClassification ? (
                <>
                  <Typography variant="body1">Ellipsis Depression Score</Typography>
                  <Typography className={classes.textFourWay} variant="h2">
                    {session.phq_4c === null || session.phq_4c < 0
                      ? 'Incomplete'
                      : session.phq_4c === 0
                        ? 'None'
                        : session.phq_4c === 1
                          ? 'Mild'
                          : session.phq_4c === 1
                            ? 'Mild'
                            : session.phq_4c === 2
                              ? 'Moderate'
                              : session.phq_4c === 3 &&
                                (session.phq9 || session.phqScores?.length === 9)
                                ? 'Severe'
                                : session.phq_4c === 3
                                  ? 'Severe'
                                  : session.phq_4c}
                  </Typography>
                  <div style={{ height: '40%' }} />
                </>
              ) : hasPHQGADCategories ? (
                <>
                  <Typography variant="body1">Survey Depression Score</Typography>
                  <Typography className={classes.textFourWay} variant="h2">
                    {!session.phqStatus
                      ? 'Incomplete'
                      : session.phqStatus !== 'COMPLETE'
                        ? session.phqStatus.charAt(0) +
                        session.phqStatus.substring(1).toLowerCase()
                        : session.phqTotal < 5
                          ? 'None'
                          : session.phqTotal < 10
                            ? 'Mild'
                            : session.phqTotal < 15
                              ? 'Moderate'
                              : session.phqTotal >= 15
                                ? 'Severe'
                                : ''}
                  </Typography>
                  <div style={{ height: '40%' }} />
                </>
              ) : (
                <>
                  <Typography variant="body1">Ellipsis Depression Score</Typography>
                  <Typography
                    className={classes.textNumber}
                    style={
                      session.phq_r >= 10
                        ? { color: '#be2020', fontWeight: 'bold' }
                        : {}
                    }
                    variant="h2"
                  >
                    {session &&
                      (session.phq_r || session.phq_r === 0) &&
                      session.phq_r !== -1
                          ? Math.ceil(session.phq_r).toString()
                      : 'Incomplete'}
                  </Typography>
                  <div style={{ height: '40%' }} />
                </>
              )}
            </CardContent>
          </Card>
        </div>
        <div style={{ flex: 1, height: 150, width: 150 }}>
          <Card className={classes.cardPHQGAD}>
            <CardContent className={classes.cardPHQGADContent}>
              {has4WayClassification ? (
                <>
                  <Typography variant="body1">Ellipsis Anxiety Score</Typography>
                  <Typography className={classes.textFourWay} variant="h2">
                    {session.gad_4c === null || session.gad_4c < 0
                      ? 'Incomplete'
                      : session.gad_4c === 0
                        ? 'None'
                        : session.gad_4c === 1
                          ? 'Mild'
                          : session.gad_4c === 1
                            ? 'Mild'
                            : session.gad_4c === 2
                              ? 'Moderate'
                              : session.gad_4c === 3
                                ? 'Severe'
                                : session.gad_4c}
                  </Typography>
                  <div style={{ height: '40%' }} />
                </>
              ) : hasPHQGADCategories ? (
                <>
                  <Typography variant="body1">Survey Anxiety Score</Typography>
                  <Typography className={classes.textFourWay} variant="h2">
                    {!session.gadStatus
                      ? 'Incomplete'
                      : session.gadStatus !== 'COMPLETE'
                        ? session.gadStatus.charAt(0) +
                        session.gadStatus.substring(1).toLowerCase()
                        : session.gadTotal < 5
                          ? 'None'
                          : session.gadTotal < 10
                            ? 'Mild'
                            : session.gadTotal < 15
                              ? 'Moderate'
                              : session.gadTotal >= 15
                                ? 'Severe'
                                : ''}
                  </Typography>
                  <div style={{ height: '40%' }} />
                </>
              ) : (
                <>
                  <Typography variant="body1">Ellipsis Anxiety Score</Typography>
                  <Typography
                    className={classes.textNumber}
                    style={
                      session.gad_r >= 10
                        ? { color: '#be2020', fontWeight: 'bold' }
                        : {}
                    }
                    variant="h2"
                  >
                    {session &&
                      (session.gad_r || session.gad_r === 0) &&
                      session.gad_r !== -1
                          ? Math.ceil(session.gad_r).toString()
                      : 'Incomplete'}
                  </Typography>
                  <div style={{ height: '40%' }} />
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  </div>
);

export default SessionScores;
