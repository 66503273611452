import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MdArrowDropDown } from 'react-icons/md';

import {
  Menu,
  MenuItem,
  Typography,
  CircularProgress
} from '@material-ui/core';

import firebase from '../../../config/firebase';
import { getClinicians } from './getClinicians';

const styles = theme => ({
  container: {
    flexDirection: 'row',
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.main
  },
  text: {
    textDecoration: 'underline'
  }
});

function ClinicianSelectDefault({ classes, data, onChange }) {
  const [anchor, setAnchor] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleSelect = async item => {
    const updatePartner = firebase
      .functions()
      .httpsCallable('dashboardFunctions-updatePartner');
    setAnchor(null);
    setLoading(true);
    try {
      await updatePartner({
        id: data.id,
        data: { defaultPatientClinician: item.id }
      });
      onChange({ defaultPatientClinician: item.id });
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    let isCancelled = false;
    const loadData = async () => {
      setLoading(true);
      try {
        const resp = await getClinicians(data.id);

        if (!isCancelled) {
          setItems(resp);
        }
      } catch (e) {
        // silence error
        console.error(e);
      }
      setLoading(false);
    };

    if (data.id) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [data.id]);

  useEffect(() => {
    const selectedItem = items.find(
      item => item.id === data.defaultPatientClinician
    );
    setSelected(selectedItem);
  }, [items, data.defaultPatientClinician]);

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <div>
      <div className={classes.container} onClick={e => setAnchor(e.target)}>
        <Typography variant="body1" className={selected && classes.text}>
          {selected
            ? `${selected.firstName || ''} ${selected.lastName || ''}`
            : ' - '}
        </Typography>
        <MdArrowDropDown />
      </div>
      <Menu
        id="clinician-select-default"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        {items.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => handleSelect(item)}
            selected={data.defaultPatientClinician === item.id}
          >
            {item.firstName} {item.lastName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ClinicianSelectDefault.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func
};

export default withStyles(styles)(ClinicianSelectDefault);
