export const acceptedAdminRoles = [
    "super",
    "admin"
]

export const acceptedPrivateRoles = [
    "super", 
    "admin",
    "clinician"
]

export const acceptedAlertRoles = [
    "super",
    "admin",
    "clinician",
    "crisisteammember"
]

export const allRoles = [
    "super",
    "admin",
    "clinician",
    "viewer"
]