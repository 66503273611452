import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import { pick } from 'lodash';

import firebase from '../../config/firebase';

import Row from './components/MeetingRow';
import { getRecentMeetings } from './utils/getCloudMeetings';

const Wrapper = ({ children }) => (
  <Container maxWidth="xl" style={{ padding: '24px' }}>
    {children}
  </Container>
);

const LiveCallsComponent = props => {
  const { portalConfig } = props;
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);

  const partnerId = portalConfig.portalActivePartners[0];
  const { forLive } = props;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getRecentMeetings(partnerId, forLive);
      setMeetings(data || []);
      setLoading(false);
    };

    getData();
  }, [getRecentMeetings, partnerId, forLive]);

  useEffect(() => {
    const timespan = moment(new Date())
      .subtract(forLive ? 60 : 7 * 24 * 60, 'minutes')
      .toDate();

    const meetingsCollectionRef = firebase
      .firestore()
      .collection('cloudMeetings')
      .where('partnerId', '==', partnerId)
      .where('createdAtUtc', '>=', timespan)
      .orderBy('createdAtUtc', 'desc');

    const unsub = meetingsCollectionRef.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const data = change.doc.data();
          if (
            data.endedAtUtc === undefined &&
            !meetings.find(el => el.appMeetingUuid === data.appMeetingUuid)
          ) {
            setMeetings(prev => [
              pick(data, [
                'app',
                'appMeetingName',
                'appMeetingId',
                'appMeetingUuid',
                'appHostId',
                'appParticipants',
                'meetingId',
                'restSessionId',
                'restSessionScore',
                'partnerId',
                'recordingEndedAtUtc',
                'createdAtUtc',
                'endedAtUtc'
              ]),
              ...prev
            ]);
          }
        }
      });
    });

    return () => {
      unsub();
    };
  });

  if (loading)
    return (
      <Wrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '300px' }}
        >
          <CircularProgress />
        </Box>
      </Wrapper>
    );

  if (meetings.length === 0)
    return (
      <Wrapper>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '300px' }}
        >
          No Live Calls available.
        </Box>
      </Wrapper>
    );

  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Patient Name / ID</TableCell>
              <TableCell align="center">Clinician Name / ID</TableCell>
              <TableCell align="center">Call Date</TableCell>
              <TableCell align="center">Depression</TableCell>
              <TableCell align="center">Anxiety</TableCell>
              <TableCell align="center">Call Status</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {meetings.map(meeting => (
              <Row key={meeting.appMeetingUuid} meeting={meeting} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default LiveCallsComponent;
