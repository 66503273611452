import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spreadsheet from 'react-spreadsheet';

import { makeStyles } from '@material-ui/styles';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Button,
    LinearProgress
} from '@material-ui/core';

const useRowStyles = makeStyles({
    cell: {
        color: '#000000'
    }
});

const CSVViewerDialog = ({ fileContent, close }) => {
    const classes = useRowStyles();
    const [loading, setLoading] = useState(true);
    const [dataset, setDataset] = useState([]);

    useEffect(() => {
        const initialDataset = [];
        const tempDataset = [];

        const allRows = fileContent.split("\n");
        for (let i = 0; i < allRows.length; i+=1) {
            tempDataset.push(allRows[i].split("\t"));

            const tempArrayRow = [];
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const j in tempDataset[i]) {
                tempArrayRow.push({ value: tempDataset[i][j], readOnly: true, className: classes.cell });
            }
            initialDataset.push(tempArrayRow);
        }

        setDataset(initialDataset)
        setLoading(false)
    }, []);

    return (
      <div>
        <DialogTitle>File Drop Content</DialogTitle>
        <Divider />
        <DialogContent>
          {!loading ? (
            <Spreadsheet data={dataset} />
                ) : <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Close
          </Button>
        </DialogActions>
      </div>
    );
}

CSVViewerDialog.propTypes = {
    close: PropTypes.func,
};

export default CSVViewerDialog;