import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';

import Row from './SessionDropRow';

const SessionDropTable = props => {
  const { sessions } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Session Name / ID</TableCell>
            <TableCell align="center">Session Date</TableCell>
            <TableCell align="center">Depression</TableCell>
            <TableCell align="center">Anxiety</TableCell>
            <TableCell align="center">Processed</TableCell>
            <TableCell align="center">Tries</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map(session => (
            <Row key={session.sessionId} session={session} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SessionDropTable;
